import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { GetQueryHistoryByUserParams, QueryListPaginatedResponse } from './types';

import { queryHistoryKeys } from './queryKeys';

export const useGetQueryHistoryByUserInfiniteQuery = (
  { limit, userId, ...params }: GetQueryHistoryByUserParams,
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (
      last: QueryListPaginatedResponse['data'] = [],
      all: QueryListPaginatedResponse['data'][],
    ) => (last.length === limit ? all.length : null),
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<QueryListPaginatedResponse>('/query-history', {
        params: { ...params, limit, skip: pageParam * limit, userId },
        signal,
      });

      return res.data.data;
    },
    queryKey: queryHistoryKeys.list({ ...params, limit, type: 'user-based', userId }),
    ...(options || {}),
  });
};
