import type { Id } from 'react-toastify';

import { useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { twMerge } from 'tailwind-merge';

import type { ModelCollection } from 'shared/api/model-collections';

import { getCategoryIcon } from 'features/CategoryModelsDropdown';
import NoFoundIcon from 'pages/ModelCollections/assets/no-found.svg?react';
import {
  modelCollectionsQueryKeys,
  useDeleteModelCollectionsQuery,
  useEditModelCollectionsQuery,
  useModelCollectionsQuery,
} from 'shared/api/model-collections';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  modelId: string;
  onEditModelCollection: (modelCollection: ModelCollection) => void;
  onOpenChange: (open: boolean) => void;
  onOpenCreateCollection: () => void;
  open: boolean;
};

export const AddModelsToCollections = ({
  modelId,
  onEditModelCollection,
  onOpenChange,
  onOpenCreateCollection,
  open,
}: Props) => {
  const [selectedCollection, setSelectedCollection] = useState<ModelCollection | null>(null);

  const deleteCollectionId = useRef<string>();

  const queryClient = useQueryClient();
  const { data: collections, isFetching: isCollectionsPending } = useModelCollectionsQuery();
  const { isPending: isEditModelCollectionsPending, mutateAsync: editModelCollections } =
    useEditModelCollectionsQuery();

  // const collections = useMemo(() => {
  //   return collections?.filter((el) => !el.models.find((el) => el._id === modelId));
  // }, [collections, modelId]);

  const { isPending: isDeleteModelCollectionsPending, mutateAsync: deleteModelCollections } =
    useDeleteModelCollectionsQuery();

  const toastId = useRef<Id | undefined>();

  const handleSubmit = async () => {
    if (!selectedCollection) {
      toastId.current = toaster.error(
        'Please select collection',
        {},
        { toastId: toastId.current || undefined, updateId: toastId.current || undefined },
      );
      return;
    }

    try {
      const modelIds = new Set(selectedCollection.models.map((el) => el._id));
      modelIds.add(modelId);
      await editModelCollections({ ...selectedCollection, models: [...modelIds] });
      queryClient.invalidateQueries({
        queryKey: modelCollectionsQueryKeys.myCollections(),
      });
      onOpenChange(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={open}>
      <Modal.Content className="w-full !max-w-[512px]" innerClassName="sm:p-7" withAnimation={false}>
        <Modal.CloseButton className=" right-4 top-4" />
        <div className="mb-4 text-3xl font-semibold text-clay-900">Add this Model to a Collection</div>

        <Modal.ScrollableContent className="min-h-72">
          {(isCollectionsPending || (collections && collections.length === 0)) && (
            <div className="flex min-h-72 flex-col items-center justify-center text-center">
              {isCollectionsPending ? (
                <Spinner className="size-8" />
              ) : (
                <>
                  <NoFoundIcon className="size-20" name="search" />
                  <div className="mt-4 text-2xl font-semibold text-clay-900">Collections not found</div>
                  <div className="text-base text-clay-500">Create and manage collections for easy work</div>
                </>
              )}
            </div>
          )}

          {!isCollectionsPending && collections && collections.length > 0 && (
            <div className="grid grid-cols-1 gap-2">
              {collections.map((el) => {
                const isIncludeModelInCollection = !!el.models.find((el) => el._id === modelId);
                return (
                  <Tooltip
                    content={isIncludeModelInCollection ? 'Model added to this collection' : ''}
                    key={el._id}
                  >
                    <div
                      aria-disabled={isIncludeModelInCollection}
                      aria-selected={selectedCollection?._id === el._id}
                      className={twMerge(
                        'group relative flex cursor-pointer gap-4 overflow-hidden rounded-xl p-3 duration-200',
                        'border border-clay-20 hover:border-primary-300',
                        'aria-selected:border-primary-800 aria-selected:hover:border-primary-900',
                        'aria-disabled:cursor-not-allowed aria-disabled:border-clay-100 aria-disabled:bg-clay-10 aria-disabled:hover:border-clay-100',
                      )}
                      onClick={() => {
                        if (!isIncludeModelInCollection) {
                          setSelectedCollection(el);
                        }
                      }}
                    >
                      <div className="flex size-16 items-center justify-center rounded-lg bg-primary-30 group-aria-disabled:bg-clay-20 xs:size-20">
                        <Icon
                          className="size-8 text-primary-800 group-aria-disabled:text-clay-300 xs:size-10"
                          name={getCategoryIcon(el.icon || '')}
                        />
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div>
                          <div className="mb-1 line-clamp-1 text-lg/6 font-semibold text-clay-900">
                            {el.name}
                          </div>
                          <div className="line-clamp-1 text-sm/4 text-clay-500">{el.description}</div>
                        </div>
                        <div className="line-clamp-1 text-sm text-clay-300">{el.models.length} Models</div>
                      </div>
                      <div className="flex items-end gap-2">
                        <Button
                          asIcon
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            onEditModelCollection(el);
                          }}
                          size="extra-small"
                        >
                          <Icon className="size-4" name="penLineStroke" />
                        </Button>
                        <Button
                          asIcon
                          color="red"
                          isLoading={deleteCollectionId.current === el._id && isDeleteModelCollectionsPending}
                          onClick={async (e) => {
                            e.stopPropagation();
                            deleteCollectionId.current = el._id;
                            await deleteModelCollections(el._id);
                            queryClient.invalidateQueries({
                              queryKey: modelCollectionsQueryKeys.myCollections(),
                            });
                          }}
                          size="extra-small"
                        >
                          <Icon className="size-4" name="trash" />
                        </Button>
                      </div>

                      <div
                        className={twMerge(
                          'absolute right-0 top-0 flex size-7 items-center justify-center rounded-bl-lg bg-primary-800 opacity-0 duration-200',
                          'group-hover:opacity-15 group-aria-disabled:bg-clay-300 group-aria-disabled:opacity-40 group-aria-selected:opacity-100',
                        )}
                      >
                        <Icon className="size-3.5 text-white" name="check" />
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          )}
        </Modal.ScrollableContent>

        <div className="mt-4 flex justify-between gap-2">
          <Button color="secondary" onClick={() => onOpenCreateCollection()}>
            Create New Collection
          </Button>
          <Button
            disabled={!selectedCollection}
            isLoading={isEditModelCollectionsPending}
            onClick={handleSubmit}
          >
            Done
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
