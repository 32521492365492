import { motion } from 'framer-motion';

import { Label } from 'shared/ui/Label';
import { Select } from 'shared/ui/Select';
import { Slider } from 'shared/ui/Slider';
import { Switch } from 'shared/ui/Switch';

import type { AudioClassificationParams, TextToSpeechQueryParams } from '../types';

type Props = {
  onChange: (params: Partial<AudioClassificationParams>) => void;
  queryParams: AudioClassificationParams;
};

export const AudioClassification = ({ onChange, queryParams }: Props) => {
  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className="grid min-h-fit grid-cols-2 gap-2.5 overflow-hidden pb-4"
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <div className="col-span-2">
        <Select
          label="Sampling Rate"
          onValueChange={(value) =>
            onChange({ samplingRate: Number(value) as TextToSpeechQueryParams['samplingRate'] })
          }
          value={`${queryParams.samplingRate}`}
        >
          <Select.Content>
            {[16000, 22050, 24000].map((value) => (
              <Select.Item key={value} value={`${value}`}>
                {value.toLocaleString()}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>

      <div>
        <Label>Temperature</Label>
        <Slider
          controls
          max={1}
          min={0.1}
          onValueChange={(values) => onChange({ temperature: values[0] })}
          step={0.01}
          value={[queryParams.temperature || 1]}
        />
      </div>
      <div>
        <Label>Top K</Label>
        <Slider
          controls
          max={100}
          min={0}
          onValueChange={(values) => onChange({ topK: values[0] })}
          step={1}
          value={[queryParams.topK || 1]}
        />
      </div>
      <div className="flex items-center gap-2">
        <Label className="mb-0 flex cursor-pointer items-center gap-2">
          <Switch
            checked={queryParams.returnTimeStamp}
            onCheckedChange={(isChecked) => onChange({ returnTimeStamp: isChecked })}
          />
          Return timestamps
        </Label>
      </div>
    </motion.div>
  );
};
