export { Page } from './Page';

import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const DatasetsPageLazy = lazy(() => import('./Page').then((module) => ({ default: module.Page })));

export const DatasetDetails = () => (
  <Suspense fallback={<PageLoader />}>
    <DatasetsPageLazy />
  </Suspense>
);
