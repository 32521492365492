import type { PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = { isActive?: boolean; isDisabled?: boolean; onClick?: () => void } & ClassName;

export const Chip = ({ children, className, isActive, isDisabled, onClick }: PropsWithChildren<Props>) => {
  return (
    <div
      className={twMerge(
        'flex h-7 cursor-pointer items-center rounded-lg bg-white px-2 py-1.5 text-sm/none text-clay-500 shadow-md transition-colors hover:bg-primary-40',
        isActive && 'border-primary-800 bg-primary-800 text-white hover:bg-primary-900',
        isDisabled && 'pointer-events-none opacity-50',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
