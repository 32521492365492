import type { ComponentProps } from 'react';

import * as SwitchUI from '@radix-ui/react-switch';
import { twMerge } from 'tailwind-merge';

type Props = {
  thumbClassName?: string;
} & ComponentProps<typeof SwitchUI.Root>;

export const Switch = ({ className, thumbClassName, ...props }: Props) => {
  return (
    <SwitchUI.Root
      {...props}
      className={twMerge(
        'relative h-[18px] w-9 rounded-full bg-clay-20 transition-all duration-200 data-[state=checked]:bg-primary-1000 3xl:h-5',
        'hover:bg-clay-100 focus-visible:bg-clay-100',
        'data-[state=checked]:hover:bg-primary-800 data-[state=checked]:focus-visible:bg-primary-800',
        className,
      )}
    >
      <SwitchUI.Thumb
        className={twMerge(
          'absolute left-[2px] top-[2px] size-4 rounded-full bg-white transition-all duration-200 data-[state=checked]:translate-x-4 3xl:left-0.5 3xl:top-0.5',
          thumbClassName,
        )}
      />
    </SwitchUI.Root>
  );
};
