import { useMemo } from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Icon } from 'shared/ui/Icon';

type Props = { file: File; onRemove: () => void } & ClassName;

export const FilePreview = ({ className, file, onRemove }: Props) => {
  const filePreview = useMemo(() => file && file.type.includes('image') && URL.createObjectURL(file), [file]);

  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className={twMerge('grid min-h-fit grid-cols-1 gap-2 overflow-hidden pt-3', className)}
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <h5 className="text-clay-500">Uploads</h5>

      <div className="flex items-center justify-between rounded-xl border border-clay-20 p-2 pl-3 md:p-3 md:pl-4">
        <div className="flex items-center gap-3">
          {filePreview && <img alt="preview" className="max-h-24 max-w-32 rounded-lg" src={filePreview} />}

          <div className="text-sm md:text-base">{file.name}</div>
        </div>
        <div
          className="flex cursor-pointer items-center justify-center rounded-lg bg-clay-10 p-2 transition-colors hover:bg-clay-20"
          onClick={onRemove}
        >
          <Icon className="size-4 text-clay-500" name="trash" />
        </div>
      </div>
    </motion.div>
  );
};
