import type Stripe from 'stripe';

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { stripeQueryKeys } from './queryKeys';

export const useGetProPricesQuery = <T = Stripe.ApiList<Stripe.Price>>(
  options: UseQueryOptions<Stripe.ApiList<Stripe.Price>, unknown, T> = {},
) => {
  return useQuery({
    staleTime: 1000 * 60 * 60,
    ...options,
    queryFn: async () => {
      const { data } = await axiosInstance.get<Stripe.ApiList<Stripe.Price>>('/stripe/pro/prices');

      return data;
    },
    queryKey: stripeQueryKeys.proPrices(),
  });
};
