import { motion } from 'framer-motion';

import { Label } from 'shared/ui/Label';
import { Select } from 'shared/ui/Select';
import { Slider } from 'shared/ui/Slider';

import type { TextToVideoParams } from '../types';

type Props = {
  onChange: (params: Partial<TextToVideoParams>) => void;
  queryParams: TextToVideoParams;
};

const ratioOptions = (['1:1', '3:4', '4:3', '9:16', '16:9'] as const).map((value) => ({
  label: value,
  value,
}));

const durationOptions = (['2s', '4s', '8s'] as const).map((value) => ({
  label: value,
  value,
}));

export const TextToVideo = ({ onChange, queryParams }: Props) => {
  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className="grid min-h-fit grid-cols-2 gap-2.5 overflow-hidden pb-4"
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <div>
        <Select
          label="Aspect ratio"
          onValueChange={(value) => onChange({ aspectRatio: value as TextToVideoParams['aspectRatio'] })}
          value={queryParams.aspectRatio}
        >
          <Select.Content>
            {ratioOptions.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Select
          label="Duration"
          onValueChange={(value) => onChange({ duration: value as TextToVideoParams['duration'] })}
          value={queryParams.duration}
        >
          <Select.Content>
            {durationOptions.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Label>Number of Inference Steps</Label>
        <Slider
          controls
          max={50}
          min={1}
          onValueChange={(values) => onChange({ numInferenceSteps: values[0] })}
          step={1}
          value={[queryParams.numInferenceSteps]}
        />
      </div>
      <div>
        <Label>CFG Scale</Label>
        <Slider
          controls
          max={30}
          min={1}
          onValueChange={(values) => onChange({ cfgScale: values[0] })}
          step={0.5}
          value={[queryParams.cfgScale]}
        />
      </div>

      {/* <div>
        <Label>Seed</Label>
        <Input
          onChange={(e) => {
            const numValue = Number(e.target.value);
            if (numValue > 0) {
              onChange({ seed: numValue });
            }
          }}
          type="number"
          value={queryParams.seed}
        />
      </div> */}
    </motion.div>
  );
};
