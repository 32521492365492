import type { ReactNode } from 'react';
import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Button } from 'shared/ui/Button';
import { DatePicker } from 'shared/ui/DatePicker';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';
import { toaster } from 'shared/ui/Toast';

import type { SortFilter } from '../GalleryFilters';

type Props = {
  onSortChange: (value: SortFilter) => void;
};

const statuses: { dot?: ReactNode; label: string; value: string }[] = [
  { label: 'All', value: 'all' },
  { dot: <div className="size-1 self-start rounded bg-green-600" />, label: 'Active', value: 'active' },
];

export const Filter = ({ onSortChange }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [status, setStatus] = useState<'active' | 'all' | 'inactive'>('all');
  const [isPopoverOpen, setisPopoverOpen] = useState(false);

  const lg = useMinWidthMediaQuery('lg');

  return (
    <Select
      className={twMerge(
        'h-9 w-fit rounded-lg bg-clay-10 p-3 text-sm inner-border-0 hover:bg-clay-40 sm:text-base',
        isPopoverOpen && 'bg-white',
      )}
      iconClassName="mr-1 lg:mr-2"
      iconName="filter"
      onOpenChange={setisPopoverOpen}
      onValueChange={onSortChange}
      open={isPopoverOpen}
      placeholder={lg ? 'Filter' : undefined}
      placeholderClassName="font-medium text-clay-600"
    >
      <Select.Content className="z-10 rounded-lg p-2 pt-1 shadow-sm">
        <div className="flex flex-col gap-3">
          <div className="border-b border-clay-20 p-2.5 pt-2 text-sm font-medium text-clay-350">Filter</div>

          <div className="flex w-full flex-col">
            <div className="flex justify-between pb-1.5">
              <div className="text-xs text-clay-500 sm:text-sm">Data Range</div>
              <div
                className="cursor-pointer text-xs text-primary-800 hover:text-primary-1000 sm:text-sm"
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                }}
              >
                Reset
              </div>
            </div>

            <div className="flex gap-2">
              <div className="flex h-14 flex-col gap-2">
                <div className="h-3.5 text-xs text-clay-300 sm:text-sm">From</div>
                <DatePicker onDateChange={setStartDate} selectedDate={startDate} />
              </div>

              <div className="flex h-14 flex-col gap-2">
                <div className="h-3.5 text-xs text-clay-300 sm:text-sm">To</div>
                <DatePicker onDateChange={setEndDate} selectedDate={endDate} />
              </div>
            </div>
          </div>

          <hr className="border-t border-clay-20" />

          <div className="flex w-full flex-col">
            <div className="flex justify-between pb-1.5">
              <div className="text-xs text-clay-500 sm:text-sm">Status</div>
              <div
                className="cursor-pointer text-xs text-primary-800 hover:text-primary-1000 sm:text-sm"
                onClick={() => setStatus('all')}
              >
                Reset
              </div>
            </div>

            <Select
              className=" bg-white py-2.5 pl-3 pr-1.5 shadow-sm hover:bg-clay-10"
              onValueChange={(value) => {
                setStatus(value as 'active' | 'all' | 'inactive');
              }}
              value={status}
            >
              <Select.Content className="z-50">
                {statuses?.map((status) => {
                  return (
                    <Select.Item key={status.value} value={status.value}>
                      <div className="flex gap-0.5 font-normal text-clay-600 lg:text-xs">
                        {status.label}
                        {status.dot}
                      </div>
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select>
          </div>

          <hr className="border-t border-clay-20" />

          <div className="flex h-8 w-full justify-between">
            <Button
              className="pl-2.5 pr-3 outline-0"
              color="secondary"
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
                setStatus('all');
                setisPopoverOpen(false);
              }}
              size="extra-small"
              variant="filled-light"
            >
              <Icon className="size-3 text-clay-350" name="refresh" />
              <div className="mt-px text-xs text-clay-350 sm:text-sm">Reset all</div>
            </Button>

            <Button
              className="flex h-8 items-center justify-center gap-2 rounded-lg bg-primary-800 pl-2.5 pr-3 outline-0"
              onClick={() => {
                setisPopoverOpen(false);
                toaster.info('Coming soon');
              }}
              size="extra-small"
              variant="filled-light"
            >
              <Icon className="size-3 text-white" name="check" />
              <div className="text-xs font-medium text-white sm:text-sm">Apply</div>
            </Button>
          </div>
        </div>
      </Select.Content>
    </Select>
  );
};
