import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useUser } from 'app/stores/user';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { TermsLinks } from 'shared/ui/TermsLinks';

import gmailSrc from './ui/gmail.png';

export const VerifyEmail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { user } = useUser();

  if (!state?.email || !user) {
    return <Navigate replace to="/preview" />;
  }

  return (
    <AnimateRoute className="my-auto flex h-full min-h-dvh flex-col px-3">
      <form className="m-auto flex w-full max-w-96 flex-col items-center justify-center">
        <h2 className="mb-2 text-xl font-medium">Verify your email</h2>
        <div className="mb-8 text-center text-sm text-clay-500">
          We sent an email to <span className="font-semibold">{state?.email || 'Unknown'}</span>. Click the
          link inside to get started.
        </div>

        <div className="mb-2 flex w-full flex-col">
          <Button color="secondary" onClick={() => window.open('https://mail.google.com/mail/', 'blank')}>
            <img alt="gmail" height={22} src={gmailSrc} width={22} />
            Open Gmail
          </Button>
        </div>

        <div className="mb-6 text-xs text-clay-500">Make sure to check your spam folder</div>

        <Button className="w-full" color="secondary" onClick={() => navigate('/')} variant="filled-light">
          Skip this step
        </Button>
      </form>

      <TermsLinks className="absolute bottom-6 left-1/2 mx-auto -translate-x-1/2" />
    </AnimateRoute>
  );
};
