import type { SyntaxHighlighterProps } from 'react-syntax-highlighter';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { githubGist } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { twMerge } from 'tailwind-merge';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { toaster } from '../Toast';

type Props = {
  className?: string;
  enableCopy?: boolean;
} & SyntaxHighlighterProps;

export const CodeBlock = ({ children, className, enableCopy, ...rest }: Props) => {
  return (
    <div className={twMerge('relative grid max-h-[600px] grid-cols-1 rounded-lg text-sm', className)}>
      {enableCopy && (
        <CopyToClipboard onCopy={() => toaster.success('Copied')} text={children.toString()}>
          <Button className="absolute right-2 top-0 w-fit px-1.5 py-0.5" color="white" size="extra-small">
            <Icon className="size-3 text-clay-800" name="copy" /> Copy
          </Button>
        </CopyToClipboard>
      )}

      <div className="flex-1 overflow-x-scroll">
        <SyntaxHighlighter language="json" style={githubGist} wrapLongLines {...rest}>
          {/* <SyntaxHighlighter language="json" style={githubGist} wrapLongLines {...rest}> */}
          {children}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};
