import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { getFileByCidRequest } from './getFileByCidRequest';
import { ipfsKeys } from './queryKeys';

export type FileResponse =
  | {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: any;
      type: Omit<string, 'audio'>;
    }
  | AudioFileResponse;

export type AudioFileResponse = { data: Blob; duration: number; type: 'audio' };

export const useGetFileByCidListQuery = (
  cidList: string[],
  options: UseQueryOptions<(FileResponse | undefined)[] | undefined> = {},
) => {
  return useQuery({
    queryFn: async () => {
      try {
        const data = await Promise.all(cidList.map((cid) => getFileByCidRequest({ cid })));

        return data;
      } catch (e) {
        console.log(e);
      }
    },
    queryKey: ipfsKeys.file({ cid: cidList.join(',') }),
    ...options,
  });
};
