import { motion } from 'framer-motion';

import {
  getIsAudioClassification,
  getIsAutoSpeechRecognition,
  getIsDocumentQA,
  getIsImageGeneration,
  getIsOCR,
  getIsSentenceSimilarity,
  getIsSpeechToText,
  getIsTextToSpeech,
  getIsTextToVideo,
  getIsTimeSeriesForecasting,
  getIsUnconditionalImageGen,
} from 'features/ModelQueryParams/helpers/modelParamsChecks';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { Slider } from 'shared/ui/Slider';

import type { QueryParams } from './types';

import { AudioClassification } from './ui/AudioClassification';
import { AutoSpeechRecognition } from './ui/AutoSpeechRecognition';
import { DocumentQA } from './ui/DocumentQA';
import { ImageGen } from './ui/ImageGen';
import { OCR } from './ui/OCR';
import { SentenceSimilarity } from './ui/SentenceSimilarity';
import { SpeechToText } from './ui/SpeechToText';
import { TextToSpeech } from './ui/TextToSpeech';
import { TextToVideo } from './ui/TextToVideo';
import { TimeSeriesForecasting } from './ui/TimeSeriesForecasting';
import { UnconditionalImageGen } from './ui/UnconditionalImageGen';

type Props = {
  modelType: string;
  onChange: (params: Partial<QueryParams>) => void;
  value: QueryParams;
};

export const ModelQueryParams = ({ modelType, onChange, value: queryParams }: Props) => {
  const isTextToSpeech = getIsTextToSpeech(queryParams, modelType);
  const isSpeechToText = getIsSpeechToText(queryParams, modelType);
  const isAudioClassification = getIsAudioClassification(queryParams, modelType);
  const isDocumentQA = getIsDocumentQA(queryParams, modelType);
  const isOCR = getIsOCR(queryParams, modelType);
  const isUnconditionalImageGen = getIsUnconditionalImageGen(queryParams, modelType);
  const isAutoSpeechRecognition = getIsAutoSpeechRecognition(queryParams, modelType);
  const isSentenceSimilarity = getIsSentenceSimilarity(queryParams, modelType);
  const isImageGeneration = getIsImageGeneration(queryParams, modelType);
  const isTextToVideo = getIsTextToVideo(queryParams, modelType);
  const isTimeSeriesForecasting = getIsTimeSeriesForecasting(queryParams, modelType);

  if (isTimeSeriesForecasting) {
    return <TimeSeriesForecasting onChange={onChange} queryParams={queryParams} />;
  }

  if (isTextToVideo) {
    return <TextToVideo onChange={onChange} queryParams={queryParams} />;
  }

  if (isImageGeneration) {
    return <ImageGen onChange={onChange} queryParams={queryParams} />;
  }

  if (isSentenceSimilarity) {
    return <SentenceSimilarity onChange={onChange} queryParams={queryParams} />;
  }

  if (isAutoSpeechRecognition) {
    return <AutoSpeechRecognition onChange={onChange} queryParams={queryParams} />;
  }

  if (isUnconditionalImageGen) {
    return <UnconditionalImageGen onChange={onChange} queryParams={queryParams} />;
  }

  if (isOCR) {
    return <OCR onChange={onChange} queryParams={queryParams} />;
  }

  if (isDocumentQA) {
    return <DocumentQA onChange={onChange} queryParams={queryParams} />;
  }

  if (isAudioClassification) {
    return <AudioClassification onChange={onChange} queryParams={queryParams} />;
  }

  if (isSpeechToText) {
    return <SpeechToText onChange={onChange} queryParams={queryParams} />;
  }

  if (isTextToSpeech) {
    return <TextToSpeech onChange={onChange} queryParams={queryParams} />;
  }

  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className="grid min-h-fit grid-cols-2 gap-2.5 overflow-hidden pb-4"
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <div>
        <Label>Temperature</Label>
        <Slider
          controls
          max={2}
          min={0}
          onValueChange={(values) => onChange({ temperature: values[0] })}
          step={0.01}
          value={[queryParams.temperature || 1]}
        />
      </div>
      <div>
        <Label>Top P</Label>
        <Slider
          controls
          max={1}
          min={0}
          onValueChange={(values) => onChange({ topP: values[0] })}
          step={0.01}
          value={[queryParams.topP || 1]}
        />
      </div>

      <div>
        <Label>Frequency penalty</Label>
        <Slider
          controls
          max={2}
          min={0}
          onValueChange={(values) => onChange({ frequencyPenalty: values[0] })}
          step={0.01}
          value={[queryParams.frequencyPenalty || 0]}
        />
      </div>

      <div>
        <Label>Presence penalty</Label>
        <Slider
          controls
          max={2}
          min={0}
          onValueChange={(values) => onChange({ presencePenalty: values[0] })}
          step={0.01}
          value={[queryParams.presencePenalty || 0]}
        />
      </div>
      {queryParams.apiKey !== undefined && (
        <div className="col-span-2">
          <Input
            label="API Key"
            onChange={(e) => onChange({ apiKey: e.target.value })}
            placeholder="Your api key"
            value={queryParams.apiKey}
          />
        </div>
      )}
    </motion.div>
  );
};
