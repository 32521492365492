import { useNavigate } from 'react-router-dom';

import { BackButton } from 'app/ui/BackButton';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';

export const ModelNotFound = () => {
  const navigate = useNavigate();

  return (
    <AnimateRoute className="flex flex-1 flex-col gap-4 px-2 py-4 xs:p-4">
      <BackButton onClick={() => navigate('/')}>Back to models</BackButton>

      <div className="flex size-full flex-col items-center justify-center gap-4 text-sm text-clay-380">
        <p className="text-center">Model not found</p>

        <Button onClick={() => navigate('/')}>Back Home</Button>
      </div>
    </AnimateRoute>
  );
};
