import type {
  AudioClassificationParams,
  AutoSpeechRecognitionParams,
  DefaultQueryParams,
  DocumentQAParams,
  ImageGenerationParams,
  OCRParams,
  QueryParams,
  SentenceSimilarityParams,
  SpeechToTextQueryParams,
  TextToSpeechQueryParams,
  TextToVideoParams,
  TimeSeriesForecastingParams,
  UnconditionalImageGenParams,
} from 'features/ModelQueryParams/types';
import type { ModelApiKeyService } from 'shared/api/models/types';

export const getDefaultParamsByModelType = ({
  apiKeyService,
  modelType,
}: {
  apiKeyService?: ModelApiKeyService;
  modelType: string;
}): QueryParams => {
  if (modelType === 'speech-to-text') {
    return {
      audioBlob: undefined,
      language: 'english',
      profanityFilter: false,
    } as SpeechToTextQueryParams;
  }

  if (modelType === 'text-to-speech') {
    return {
      bitrate: 64,
      emotion: 'neutral',
      language: 'english',
      pitch: 0,
      question: '',
      samplingRate: 24000,
      speed: 1,
      temperature: 1,
      volume: 1,
    } as TextToSpeechQueryParams;
  }

  if (modelType === 'audio-classification') {
    return {
      returnTimeStamp: false,
      samplingRate: 24000,
      temperature: 1,
      topK: 5,
    } as AudioClassificationParams;
  }

  if (modelType === 'document-question-answering') {
    return {
      context: '',
      returnConfidenceScores: false,
      topK: 5,
    } as DocumentQAParams;
  }

  if (modelType === 'ocr') {
    return {
      context: '',
      language: 'english',
      mode: 'plain-texts',
      returnBoundingBoxes: false,
      returnConfidenceScores: false,
      topK: 5,
    } as OCRParams;
  }

  if (modelType === 'unconditional-image-generation') {
    return {
      guidanceScale: 1,
      imageSize: '256x256',
      numInferenceSteps: 10,
      seed: 42,
    } as UnconditionalImageGenParams;
  }

  if (modelType === 'image-generation') {
    return {
      aspectRatio: '512x512',
      cfgScale: 1,
      clipSkip: 1,
      content: '',
      numInferenceSteps: 1,
      question: '',
    } as ImageGenerationParams;
  }

  if (modelType === 'automatic-speech-recognition') {
    return {
      language: 'english',
      returnTimeStamp: false,
      samplingRate: 16000,
      task: 'transcribe',
      temperature: 1,
    } as AutoSpeechRecognitionParams;
  }

  if (modelType === 'sentence-similarity') {
    return {
      poolingMethod: 'mean',
      returnEmbeddings: false,
    } as SentenceSimilarityParams;
  }

  if (modelType === 'text-to-video') {
    return {
      aspectRatio: '1:1',
      cfgScale: 1,
      context: '',
      duration: '2s',
      numInferenceSteps: 1,
      question: '',
    } as TextToVideoParams;
  }

  if (modelType === 'time-series-forecasting') {
    return {
      changePoints: 0,
      evaluationMetric: 'MAE',
      file: undefined,
      forecastHorizon: 1,
      holidays: false,
      learningRate: 0.001,
      private: false,
      seasonality: 'None',
      trend: 'Additive',
    } as TimeSeriesForecastingParams;
  }

  return apiKeyService ? ({ apiKey: '', ...defaultQueryParams } as DefaultQueryParams) : defaultQueryParams;
};

export const defaultQueryParams: QueryParams = {
  context: undefined,
  frequencyPenalty: 0,
  presencePenalty: 0,
  question: '',
  temperature: 1,
  topP: 1,
};

export const ERROR_CODES = [205, 311, 312, 313, 314, 315, 316, 317, 318, 319];
