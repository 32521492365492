import type { WalletType } from 'graz';

import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useAccount } from 'graz';

import type { Section } from 'features/QueryMenu/QueryMenu';
import type { IconName } from 'shared/ui/Icon';

import { useProductPrices } from 'app/stores/prices';
import { UpgradeToProModal } from 'features/UpgradeToProModal';
import { WalletProviderModal } from 'features/WalletProviderModal';
import { NESA_PRO_PRICE_BY_MONTH } from 'shared/const';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';

type Props = {
  isOpen: boolean;
  onConnected?: (params: { address?: string; walletType?: WalletType }) => void;
  onOpenChange: (isOpen: boolean) => void;
};

const items: { icon: IconName; label: string }[] = [
  { icon: 'check', label: 'Unlimited usage across all models' },
  { icon: 'check', label: 'Early access to new models' },
  { icon: 'check', label: 'Nesa Pro badge' },
  { icon: 'check', label: '5x the Nesa Playground Points' },
];

export const LimitReachedModal = ({ isOpen, onConnected, onOpenChange }: Props) => {
  const { id: modelId, section } = useParams<{ id: string; section?: Section }>();

  const [isWalletProviderOpen, setIsWalletProviderOpen] = useState(false);
  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);

  const { data: prices } = useProductPrices();
  const priceByMonth = prices?.['nesa.pro.month'];

  const { data, isConnected, walletType } = useAccount();

  return (
    <>
      <Modal onOpenChange={onOpenChange} open={isOpen}>
        <Modal.Content className="!max-w-[40rem]" innerClassName="p-6">
          <Modal.CloseButton className="right-4 top-4" />

          <div className="mb-5 flex max-w-fit items-center justify-center rounded-md bg-clay-10 p-3.5">
            <Icon className="size-9" name="logo" />
          </div>

          <h1 className="mb-2 text-[24px] font-semibold leading-8 text-clay-800">Daily Limit Reached</h1>
          <h2 className="mb-5 text-[24px] font-semibold leading-8 text-clay-800 xs:mb-8">
            Upgrade to Nesa Pro to Continue Usage
          </h2>

          <div className="mb-7 grid grid-cols-1 gap-3 xs:grid-cols-2">
            <div className="flex flex-col gap-2 py-3 xs:gap-3.5">
              {items.map(({ icon, label }) => {
                return (
                  <div className="flex items-center gap-2" key={label}>
                    <Icon className="size-3.5 min-w-3.5 text-green-800" name={icon} />
                    <div className="text-base/none font-light text-clay-400">{label}</div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col rounded-lg border border-clay-20 p-4">
              <div className="mb-2 flex items-center gap-2 text-sm/none font-semibold">
                Nesa{' '}
                <span className="rounded-[4px] bg-blue-600 px-1.5 py-1 text-[10px] font-semibold uppercase text-white">
                  Pro
                </span>
              </div>

              <div className="mb-3 flex items-end">
                <div className="text-4xl/none font-semibold">
                  ${priceByMonth?.unit_amount ? `${priceByMonth.unit_amount / 100}` : NESA_PRO_PRICE_BY_MONTH}
                </div>
                <div className="text-base/none font-light text-clay-500">/Month</div>
              </div>

              <Button
                className="bg-blue-600 hover:bg-blue-600 hover:brightness-90"
                onClick={() => {
                  onOpenChange(false);
                  setIsUpgradeOpen(true);
                }}
                size="extra-small"
              >
                Upgrade
              </Button>
            </div>
          </div>

          <div className="mb-7 flex items-center gap-2">
            <div className="h-px w-full flex-1 bg-clay-20" />
            <div className="text-xs/none uppercase text-clay-300">or</div>
            <div className="h-px w-full flex-1 bg-clay-20" />
          </div>

          <div className="flex flex-col items-center justify-center pb-3">
            <Icon className="mb-2.5 size-12 text-primary-1100" name="wallet" />
            <div className="mb-4 text-center text-xl font-semibold">On-demand Usage with Wallet</div>
            <Button
              className="mb-4"
              onClick={() => {
                onOpenChange(false);
                !isConnected && setIsWalletProviderOpen(true);

                if (isConnected) {
                  onConnected?.({ address: data?.bech32Address, walletType });
                }
              }}
              size="extra-small"
            >
              {isConnected ? 'Resume Usage' : 'Connect Wallet'}
            </Button>
            <Link
              className="cursor-pointer text-xs font-light text-clay-500 transition-colors hover:text-clay-600"
              target="_blank"
              to="/pricing"
            >
              Learn More
            </Link>
          </div>
        </Modal.Content>
      </Modal>
      <WalletProviderModal
        onConnected={() => {
          onConnected?.({ address: data?.bech32Address, walletType });
        }}
        onOpenChange={setIsWalletProviderOpen}
        open={isWalletProviderOpen}
      />
      <UpgradeToProModal
        onOpenChange={setIsUpgradeOpen}
        open={isUpgradeOpen}
        redirectPath={`/models/${modelId}${section ? `/${section}` : ''}`}
      />
    </>
  );
};
