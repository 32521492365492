import type { Emotion, OCRMode } from './types';

export const ocrModeOptions: { label: string; value: OCRMode }[] = [
  { label: 'Plain texts', value: 'plain-texts' },
  { label: 'Format texts', value: 'format-texts' },
  { label: 'Plain multi-crop', value: 'plain-multi-crop' },
  { label: 'Format multi-crop', value: 'format-multi-crop' },
  { label: 'Plain fine-grained', value: 'plain-fine-grained' },
  { label: 'Format fine-grained', value: 'format-fine-grained' },
];

export const textSpeechEmotionOptions: { label: string; value: Emotion }[] = [
  { label: 'Angry', value: 'angry' },
  { label: 'Happy', value: 'happy' },
  { label: 'Neutral', value: 'neutral' },
  { label: 'Sad', value: 'sad' },
];
