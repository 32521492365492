import type { GetQueryHistoryByPeriodParams } from './types';

export const queryHistoryKeys = {
  base: ['query-history'],
  byId: (params: { id?: string }) => [...queryHistoryKeys.base, 'by-id', params],
  byPeriod: (params: GetQueryHistoryByPeriodParams) => [...queryHistoryKeys.base, 'by-period', params],
  list: (params: {
    id?: string;
    limit: number;
    modelId?: string;
    skip?: number;
    type?: string;
    userId?: string;
    walletAddress?: string;
  }) => [...queryHistoryKeys.base, params],
};
