import type { Model } from 'shared/api/models/types';

import { Icon } from 'shared/ui/Icon';

import { Inference } from './Inference';
import { Steps } from './Steps';

type Props = {
  model?: Model;
  txDataCode: null | number;
  txDataErrorMessage: null | string;
};

export const InferenceMap = ({ model, txDataCode, txDataErrorMessage }: Props) => {
  return (
    <div className="grid min-h-20 flex-1 grid-cols-1 gap-2 rounded-2xl bg-white p-1 md:grid-cols-5 ">
      <div className="col-span-1 flex flex-col gap-2 p-4 pb-1 pr-2 md:col-span-3 md:pb-2">
        <div className="flex items-center gap-2 text-clay-900">
          <Icon className="size-4 text-clay-400" name="database" />
          <span className="text-sm/none">Realtime Inference Map</span>
        </div>

        <div className="mt-auto">
          <div className="mb-2 flex items-center gap-3 uppercase">
            <div className="size-1 rounded-full bg-green-600" />{' '}
            <span className="text-xs/none">operational</span>
          </div>

          <Inference code={txDataCode} />
        </div>
      </div>
      <div className="col-span-1 h-full rounded-xl bg-clay-10 md:col-span-2">
        <Steps code={txDataCode} errorMessage={txDataErrorMessage} latency={model?.latency} />
      </div>
    </div>
  );
};
