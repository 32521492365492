import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { UploadImageParams } from './types';

export const useUploadImageMutation = (
  options: UseMutationOptions<{ cid: string; name?: string }, Error, UploadImageParams> = {},
) => {
  return useMutation({
    mutationFn: async ({ file }: UploadImageParams) => {
      const formData = new FormData();

      formData.append('file', file);

      const { data } = await axiosInstance.post<{ cid: string; name?: string }>(`/images/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 5 * 60 * 1000,
      });

      return data;
    },
    ...options,
  });
};
