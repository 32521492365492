import { useNavigate } from 'react-router-dom';

import { useGetRandomPickModelQuery } from 'shared/api/models/useGetRandomPickModelQuery';
import { Icon } from 'shared/ui/Icon';

import curiousBg from './assets/curious-bg.png';

export const CuriousBlock = () => {
  const { data: model } = useGetRandomPickModelQuery();
  const navigate = useNavigate();
  return (
    <div
      className="group flex cursor-pointer flex-col items-center rounded-xl bg-primary-40 bg-cover bg-center bg-no-repeat p-4 transition-colors hover:bg-primary-300"
      onClick={() => {
        model && navigate(`/models/${model.model._id}`, { state: { sidebar: true } });
      }}
      style={{ backgroundImage: `url(${curiousBg})` }}
    >
      <Icon
        className="mb-2 size-6 stroke-primary-800 text-transparent transition-colors group-hover:stroke-white"
        name="sparkle"
      />

      <div className="mb-2 max-w-fit select-none text-center text-[14px] font-semibold transition-colors group-hover:text-white sm:max-w-24">
        Im Feeling Curious
      </div>

      <div className="ml-[-2px] mt-auto flex items-center gap-2 text-white">
        <Icon className="size-4 stroke-white text-transparent" name="shuffle" />
        <span className="text-base/none">Randomize</span>
      </div>
    </div>
  );
};
