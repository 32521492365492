import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import isEmail from 'validator/es/lib/isEmail';

import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
// import appleSrc from './ui/assets/apple.png';
// import googleSrc from './ui/assets/google.png';

interface Props {
  isOpen: boolean;
  noOverlay?: boolean;
  onLoginClick: () => void;
  onOpenChange: (isOpen: boolean) => void;
}

interface FormValues {
  email: string;
}

export const SignupModal = ({ isOpen, noOverlay, onLoginClick, onOpenChange }: Props) => {
  const navigate = useNavigate();

  const {
    formState: { errors, isValid },
    getValues,
    register,
    reset,
  } = useForm<FormValues>({
    defaultValues: { email: '' },
    mode: 'onChange',
  });

  const handleClose = () => {
    reset();
    onOpenChange(false);
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content
        className="max-w-full sm:!max-w-96"
        innerClassName="bg-[#1E1E1E]"
        noOverlay={noOverlay}
        wrapperClassName="z-[100]"
      >
        <Modal.Title className="mb-4 font-light text-white">Create your account</Modal.Title>

        <form
          className="mb-4 flex flex-col gap-2"
          onSubmit={(e) => {
            e.preventDefault();

            const { email } = getValues();

            navigate('/signup', { state: { email } });
            handleClose();
          }}
        >
          <Input
            classNameInput="placeholder:text-white/20 text-white font-light"
            classNameInputWrapper="bg-[#1E1E1E] !border-white/10"
            errorSpacing
            {...register('email', { validate: (val) => (!isEmail(val) ? 'Invalid email' : true) })}
            error={errors.email?.message}
            placeholder="Email Address"
          />

          <Button disabled={!isValid} type="submit">
            Continue
          </Button>
        </form>

        <div className="flex flex-wrap justify-center gap-1 text-center text-xs text-clay-500">
          Already have an account?
          <a
            className="cursor-pointer text-primary-800 transition-colors hover:text-primary-1000"
            onClick={onLoginClick}
          >
            Log in
          </a>
        </div>
      </Modal.Content>
    </Modal>
  );
};
