import type { GenericAbortSignal } from 'axios';

import { axiosInstance } from 'shared/config/axiosInstance';
import { getAudioFileDuration } from 'shared/helpers/getAudioFileDuration';

import type { FileResponse } from './useGetFileByCidListQuery';

type Props = {
  cid: string;
  signal?: GenericAbortSignal;
};

export const getFileByCidRequest = async ({ cid, signal }: Props): Promise<FileResponse | undefined> => {
  try {
    const { data, headers, ...other } = await axiosInstance.get(`/images/${cid}`, {
      signal,
    });

    const isImage = headers['content-type'].includes('image'); // image
    const isAudio = headers['content-type'].includes('audio'); // image
    console.log({ data, other, type: headers['content-type'] });

    if (isImage) {
      const { data: blobData } = await axiosInstance.get<Blob>(`/images/${cid}`, {
        responseType: 'blob',
        signal,
        timeout: 60 * 1000,
      });

      return { data: URL.createObjectURL(blobData), type: 'image' };
    }

    if (isAudio) {
      const { data: blobData } = await axiosInstance.get<Blob>(`/images/${cid}`, {
        responseType: 'blob',
        signal,
        timeout: 60 * 1000,
      });

      const duration = await getAudioFileDuration(blobData);

      return { data: blobData, duration, type: 'audio' };
    }

    return { data, type: 'data' };
  } catch (e) {
    console.log(e);
  }
};
