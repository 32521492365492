import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { ExpandModal } from 'features/ModelQuery/ui/ExpandModal';
import { Icon } from 'shared/ui/Icon';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

type Props = {
  isLoading?: boolean;
  src: string | undefined;
} & ClassName;

export const ImageData = ({ className, isLoading, src }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={twMerge('relative inline-block h-40 overflow-hidden', className)}>
      <img className="h-full rounded-lg" src={src} />
      <StretchedSkeleton enable={isLoading} />

      {src && (
        <>
          <div
            className="absolute right-1 top-1 flex size-9 cursor-pointer items-center justify-center rounded-xl bg-black/20 text-white backdrop-blur-md transition-colors hover:text-clay-20"
            onClick={() => setIsExpanded(true)}
          >
            <Icon className="size-4 text-white" name="expand" />
          </div>
          <ExpandModal imageSrc={src} isOpen={isExpanded} onOpenChange={setIsExpanded} />
        </>
      )}
    </div>
  );
};
