import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

type Props = {
  isLoading?: boolean;
  src: string | undefined;
} & ClassName;

export const VideoData = ({ className, isLoading, src }: Props) => {
  return (
    <div className={twMerge('relative h-40 overflow-hidden', className)}>
      <video autoPlay className="h-full rounded-lg" loop muted playsInline src={src} />
      <StretchedSkeleton enable={isLoading} />
    </div>
  );
};
