import type { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';

import { getCategoryLabel } from 'features/CategoryModelsDropdown';
import { getPrice } from 'shared/helpers/getPrice';

import { Card } from '../Card';
import { Table } from '../Table';

type Props = {
  model: Model;
};

export const ModelDescription = ({ model }: Props) => {
  const detailsTable = [
    { title: 'Model Author', value: model.org },
    { title: 'Modality', value: getCategoryLabel(model.type) },
    {
      title: 'Input Price',
      value: model.pricing ? `$${getPrice(model.pricing?.input_price)} per 1M tokens` : '',
    },
    {
      title: 'Output Price',
      value: model.pricing ? `$${getPrice(model.pricing?.output_price)} per 1M tokens` : '',
    },
  ];

  const performanceTableData =
    model.extendedDescription?.performance_table &&
    Object.entries(model.extendedDescription?.performance_table).reduce<{ [key: string]: string }[]>(
      (acc, [propertyTitle, value]) => {
        value.forEach((value, idx) => {
          acc[idx] = {
            ...(acc[idx] || {}),
            [propertyTitle]: value,
          };
        });

        return acc;
      },
      [],
    );

  const performanceTableColumns =
    model.extendedDescription?.performance_table &&
    Object.keys(model.extendedDescription?.performance_table).reduce<
      { key: string; renderTd: (item: unknown) => ReactNode; title: string }[]
    >((acc, propertyTitle) => {
      acc.push({
        key: propertyTitle,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderTd: (item: any) => {
          return item[propertyTitle] as string;
        },
        title: propertyTitle,
      });

      return acc;
    }, []);

  return (
    <Card
      className={twMerge(
        'overflow-y-scroll border border-clay-20 md:col-span-3',
        model && ['depth-estimation', 'image-generation'].includes(model.type) && 'md:col-span-2',
      )}
      key="description"
    >
      <div className="mb-4 flex flex-col gap-1.5">
        <h2 className="text-lg font-medium text-clay-600">Model Description</h2>

        <div className="text-base font-light text-clay-500">
          {model?.extendedDescription?.description || model?.description || '--'}
        </div>
      </div>

      {model?.extendedDescription?.about_author && (
        <div className="mb-4 flex flex-col gap-1.5">
          <h2 className="text-lg font-medium text-clay-600">About the Author</h2>

          <div className="text-base font-light text-clay-500">
            {model?.extendedDescription?.about_author || '--'}
          </div>
        </div>
      )}

      {model?.extendedDescription?.disclaimer && (
        <div className="mb-8 flex flex-col gap-1.5">
          <h2 className="text-lg font-medium text-clay-600">Disclaimer</h2>

          <div className="text-base font-light text-clay-500">
            {model?.extendedDescription?.disclaimer || '--'}
          </div>
        </div>
      )}

      <div className="relative mb-8 overflow-x-scroll">
        <Table
          columns={[
            {
              key: 'title',
              renderTd: ({ title }) => {
                return title;
              },
              title: 'Model Info',
            },
            {
              key: 'value',
              renderTd: ({ value }) => {
                return value;
              },
              title: 'Details',
            },
          ]}
          data={detailsTable}
        />
      </div>

      {performanceTableData && performanceTableColumns && (
        <div className="relative mb-8 overflow-x-scroll">
          <Table columns={performanceTableColumns || []} data={performanceTableData} />
        </div>
      )}

      {model?.extendedDescription?.license && (
        <div className="mb-4 flex flex-col gap-1.5">
          <h2 className="text-lg font-medium text-clay-600">License</h2>
          <div className="mb-2 text-base font-light text-clay-400">
            Please adhere to this model’s license agreement as set forth by the model author.
          </div>

          <div className="rounded-xl bg-clay-10 p-3 font-fira text-sm font-light text-clay-500">
            {model?.extendedDescription?.license || '--'}
          </div>
        </div>
      )}
    </Card>
  );
};
