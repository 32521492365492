import type { WillChangeEvent } from '@egjs/react-flicking';

import { useRef, useState } from 'react';

import Flicking from '@egjs/react-flicking';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { useGetFileByCidListQuery } from 'shared/api/ipfs/useGetFileByCidListQuery';
import { getIsEmptyResponse } from 'shared/helpers/getIsEmptyResponse';
import { getStringifiedJSONData } from 'shared/helpers/getStringifiedJSONData';
import { CodeBlock } from 'shared/ui/CodeBlock';
import { Icon } from 'shared/ui/Icon';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

import { IpfsImage } from './IpfsImage';

type Props = {
  cidList: string[];
};
export const DataCarousel = ({ cidList }: Props) => {
  const ref = useRef<Flicking>(null);

  const { data = [], isLoading } = useGetFileByCidListQuery(cidList, {
    enabled: cidList && cidList.length > 0,
  });

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="grid grid-cols-1 gap-3 overflow-hidden"
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
    >
      {isLoading ? (
        <>
          <motion.div
            animate={{ opacity: 1 }}
            className="flex overflow-x-scroll"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <div className="relative mr-2 flex h-56 min-w-[340px] flex-col gap-1 overflow-hidden rounded-xl">
              <StretchedSkeleton enable />
            </div>
            <div className="relative mr-2 flex h-56 min-w-[340px] flex-col gap-1 overflow-hidden rounded-xl">
              <StretchedSkeleton enable />
            </div>
            <div className="relative mr-2 flex h-56 min-w-[340px] flex-col gap-1 overflow-hidden rounded-xl">
              <StretchedSkeleton enable />
            </div>
          </motion.div>

          <motion.div
            animate={{ opacity: 1 }}
            className="flex gap-2 pb-3"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <div className="relative size-5 overflow-hidden rounded-md">
              <StretchedSkeleton enable />
            </div>
            <div className="relative size-5 overflow-hidden rounded-md">
              <StretchedSkeleton enable />
            </div>
          </motion.div>
        </>
      ) : (
        <>
          <Flicking
            align="prev"
            bound
            cameraClass="flex"
            cameraTag="div"
            circular={false}
            defaultIndex={0}
            horizontal
            noPanelStyleOverride
            onWillChange={(e: WillChangeEvent) => {
              setActiveIndex(e.index);
            }}
            ref={ref}
            renderOnSameKey={false}
            viewportTag="div"
          >
            {data?.map((item, idx) => {
              const isEmptyData = item && getIsEmptyResponse(item?.data);

              return (
                <div className="flex max-h-56 min-w-[340px] flex-col gap-1 rounded-lg pr-2" key={idx}>
                  {item?.type === 'image' ? (
                    <IpfsImage className="h-56 max-h-56" imageSrc={item?.data || ''} />
                  ) : item?.type === 'data' ? (
                    <>
                      {isEmptyData ? (
                        <div className="my-auto flex items-center justify-center text-xs text-clay-500">
                          No predictions available for the given input.
                        </div>
                      ) : (
                        <CodeBlock className="h-full">{getStringifiedJSONData(item?.data)}</CodeBlock>
                      )}
                    </>
                  ) : (
                    <div className="flex items-center justify-center text-xs text-clay-400">Unsupported</div>
                  )}
                </div>
              );
            })}
          </Flicking>

          <div className="flex items-center gap-2 pb-3">
            <Icon
              className={twMerge(
                'size-4 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
                (activeIndex === undefined || activeIndex <= 0) && 'cursor-not-allowed opacity-50',
              )}
              name="arrowLeftLong"
              onClick={() => {
                if (activeIndex > 0) {
                  ref.current?.prev();
                }
              }}
            />
            <Icon
              className={twMerge(
                'size-4 rotate-180 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
                activeIndex >= data.length - 1 && 'cursor-not-allowed opacity-50',
              )}
              name="arrowLeftLong"
              onClick={() => {
                if (activeIndex < data.length - 1) {
                  ref.current?.next();
                }
              }}
            />
          </div>
        </>
      )}
    </motion.div>
  );
};
