import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { getStringifiedJSONData } from 'shared/helpers/getStringifiedJSONData';
import { Accordion } from 'shared/ui/Accordion';
import { CodeBlock } from 'shared/ui/CodeBlock';
import { Modal } from 'shared/ui/Modal';

export type GetModelParamsCb = () => {
  chatRequest: unknown;
  expectedInput?: string;
  model: string;
  walletType?: string;
};

type Props = {
  getModelParams: GetModelParamsCb;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const SdkCodeModal = ({ getModelParams, isOpen, onOpenChange }: Props) => {
  if (!isOpen) return;

  const data = getModelParams();

  const isExpectsText = data.expectedInput?.includes('text');

  const params = getStringifiedJSONData({
    model: data.model,
    // eslint-disable-next-line perfectionist/sort-objects
    messages: `[{ content: '${isExpectsText ? '<insert query here>' : '<insert ipfs cid here>'}'', role: 'user' }]`,
  });
  const paramsStr = getStringifiedJSONData(data.chatRequest);

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-screen-md">
        <Modal.CloseButton />

        <h1 className=" mb-3 border-b border-clay-20 pb-2 text-2xl font-semibold">
          How to use from the Nesa SDK library
        </h1>

        <CodeBlock className="mb-4 border-b border-clay-20 pb-0" enableCopy language="javascript">
          {`// Customize your query
const chatRequest = ${params};
          `}
        </CodeBlock>

        <div className="px-2">
          <Accordion>
            <Accordion.Trigger>Full Code Snippet</Accordion.Trigger>

            <Accordion.Content>
              <CodeBlock className="min-w-fit" enableCopy language="javascript" wrapLongLines>
                {`
// Documentation: https://docs.nesa.ai/nesa/using-nesa/via-sdk
import { ChatClient } from 'nesa-sdk';

const chainInfo = ${JSON.stringify(nesaTestnet, undefined, 4)}

const ChatUtils = new ChatClient({
  chainInfo: chainInfo,
  modelName: "${data.model}",  //  model name
  mnemonic: "<wallet_mnemonic>" 
})

const chatRequest = ${paramsStr};

const main = async () => {
  const sessionStream = await client.requestSession();
  sessionStream.on('data', async (data: { code: number; message: string }) => {
    if (data.code === 200) {
      const dataStream = await client.requestChat(chatRequest);
      dataStream.on('data', (data) => {});
      dataStream.on('end', () => {
        client.requestCloseHeartbeat();
      });
    }
  });
  sessionStream.on('end', () => {});
};

main()
  .then(() => {})
  .catch((e) => console.error(e));
  `}
              </CodeBlock>
            </Accordion.Content>
          </Accordion>
        </div>
      </Modal.Content>
    </Modal>
  );
};
