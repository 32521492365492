import { motion } from 'framer-motion';

import { Label } from 'shared/ui/Label';
import { Select } from 'shared/ui/Select';
import { Slider } from 'shared/ui/Slider';
import { Switch } from 'shared/ui/Switch';

import type { Language, OCRMode, OCRParams as TOCRParams } from '../types';

import { ocrModeOptions } from '../consts';
import { languages } from '../languages';

type Props = {
  onChange: (params: Partial<TOCRParams>) => void;
  queryParams: TOCRParams;
};

export const OCR = ({ onChange, queryParams }: Props) => {
  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className="grid min-h-fit grid-cols-1 gap-2.5 overflow-hidden pb-4"
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <div>
        <Label>Top K</Label>
        <Slider
          controls
          max={100}
          min={0}
          onValueChange={(values) => onChange({ topK: values[0] })}
          step={1}
          value={[queryParams.topK || 1]}
        />
      </div>
      <div>
        <Select
          label="Language"
          onValueChange={(value) => onChange({ language: value as Language })}
          value={queryParams.language}
        >
          <Select.Content>
            {languages.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Select
          label="OCR Mode"
          onValueChange={(value) => onChange({ mode: value as OCRMode })}
          value={queryParams.mode}
        >
          <Select.Content>
            {ocrModeOptions.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div className=" flex items-center gap-2">
        <Label className="mb-0 flex cursor-pointer items-center gap-2">
          <Switch
            checked={queryParams.returnConfidenceScores}
            onCheckedChange={(isChecked) => onChange({ returnConfidenceScores: isChecked })}
          />
          Return Confidence Scores
        </Label>
      </div>
      <div className=" flex items-center gap-2">
        <Label className="mb-0 flex cursor-pointer items-center gap-2">
          <Switch
            checked={queryParams.returnBoundingBoxes}
            onCheckedChange={(isChecked) => onChange({ returnBoundingBoxes: isChecked })}
          />
          Return Bounding Boxes
        </Label>
      </div>
    </motion.div>
  );
};
