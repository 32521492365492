import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const EnterprisePageLazy = lazy(() => import('./Page').then((module) => ({ default: module.Page })));

export const Enterprise = () => (
  <Suspense fallback={<PageLoader />}>
    <EnterprisePageLazy />
  </Suspense>
);
