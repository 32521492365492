import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useDebounce, useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import { theme } from 'app/theme';
import { getCategoryLabel } from 'features/CategoryModelsDropdown';
import { useLatestUsedModelsInfiniteQuery } from 'shared/api/transactions/useLatestUsedModelsInfiniteQuery';
import { getSplittedModelName } from 'shared/helpers/getSplittedModelName';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

// import { TrendingSection } from './ui/TrendingSection';

const heightExpanded = '25.4rem';
// const heightExpanded = '34rem';
const sizeCollapsed = '3rem';

const getTimePast = (date: string) => {
  const diffs = DateTime.fromISO(date).plus({ minutes: 1 }).diffNow(['second', 'hour', 'day', 'minute']);
  const hourDiff = Math.abs(diffs.hours);

  if (hourDiff > 0) {
    return `${Math.floor(hourDiff)} h`;
  }

  const minuteDiff = Math.abs(diffs.minutes);
  if (minuteDiff > 0) {
    return `${Math.floor(minuteDiff)} min`;
  }

  const secondDiff = Math.floor(Math.abs(diffs.seconds));
  if (secondDiff >= 0) {
    return secondDiff === 0 ? 'now' : `${secondDiff} s`;
  }

  return 'now';
};

type Props = {
  hide?: boolean;
};

export const ActiveModels = ({ hide }: Props) => {
  const [hasSeenWidget, setHasSeenWidget] = useLocalStorage('seen-latest-widget', false);
  const [isOpen, setIsActiveOpen] = useLocalStorage('latest-active-models', false);

  const delayedHide = useDebounce(hide, 1000);

  useEffect(() => {
    if (hasSeenWidget) return;

    const timeout = setTimeout(() => {
      setIsActiveOpen(true);
      setHasSeenWidget(true);
      clearTimeout(timeout);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sm = useMinWidthMediaQuery('sm');

  const { data: activeModelsData } = useLatestUsedModelsInfiniteQuery(
    { limit: 4 },
    { refetchInterval: 7000 },
  );

  // const { data: activeModelsData } = useBeingUsedModelsQuery({
  //   notifyOnChangeProps: 'all',
  //   refetchInterval: 5000,
  //   refetchOnWindowFocus: true,
  // });

  const activeModels = activeModelsData?.pages.flat() || [];

  const isActiveOpen = !delayedHide && isOpen;

  return (
    <>
      <AnimatePresence>
        {isActiveOpen && (
          <motion.div
            animate={{ opacity: 1 }}
            className="pointer-events-none fixed inset-0 z-10 bg-gradient-to-br from-transparent via-transparent via-80% to-black/30"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        <motion.div
          animate={{
            backgroundColor: isActiveOpen ? theme.colors.white : theme.colors.clay[800],
            height: isActiveOpen ? heightExpanded : sizeCollapsed,
            padding: isActiveOpen ? '12px' : 0,
            width: isActiveOpen ? (sm ? '20rem' : '20rem') : sizeCollapsed,
          }}
          className="fixed bottom-20 right-4 z-10 flex flex-col overflow-hidden rounded-lg drop-shadow-mdAll sm:bottom-4"
          exit={{
            backgroundColor: theme.colors.clay[800],
            height: !isActiveOpen ? sizeCollapsed : heightExpanded,
            padding: isActiveOpen ? '12px' : 0,
            width: isActiveOpen ? (sm ? '20rem' : '20rem') : sizeCollapsed,
          }}
          initial={{
            backgroundColor: isActiveOpen ? theme.colors.white : theme.colors.clay[800],
            height: isActiveOpen ? heightExpanded : sizeCollapsed,
            padding: isActiveOpen ? '12px' : 0,
            width: isActiveOpen ? (sm ? '20rem' : '20rem') : sizeCollapsed,
          }}
          onClick={() => !isActiveOpen && setIsActiveOpen((prev) => !prev)}
          transition={{ duration: 0.2 }}
        >
          <div className={twMerge('mb-3 flex w-full items-start justify-between')}>
            <div className="flex items-start gap-2">
              {isActiveOpen ? (
                <motion.div
                  animate={{ opacity: 1, transition: { delay: 0.2 } }}
                  className="flex size-7 min-h-7 items-center justify-center rounded-lg bg-yellow-500 stroke-white p-1"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                >
                  <Icon className="text-transparent" name="flame" />
                </motion.div>
              ) : (
                <div className="flex size-12 cursor-pointer items-center justify-center rounded-lg bg-clay-800 stroke-yellow-500 drop-shadow-mdAll">
                  <Icon className="text-transparent" name="flame" />
                </div>
              )}
              <AnimatePresence>
                {isActiveOpen && (
                  <motion.h1
                    animate={{ opacity: 1 }}
                    className="flex select-none flex-col whitespace-nowrap text-lg font-normal"
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    Being used right now
                    <Button
                      as={Link}
                      className="flex h-6 w-fit cursor-pointer items-center gap-1 rounded-md pl-2 pr-1 text-xs/none font-normal text-clay-380 hover:text-clay-500"
                      color="secondary"
                      size="extra-small"
                      target="_blank"
                      to="https://explorer-test.nesa.ai/nesa-testnet-3/transactions"
                      variant="filled-light"
                    >
                      See on explorer
                      <Icon className="size-3.5" name="arrowUpRight" />
                    </Button>
                  </motion.h1>
                )}
              </AnimatePresence>
            </div>

            {isActiveOpen && (
              <div>
                <Icon
                  className="cursor-pointer text-clay-300 transition-colors hover:text-clay-500"
                  name="close"
                  onClick={() => setIsActiveOpen((prev) => !prev)}
                />
              </div>
            )}
          </div>

          <AnimatePresence>
            <motion.div
              animate={{ opacity: isActiveOpen ? 1 : 0 }}
              className="relative flex h-96 w-full flex-col gap-2 overflow-y-hidden " //sm:w-80
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <AnimatePresence>
                {activeModels.map((model, idx) => {
                  const timePassed = getTimePast(model.timestamp);

                  return (
                    <motion.div
                      animate={{ opacity: 1, top: `${5 * idx}rem`, translateX: 0 }}
                      className="absolute h-fit w-full cursor-pointer select-none overflow-hidden rounded-lg border border-clay-20 bg-white transition-colors hover:border-clay-40"
                      exit={{ opacity: 0, position: 'absolute', translateX: '100%' }}
                      initial={{ opacity: 0, top: `${5 * idx}rem`, translateX: '-100%' }}
                      key={model._id + model.txhash}
                      layout
                      transition={{ delay: 0.1 * (activeModels.length - idx), duration: 0.5 }}
                    >
                      <Link className="flex max-w-full gap-3 p-3" to={`/models/${model._id}`}>
                        <img className="size-12 shrink-0 overflow-hidden rounded-lg" src={model.image} />

                        <div className="flex w-full flex-col overflow-hidden pt-1">
                          <div className="truncate text-base/none font-semibold">
                            {getSplittedModelName(model.name).name}
                          </div>

                          <div className="mt-auto flex w-full items-center justify-between gap-1.5 overflow-hidden">
                            <div className="truncate rounded-md border border-clay-20 bg-white px-2 py-1 text-[0.66rem]/none font-normal uppercase text-clay-300">
                              {getCategoryLabel(model.type)}
                            </div>

                            <span className="min-w-fit text-xs font-normal text-clay-700">
                              {timePassed} {timePassed === 'now' ? '' : 'ago'}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </motion.div>
          </AnimatePresence>

          {/* {isActiveOpen && <TrendingSection />} */}
        </motion.div>
      </AnimatePresence>
    </>
  );
};
