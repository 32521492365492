import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { GetModelFilesParams } from './types';

import { modelsKeys } from './queryKeys';

type Response = {
  files: { name: string; size: number; timestamp: string }[];
  pagination: {
    nextQuery: string;
  };
};
export const useGetModelFilesQuery = (
  params: GetModelFilesParams,
  options: UseQueryOptions<Response> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Response>(`/models-storage/structure/read`, {
        params,
        signal,
      });

      return data;
    },
    queryKey: modelsKeys.modelFiles(params),
    ...options,
  });
};
