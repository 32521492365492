import type { PropsWithChildren } from 'react';

import * as RadixPopover from '@radix-ui/react-popover';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

export const Popover = ({ children, ...props }: PropsWithChildren<RadixPopover.PopoverProps>) => {
  return <RadixPopover.Root {...props}>{children}</RadixPopover.Root>;
};

const Trigger = ({ children, ...props }: PropsWithChildren<RadixPopover.PopoverTriggerProps>) => {
  return <RadixPopover.Trigger {...props}>{children}</RadixPopover.Trigger>;
};

const Content = ({ children, className, ...props }: PropsWithChildren<RadixPopover.PopoverContentProps>) => {
  return (
    <RadixPopover.Portal>
      <RadixPopover.Content
        className={twMerge(
          'z-50 rounded-2xl bg-white px-4 py-2 shadow-2xl outline-none',
          'max-h-[var(--radix-popover-content-available-height)] overflow-y-auto',
          className,
        )}
        collisionPadding={6}
        sideOffset={4}
        {...props}
      >
        <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
          {children}
        </motion.div>
      </RadixPopover.Content>
    </RadixPopover.Portal>
  );
};

Popover.Trigger = Trigger;
Popover.Content = Content;
