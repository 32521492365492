import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { ContentLoader } from '../ContentLoader';

type Props = {
  src: string;
} & ClassName;

export const Image = ({ className, src }: Props) => {
  const [imageStatus, setImageStatus] = useState<string>('loading');
  return (
    <div className={twMerge('relative size-full overflow-hidden rounded-lg', className)}>
      {src && (
        <img
          className="size-full overflow-hidden object-cover object-center"
          onError={() => setImageStatus('error')}
          onLoad={() => setImageStatus('success')}
          src={src}
        />
      )}
      {imageStatus === 'loading' && (
        <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
          <rect height="100%" rx="5" ry="5" width="100%" x="0" y="0" />
        </ContentLoader>
      )}
    </div>
  );
};
