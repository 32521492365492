export const languages = [
  { label: 'Afrikaans', value: 'afrikaans' },
  { label: 'Arabic', value: 'arabic' },
  { label: 'Azerbaijani', value: 'azerbaijani' },
  { label: 'Belarusian', value: 'belarusian' },
  { label: 'Bosnian', value: 'bosnian' },
  { label: 'Bulgarian', value: 'bulgarian' },
  { label: 'Catalan', value: 'catalan' },
  { label: 'Chinese', value: 'chinese' },
  { label: 'Croatian', value: 'croatian' },
  { label: 'Czech', value: 'czech' },
  { label: 'Danish', value: 'danish' },
  { label: 'Dutch', value: 'dutch' },
  { label: 'English', value: 'english' },
  { label: 'Estonian', value: 'estonian' },
  { label: 'Finnish', value: 'finnish' },
  { label: 'French', value: 'french' },
  { label: 'Galician', value: 'galician' },
  { label: 'German', value: 'german' },
  { label: 'Greek', value: 'greek' },
  { label: 'Hebrew', value: 'hebrew' },
  { label: 'Hindi', value: 'hindi' },
  { label: 'Hungarian', value: 'hungarian' },
  { label: 'Icelandic', value: 'icelandic' },
  { label: 'Indonesian', value: 'indonesian' },
  { label: 'Italian', value: 'italian' },
  { label: 'Japanese', value: 'japanese' },
  { label: 'Kannada', value: 'kannada' },
  { label: 'Kazakh', value: 'kazakh' },
  { label: 'Korean', value: 'korean' },
  { label: 'Latvian', value: 'latvian' },
  { label: 'Lithuanian', value: 'lithuanian' },
  { label: 'Macedonian', value: 'macedonian' },
  { label: 'Malay', value: 'malay' },
  { label: 'Marathi', value: 'marathi' },
  { label: 'Maori', value: 'maori' },
  { label: 'Nepali', value: 'nepali' },
  { label: 'Norwegian', value: 'norwegian' },
  { label: 'Persian', value: 'persian' },
  { label: 'Polish', value: 'polish' },
  { label: 'Portuguese', value: 'portuguese' },
  { label: 'Romanian', value: 'romanian' },
  { label: 'Russian', value: 'russian' },
  { label: 'Serbian', value: 'serbian' },
  { label: 'Slovak', value: 'slovak' },
  { label: 'Slovenian', value: 'slovenian' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'Swahili', value: 'swahili' },
  { label: 'Swedish', value: 'swedish' },
  { label: 'Tagalog', value: 'tagalog' },
  { label: 'Tamil', value: 'tamil' },
  { label: 'Thai', value: 'thai' },
  { label: 'Turkish', value: 'turkish' },
  { label: 'Ukrainian', value: 'ukrainian' },
  { label: 'Urdu', value: 'urdu' },
  { label: 'Vietnamese', value: 'vietnamese' },
  { label: 'Welsh', value: 'welsh' },
] as const;
