import { useState } from 'react';

import { useGetFileByCidQuery } from 'shared/api/ipfs/useGetFileByCidQuery';
import { getStringifiedJSONData } from 'shared/helpers/getStringifiedJSONData';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

import { IPFS_REGEX } from '../QueryHistoryCard';
import { ImageData } from './ImageData';
import { VideoData } from './VideoData';

type Props = {
  response: string | undefined;
};

export const Response = ({ response }: Props) => {
  const [shouldLoadResponse, setShouldLoadResponse] = useState(false);

  const isIpfsResponse = !!response && IPFS_REGEX.test(response);

  const { data: resFileData, isLoading: isLoadingResponseFile } = useGetFileByCidQuery(response!, {
    enabled: isIpfsResponse && shouldLoadResponse,
  });

  const isImageResponse = resFileData?.type === 'image';
  const isVideoResponse = resFileData?.type === 'video';

  if (!isIpfsResponse) {
    return response || 'No response was returned for the request.';
  }

  if (!shouldLoadResponse) {
    return (
      <div
        className="cursor-pointer font-semibold text-primary-800 transition-colors hover:text-primary-900"
        onClick={() => setShouldLoadResponse(true)}
      >
        Load media data
      </div>
    );
  }

  if (isLoadingResponseFile) {
    return (
      <div className="relative h-5 w-60">
        <StretchedSkeleton enable />
      </div>
    );
  }

  if (isImageResponse) {
    return <ImageData src={resFileData?.data} />;
  }

  if (isVideoResponse) {
    return <VideoData src={resFileData?.data} />;
  }

  return resFileData?.data && getStringifiedJSONData(resFileData?.data);
};
