import { useNavigate } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';

import { useGetModelListByOrgQuery } from 'shared/api/models/useGetModelListByOrgQuery';
import { getPrice } from 'shared/helpers/getPrice';
import { Accordion } from 'shared/ui/Accordion';
import { Card } from 'shared/ui/Card';
import { GalleryCardBottomInfo } from 'shared/ui/GalleryCard';
import { Icon } from 'shared/ui/Icon';
import { Image } from 'shared/ui/Image';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  isOpen?: boolean;
  modelIdToExclude?: string;
  onOpenChange?: (isOpen: boolean) => void;
  orgName: string | undefined;
};

export const ModelsFromOrg = ({ isOpen, modelIdToExclude, onOpenChange, orgName = '' }: Props) => {
  const navigate = useNavigate();
  const { data, isPending } = useGetModelListByOrgQuery(
    { exclude: modelIdToExclude, includeAvgScore: true, org: orgName },
    { enabled: !!orgName },
  );

  const list = data?.pages.flat();

  if (isPending || (!isPending && list?.length === 0)) {
    return;
  }

  return (
    <Card animate={{ opacity: 1 }} className="!pb-1" exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
      <Accordion isExpanded={isOpen} onChange={onOpenChange}>
        <Accordion.Trigger className="flex items-center gap-2">
          <Icon className="size-5 text-clay-400" name="userCircle" />
          Also from {orgName}
        </Accordion.Trigger>

        <Accordion.Content className="relative">
          <AnimatePresence>
            {isPending && (
              <motion.div
                animate={{ opacity: 1 }}
                className="absolute right-2 top-2"
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
              >
                <Spinner className="size-5" />
              </motion.div>
            )}
            {!isPending && (
              <motion.div className="flex flex-col gap-3 pb-4">
                {list?.length === 0 ? (
                  <div className="items-center justify-center py-6 text-center font-light text-clay-400">{`${orgName} doesn't have any other models`}</div>
                ) : (
                  list?.map((model) => {
                    return (
                      <div
                        className="flex w-full cursor-pointer items-center gap-3 rounded-xl border border-clay-20 p-2.5 transition-colors hover:border-clay-40"
                        key={model._id}
                        onClick={() => navigate(`/models/${model._id}`)}
                      >
                        <Image className="size-11" src={model.image} />
                        <div className="flex flex-1 flex-col">
                          <div className="flex items-center justify-between border-b border-clay-20 pb-1">
                            <div className="text-sm">{model.modelName}</div>

                            <div className="flex items-center gap-2">
                              <span className="mt-px text-sm font-semibold text-blue-900">
                                ${getPrice(model.pricing?.output_price)}
                              </span>
                            </div>
                          </div>

                          <GalleryCardBottomInfo
                            className="pt-1"
                            isLoading={isPending}
                            latency={model?.latency}
                            reviewAvg={model.reviewsCount}
                            reviewCount={model?.reviewsCount}
                          />

                          {/* <div className="pt-2 flex items-center justify-between"></div> */}
                        </div>
                      </div>
                    );
                  })
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </Accordion.Content>
      </Accordion>
    </Card>
  );
};
