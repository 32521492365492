import { twMerge } from 'tailwind-merge';

import type { TableColumn, TableRowExt } from '../types';

type Props<ColumnKey extends string, Row extends TableRowExt<ColumnKey>> = {
  column: TableColumn<ColumnKey, Row>;
  hovered: boolean;
  indexRow: number;
  row: Row;
};

export const Td = <ColumnKey extends string, Row extends TableRowExt<ColumnKey>>({
  column,
  hovered,
  indexRow,
  row,
}: Props<ColumnKey, Row>) => {
  return (
    <td
      className={twMerge(
        'border-l border-t border-clay-20 bg-white p-3 text-sm font-normal text-clay-900 last:border-r group-last:border-b group-last:first:rounded-bl-lg group-last:last:rounded-br-lg',
        hovered && 'group-hover:bg-clay-10',
        column.tdClassName,
      )}
    >
      {column.renderTd(row, indexRow)}
    </td>
  );
};
