import { useForm } from 'react-hook-form';

import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';

type Props = {
  isOpen: boolean;
  onOpenChange: (isOPen: boolean) => void;
  onSubmit: (apiKey: string) => void;
};

type FormValues = {
  apiKey: string;
};

export const ApiKeyModal = ({ isOpen, onOpenChange, onSubmit }: Props) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: { apiKey: '' },
    mode: 'onChange',
  });

  const onSend = (values: FormValues) => {
    onSubmit(values.apiKey);
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />

        <div className="mb-4 text-xl font-medium">Provide your API key</div>

        <div className="mb-6 flex w-full">
          <Input
            error={errors.apiKey?.message}
            maxLength={200}
            placeholder="sk-xxx...xxx"
            {...register('apiKey', {
              required: true,
              validate: (val) => (!/^sk-[a-zA-Z0-1]+/.test(val) ? 'Wrong API Key format' : true),
            })}
            errorSpacing
          />
        </div>

        <div className="flex gap-4">
          <Button color="secondary" onClick={() => onOpenChange(false)} variant="filled-light">
            Cancel
          </Button>
          <Button disabled={!isValid} onClick={handleSubmit(onSend)}>
            Submit
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
