import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { GetSessionResponse } from './types';

import { userKeys } from './queryKeys';

export const useCurrentSessionQuery = (options: UseQueryOptions<GetSessionResponse>) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<GetSessionResponse>('/auth/me/');

      return data;
    },
    queryKey: userKeys.singleUser,
    ...options,
  });
};
