import { twJoin, twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  className?: string;
  isLoading?: boolean;
  latency?: number;
  price?: number;
  reviewAvg?: number;
  reviewCount?: number;
};

export const GalleryCardBottomInfo = ({ className, isLoading, latency, reviewAvg, reviewCount }: Props) => {
  const baseClassName = 'relative mr-2 flex min-h-5 max-w-fit items-center text-xs font-normal';
  return (
    <div className={twMerge('flex flex-wrap justify-between gap-3 xs:gap-2', className)}>
      <div className="flex gap-2">
        <Tooltip content="reviews" side="top">
          <div className={twMerge(baseClassName, isLoading && 'min-w-9')}>
            <StretchedSkeleton enable={isLoading} rx={10} ry={10} />

            {!isLoading && (
              <div className="group flex items-center gap-1.5 text-clay-700">
                <Icon className="size-3 text-clay-350" name="messageSquare" />

                <span className=" text-[12px]">{reviewCount || 0}</span>
              </div>
            )}
          </div>
        </Tooltip>

        <Tooltip content="rating" side="top">
          <div className={twJoin(baseClassName, isLoading && 'min-w-24')}>
            <StretchedSkeleton enable={isLoading} rx={10} ry={10} />

            {!isLoading && (
              <div className="flex items-center gap-1.5 text-clay-700">
                <Icon className="size-3 text-clay-350 transition-colors" name="star" />
                <span className="text-[12px] text-gold-600">
                  {reviewAvg?.toFixed(2) || 0}
                  <span className="px-1.5 text-clay-20">/</span>
                  <span className="leading-none text-clay-700">{reviewCount}</span>
                </span>
              </div>
            )}
          </div>
        </Tooltip>
      </div>

      <Tooltip content="latency" side="top">
        <div className={twJoin(baseClassName, isLoading && 'min-w-24')}>
          <StretchedSkeleton className="min-w-24" enable={isLoading} rx={10} ry={10} />

          {!isLoading && (
            <div className="group flex items-center gap-0.5 text-clay-700">
              <Icon
                className="size-3.5 stroke-clay-350 stroke-[1.2px] text-transparent transition-colors"
                name="bolt"
              />

              <span className="text-[12px]">{`< ${latency || 3}sec avg`}</span>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
