import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const PrivateTxPageLazy = lazy(() => import('./Page').then((module) => ({ default: module.Page })));

export const PrivateTx = () => (
  <Suspense fallback={<PageLoader />}>
    <PrivateTxPageLazy />
  </Suspense>
);
