import { useParams } from 'react-router-dom';

import { LLMQuery } from 'features/LLMQuery';
import { ModelQuery } from 'features/ModelQuery';
import { useGetModelByIdQuery } from 'shared/api/models/useGetModelByIdQuery';

import { ModelNotFound } from './ui/ModelNotFound';
import { PageSkeleton } from './ui/PageSkeleton';

export const Page = () => {
  const { id: modelId } = useParams<{ id: string }>();

  const { data: model, isPending } = useGetModelByIdQuery(modelId!, { enabled: !!modelId });
  const isLLM = model?.type === 'text-generation';

  if (isPending) {
    return <PageSkeleton />;
  }

  if (!model && !isPending) {
    return <ModelNotFound />;
  }

  return isLLM ? <LLMQuery model={model} /> : <ModelQuery model={model} />;
};
