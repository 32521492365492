import type { HTMLMotionProps } from 'framer-motion';

import { type PropsWithChildren, forwardRef } from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

export const Card = forwardRef<HTMLDivElement, PropsWithChildren<HTMLMotionProps<'div'>>>(
  ({ children, className, ...props }, ref) => {
    return (
      <motion.div
        className={twMerge('w-full rounded-lg border border-clay-20 bg-white p-3 xs:p-3 xs:px-4', className)}
        ref={ref}
        {...props}
      >
        {children}
      </motion.div>
    );
  },
);
