import { Link } from 'react-router-dom';

import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { QueryHistoryListItem } from 'shared/api/queryHistory/types';
import type { ClassName } from 'shared/types';

import { getCategoryLabel } from 'features/CategoryModelsDropdown';
import { useGetFileByCidQuery } from 'shared/api/ipfs/useGetFileByCidQuery';
import { getPrice } from 'shared/helpers/getPrice';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';

import lineSrc from './assets/line.svg';
import { ImageData } from './ui/ImageData';
import { Response } from './ui/Response';

type Props = {
  data: QueryHistoryListItem;
  showLinkToModel?: boolean;
  withPrivateButton?: boolean;
} & ClassName;

export const IPFS_REGEX =
  /^(Qm[1-9A-HJ-NP-Za-km-z]{44,}|b[A-Za-z2-7]{58,}|B[A-Z2-7]{58,}|z[1-9A-HJ-NP-Za-km-z]{48,}|F[0-9A-F]{50,})$/;

export const QueryHistoryCard = ({
  className,
  data,
  showLinkToModel = true,
  withPrivateButton = true,
}: Props) => {
  const { _id, date, executionTime, model, private: isPrivate, question, result } = data;

  const isIpfsRequest = !!question && IPFS_REGEX.test(question);

  const { data: reqFileData, isLoading: isLoadingRequestFile } = useGetFileByCidQuery(question!, {
    enabled: isIpfsRequest,
  });

  const isImageRequest = reqFileData?.type === 'image';

  return (
    <Card className={twMerge('flex flex-col !p-6', className)}>
      <header className="mb-4 flex w-full flex-wrap items-center justify-between gap-x-8 gap-y-3 border-b border-clay-20 pb-3">
        <div className="flex flex-1">
          <div className="line-clamp-1 flex items-center gap-2 whitespace-nowrap rounded-full border border-clay-20 px-2.5 py-2 text-clay-900">
            <Icon className="size-4" name="box" />
            <span className="text-sm font-medium">{model?.name || '--'}</span>
            <div className="rounded-full bg-clay-20 px-2 py-1.5 text-[0.625rem] font-semibold uppercase leading-none text-clay-900">
              {getCategoryLabel(model?.type)}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1.5">
          <Icon className="size-4 text-clay-350" name="calendar" />

          <span className="mt-px text-sm/none  text-clay-500">
            {date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED) : '-'}
          </span>
        </div>
        <div className="flex items-center gap-8 text-sm">
          <div className="flex items-center gap-1.5 text-clay-700">
            <Icon className="size-4 stroke-clay-350 text-transparent" name="bolt" />
            {executionTime?.toFixed(2) || '--'}s
          </div>

          {model && (
            <div className="inline-flex items-center gap-1 font-semibold leading-none text-blue-900">
              <Icon className="size-4 p-0 pb-0.5" name="dollar" />
              <span>
                {(model?.pricing?.output_price && getPrice(model?.pricing?.output_price)) ||
                  model?.price?.toFixed(3) ||
                  0.001}
              </span>
            </div>
          )}

          {model && showLinkToModel && (
            <Link
              className="group flex cursor-pointer items-center gap-2 text-primary-800"
              // TODO replace modelId => model
              to={`/models/${model._id}`}
            >
              View Query
              <Icon
                className="size-3.5 rounded-md bg-primary-30 p-1 text-primary-800 transition-colors group-hover:bg-primary-100"
                name="arrowUpRight"
              />
            </Link>
          )}
        </div>
      </header>

      <div className="flex flex-col">
        <div className="mb-2 text-sm/none font-normal text-clay-500">Request</div>

        <div className="line-clamp-2 text-lg font-semibold">
          {isIpfsRequest ? (
            isImageRequest ? (
              <ImageData isLoading={isLoadingRequestFile} src={reqFileData.data} />
            ) : (
              'Media request'
            )
          ) : (
            question
          )}
        </div>

        <div className="flex gap-1.5">
          {/* connector */}
          <img className="h-5 w-3" src={lineSrc} />
          <div className="mt-2 flex flex-1 flex-col font-light">
            <div className="mb-2 text-sm text-primary-800">Response</div>
            <div className="mb-2 line-clamp-2 break-all text-sm text-clay-500">
              <Response response={result} />
            </div>
          </div>
          {isPrivate && withPrivateButton && (
            <div className="flex items-end">
              <Link
                className="flex cursor-pointer select-none items-center justify-center gap-0.5 rounded-md bg-clay-800 py-1 pl-1 pr-0.5 text-xs font-light text-white transition-colors hover:bg-clay-900"
                to={`/private-tx/${_id}`}
              >
                <Icon className="size-3 text-tusk-200" name="lock" />
                Private
                <Icon className="size-4 text-white" name="arrowUpRight" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
