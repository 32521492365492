import type { WillChangeEvent } from '@egjs/react-flicking';

import { useRef, useState } from 'react';

import Flicking from '@egjs/react-flicking';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { useGetFileByCidListQuery } from 'shared/api/ipfs/useGetFileByCidListQuery';
import { Icon } from 'shared/ui/Icon';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

import { IpfsImage } from './IpfsImage';

type Props = {
  cidList: string[];
};
export const ImageCarousel = ({ cidList }: Props) => {
  const ref = useRef<Flicking>(null);

  const { data = [], isLoading } = useGetFileByCidListQuery(cidList, { enabled: cidList.length > 0 });

  console.log('data', data);

  const [activeIndex, setActiveIndex] = useState(0);

  const images = data.filter((data) => data?.type === 'image');
  const datas = data.filter((data) => data?.type === 'data');

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="grid grid-cols-1 gap-3 overflow-hidden"
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
    >
      {isLoading ? (
        <>
          <motion.div
            animate={{ opacity: 1 }}
            className="flex overflow-x-scroll"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <div className="relative mr-2 flex h-56 min-w-[340px] flex-col gap-1 overflow-hidden rounded-xl">
              <StretchedSkeleton enable />
            </div>
            <div className="relative mr-2 flex h-56 min-w-[340px] flex-col gap-1 overflow-hidden rounded-xl">
              <StretchedSkeleton enable />
            </div>
            <div className="relative mr-2 flex h-56 min-w-[340px] flex-col gap-1 overflow-hidden rounded-xl">
              <StretchedSkeleton enable />
            </div>
          </motion.div>

          <motion.div
            animate={{ opacity: 1 }}
            className="flex gap-2 pb-3"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <div className="relative size-5 overflow-hidden rounded-md">
              <StretchedSkeleton enable />
            </div>
            <div className="relative size-5 overflow-hidden rounded-md">
              <StretchedSkeleton enable />
            </div>
          </motion.div>
        </>
      ) : (
        <>
          <Flicking
            align="prev"
            bound
            cameraClass="flex"
            cameraTag="div"
            circular={false}
            defaultIndex={0}
            horizontal
            noPanelStyleOverride
            onWillChange={(e: WillChangeEvent) => {
              setActiveIndex(e.index);
            }}
            ref={ref}
            renderOnSameKey={false}
            viewportTag="div"
          >
            {images.map((image, idx) => {
              return (
                <div className="flex max-h-56 min-w-[340px] flex-col gap-1 rounded-lg pr-2" key={idx}>
                  <IpfsImage
                    className="h-56 max-h-56"
                    data={datas[idx]?.data || ''}
                    imageSrc={image?.data || ''}
                  />
                </div>
              );
            })}
          </Flicking>

          <div className="flex items-center gap-2 pb-3">
            <Icon
              className={twMerge(
                'size-4 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
                (activeIndex === undefined || activeIndex <= 0) && 'cursor-not-allowed opacity-50',
              )}
              name="arrowLeftLong"
              onClick={() => {
                if (activeIndex > 0) {
                  ref.current?.prev();
                }
              }}
            />
            <Icon
              className={twMerge(
                'size-4 rotate-180 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
                activeIndex >= images.length - 1 && 'cursor-not-allowed opacity-50',
              )}
              name="arrowLeftLong"
              onClick={() => {
                if (activeIndex < images.length - 1) {
                  ref.current?.next();
                }
              }}
            />
          </div>
        </>
      )}
    </motion.div>
  );
};
