export const WARM_MODELS = [
  'meta-llama/Llama-3.2-1B-Instruct',
  'meta-llama/Llama-3.2-3B-Instruct',
  'Orenguteng/Llama-3-8B-Lexi-Uncensored',
  'gpt-4o',
  'claude-3-opus-20240229',
  'suno/bark-small',
  'MedPaLM-2',
  'nesa/LazyMix-Uncensored',
  'black-forest-labs/FLUX.1-schnell',
  'openai/whisper-small',
  'sd-community/sdxl-flash',
  'dall-e-3',
  'gpt-3.5-turbo-0125',
  'stabilityai/sdxl-turbo',
  'dall-e-2',
  'distilbert/distilbert-base-uncased-finetuned-sst-2-english',
  'openai/whisper-base',
  'facebook/detr-resnet-101',
  '4xFaceUpSharpDAT_fp32_opset17',
  'google/pegasus-xsum',
  'facebook/mms-tts-eng',
  'zbmacro/Realistic-Vision-V6.0-B1_4-steps',
  'XGBoost',
  'distilbert/distilbert-base-cased-distilled-squad',
  'fxmarty/tiny-doc-qa-vision-encoder-decoder',
  'jonathandinu/face-parsing',
  'google/ddpm-celebahq-256',
  'facebook/hubert-large-ls960-ft',
  'anton-l/wav2vec2-random-tiny-classifier',
  'Intel/dpt-hybrid-midas',
  'ByteDance/Hyper-SD',
  'vit_huge_patch14_clip_quickgelu_224.dfn5b',
  'google/vit-base-patch16-224',
  'dslim/bert-base-NER-uncased',
  'sentence-transformers/all-mpnet-base-v2',
  'cardiffnlp/twitter-roberta-base-sentiment',
  'facebook/bart-large-mnli',
  'google-t5/t5-small',
  'Yntec/mistoonAnime2_4-steps',
  'microsoft/table-transformer-detection',
  'hustvl/yolos-tiny',
  'sayeed99/segformer-b3-fashion',
  'distilbert/distilbert-base-uncased-distilled-squad',
  'Nobilis/layoutlmv2-base-uncased_finetuned_docvqa',
  'LiheYoung/depth-anything-large-hf',
  'inception_next_base.sail_in1k_384',
  'trpakov/vit-face-expression',
  'dslim/bert-large-NER',
  'BAAI/bge-large-en-v1.5',
  'human-centered-summarization/financial-summarization-pegasus',
  'textattack/bert-base-uncased-snli',
];
