import { memo, useMemo, useState } from 'react';

import { getSizeCategories } from 'shared/api/datasets/useGetDatasetsQuery';
import { useGetDatasetsTagsByType } from 'shared/api/datasets/useGetDatasetsTagsByType';
import { useGetMatchingDatasetsTags } from 'shared/api/datasets/useGetMatchingDatasetsTags';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Spinner } from 'shared/ui/Spinner';

import type { FiltersProps } from './MainFilters';

import { Chip } from './Chip';

type Props = {
  filters: FiltersProps['filters'];
  onChange: FiltersProps['onChange'];
};

export const LanguageFilters = memo(({ filters, onChange }: Props) => {
  const [search, setSearch] = useState('');
  const { data: availableLanguages, isPending } = useGetMatchingDatasetsTags({
    format:
      filters.format && filters.format?.length > 0
        ? filters.format.map((val) => `format:${val}`).join(',')
        : undefined,
    library: filters.library?.join(','),
    license: filters.license?.join(','),
    modality:
      filters.modality && filters.modality?.length > 0
        ? filters.modality.map((val) => `modality:${val}`).join(',')
        : undefined,
    other: filters.other?.join(','),
    size_categories: filters.size?.[0] && getSizeCategories(filters.size[0]),

    sort: filters.sort,
    task_categories: filters.tasks?.join(',') || undefined,
    type: 'language',
  });

  const { data: defaultLanguages, isPending: isDefaultPending } = useGetDatasetsTagsByType({
    type: 'language',
  });

  const available = useMemo(() => {
    return defaultLanguages?.language?.filter((item) => availableLanguages?.matchingTags.includes(item.id));
  }, [defaultLanguages, availableLanguages]);

  const hidden = useMemo(() => {
    return defaultLanguages?.language?.filter((item) => !availableLanguages?.matchingTags.includes(item.id));
  }, [defaultLanguages, availableLanguages]);

  const filtered = useMemo(
    () => available?.filter((item) => item.label.toLowerCase().includes(search.toLowerCase())),
    [available, search],
  );

  return (
    <div className="flex flex-col gap-2">
      {isPending || isDefaultPending ? (
        <div className="flex flex-col items-center justify-center py-10">
          <Spinner className="size-4" />
        </div>
      ) : (
        <>
          <div className="mb-6 flex flex-col">
            <Input
              className="w-full"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Filter Languages"
              startSlot={<Icon className="size-3 text-clay-600" name="search" />}
              value={search}
            />
          </div>
          <div className="flex flex-wrap items-center gap-2">
            {filtered?.slice(0, 100)?.map((item) => {
              const isSelected = filters.language?.includes(item.id);
              return (
                <Chip
                  isActive={isSelected}
                  key={item.id}
                  onClick={() =>
                    onChange({
                      language: isSelected
                        ? filters.language?.filter((val) => val !== item.id)
                        : [...(filters.language || []), item.id],
                    })
                  }
                >
                  {item.label}
                </Chip>
              );
            })}

            {!!filtered?.length && filtered.length > 100 && (
              <Chip isDisabled key="all">
                + {filtered?.length - 100}
              </Chip>
            )}
          </div>

          {!!hidden?.length && (
            <div className="mt-2 text-left text-xs font-medium text-clay-300">Not supported:</div>
          )}
          <div className="flex flex-wrap items-center gap-2">
            {hidden?.slice(0, 100)?.map((item) => {
              return (
                <Chip isDisabled key={item.id}>
                  {item.label}
                </Chip>
              );
            })}

            {!!hidden?.length && hidden.length > 100 && (
              <Chip isDisabled key="all">
                + {hidden?.length - 100}
              </Chip>
            )}
          </div>
        </>
      )}
    </div>
  );
});
