import { AnimatePresence } from 'framer-motion';

import { Accordion } from 'shared/ui/Accordion';
import { Icon } from 'shared/ui/Icon';

export type MinerRate = {
  account: string;
  rate: number;
};

type Props = {
  executionTime?: number;
  isOpen?: boolean;
  onOpenChange?: (val: boolean) => void;
  pricePerToken: number | string;
  rates: MinerRate[];
  totalPayment: number;
  transactionHash?: string;
};

const labelClassName = 'text-clay-500 text-base';

export const Payments = ({
  executionTime,
  isOpen,
  onOpenChange,
  pricePerToken,
  rates,
  transactionHash,
}: Props) => {
  return (
    <AnimatePresence>
      <Accordion isExpanded={isOpen} onChange={onOpenChange}>
        <Accordion.Trigger className="flex gap-2">
          <Icon className="size-5 text-clay-400" name="globeDigital" />
          Transactions
        </Accordion.Trigger>
        <Accordion.Content animate={{ maxHeight: undefined }} className="grid-cols-1 pb-3">
          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
            <div>
              <div className={labelClassName}>TxID</div>

              <div
                className="group flex items-center gap-0.5  transition-colors hover:text-primary-900"
                // onClick={() =>
                //   transactionHash &&
                //   window.open(
                //     `https://explorer-test.nesa.ai/nesa-testnet-3/transactions/${transactionHash}`,
                //     '_blank',
                //   )
                // }
              >
                <div className="w-full truncate">{transactionHash || '-'}</div>
                {transactionHash && (
                  <Icon
                    className="text-clay-500 transition-colors group-hover:text-primary-900 hover:text-primary-900"
                    name="arrowUpRightLong"
                  />
                )}
              </div>
            </div>
            <div className="flex w-full flex-col">
              <div className={labelClassName}>Inference nodes</div>
              {rates.map((item) => {
                return (
                  <div className="mt-1 flex flex-1 grow items-center" key={item.account}>
                    <div className="mr-1 truncate">{item.account}</div>
                    <span className="whitespace-nowrap">
                      {' '}
                      - <span className="font-medium">{item.rate}</span>
                    </span>
                    <div className="ml-2 whitespace-nowrap rounded border border-primary-900 bg-primary-50 p-1 text-sm/none text-primary-1000">
                      Equipped with TEE
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <div className={labelClassName}>Execution time</div>
              {executionTime ? Math.abs(executionTime) : ' - '}s
            </div>
            <div className="flex flex-col gap-0.5">
              <div className={labelClassName}>Price per token</div>
              <span>${pricePerToken}</span>
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
    </AnimatePresence>
  );
};
