import type { FormEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useIntersectionObserver } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useAccount } from 'graz';
import { twMerge } from 'tailwind-merge';

import type { LLMQueryParams } from 'features/LLMQuery/ui/LLMParams';
import type { Model } from 'shared/api/models/types';
import type { ClassName } from 'shared/types';

import { useUser } from 'app/stores/user';
import { LimitReachedModal } from 'features/LimitReachedModal';
import { Message } from 'pages/QueryPage/ui/Message';
import { useGetLlmSessionMessagesQuery } from 'shared/api/messages/useGetLlmSessionMessagesQuery';
import { useLimitReached } from 'shared/hooks/useLimitReached';
import { Spinner } from 'shared/ui/Spinner';

import chatBubbleSrc from '../assets/message-square.svg';
import { ChatTextArea } from './ChatTextArea';

type Props = {
  isRequestingSession?: boolean;
  isTyping?: boolean;
  model: Model;
  onMessageSend: (message: string, apiKey?: string) => void;
  onQueryParamsChange: (params: Partial<LLMQueryParams>) => void;
  queryParams: LLMQueryParams;
  sessionId: string | undefined;
} & ClassName;

type FormValues = {
  message: string;
};

export const FREE_MSG_LIMIT = 0;

export const LLMChatContent = ({
  className,
  isRequestingSession,
  isTyping,
  model,
  onMessageSend,
  onQueryParamsChange,
  queryParams,
  sessionId = 'default',
}: Props) => {
  const { user } = useUser();
  const { data, fetchNextPage, hasNextPage, isPending } = useGetLlmSessionMessagesQuery(
    { modelName: model.name, sessionId: sessionId!, userId: user?._id || '' },
    { enabled: !!user?._id && !!model.name, staleTime: Infinity },
  );

  const { isConnected } = useAccount();

  const { isLimitReached } = useLimitReached(model._id);

  const showLimitPopup = isLimitReached && !isConnected;

  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();
  const [isLimitOpen, setIsLimitOpen] = useState(false);

  const {
    formState: { isValid },
    getValues,
    register,
    resetField,
  } = useForm<FormValues>({
    defaultValues: { message: '' },
    mode: 'onChange',
  });

  const messageHistory = useMemo(() => data?.pages?.flat() || [], [data?.pages]);

  const hasMessages = messageHistory.length > 0;

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  const handleSubmit = async (
    e: FormEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLTextAreaElement> | undefined,
  ) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (showLimitPopup) {
      setIsLimitOpen(true);
      return;
    }

    if (isTyping) return;

    const { message } = getValues();

    onMessageSend(message);

    resetField('message');
  };

  return (
    <div className={twMerge('flex h-full flex-col', className)}>
      <div className="flex flex-1 flex-col-reverse overflow-scroll p-4 pb-0 scrollbar-none">
        {hasMessages &&
          messageHistory.map((message, i) => {
            const isUser = message.history.role === 'user';
            return (
              <AnimatePresence key={message._id}>
                {i === messageHistory.length - 1 && <div ref={ref} />}
                <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} key={message._id}>
                  <Message
                    className={twMerge('pb-2 pt-5', i === messageHistory.length - 1 && 'border-none')}
                    id={message._id}
                    isUser={isUser}
                    text={message.history.content}
                    title={isUser ? user?.first_name || 'You' : model.name}
                  />
                </motion.div>
              </AnimatePresence>
            );
          })}

        {isPending ? (
          <div className="flex h-full items-center justify-center text-center text-clay-300">
            <Spinner className="size-6" />
          </div>
        ) : (
          !hasMessages && (
            <div className="flex h-full flex-col items-center justify-center gap-3 text-center font-light text-clay-500">
              <img className="size-11" src={chatBubbleSrc} />
              No Messages
            </div>
          )
        )}
      </div>

      <form className="mt-auto flex w-full flex-col px-4 pb-4">
        <div className="relative flex flex-col pt-5">
          <AnimatePresence>
            {isTyping && (
              <motion.div
                animate={{ opacity: 1 }}
                className="absolute -top-0 right-0 flex items-center justify-end gap-1.5 text-right text-xs text-clay-400 lg:text-sm/4"
                initial={{ opacity: 0 }}
                transition={{ delay: 1 }}
              >
                <Spinner className="size-3 md:size-4" />
                {model.modelName} typing...
              </motion.div>
            )}
          </AnimatePresence>
          <ChatTextArea
            isError={showLimitPopup}
            isLoading={isTyping}
            isSubmitDisabled={!showLimitPopup && (!isValid || isTyping)}
            model={model}
            onParamsChange={onQueryParamsChange}
            onSubmit={handleSubmit}
            params={queryParams}
            placeholder="Write Message here"
            tooltipText={isRequestingSession ? 'Connecting to a model' : ''}
            {...register('message', { minLength: 1, required: true })}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey == false) {
                handleSubmit(e);
              }
            }}
          />
        </div>
      </form>

      <LimitReachedModal isOpen={isLimitOpen} onOpenChange={setIsLimitOpen} />
    </div>
  );
};
