import type { ComponentProps } from 'react';
import { useId } from 'react';

import { twMerge } from 'tailwind-merge';

import { theme } from 'app/theme';

type StarProps = {
  color?: string;
  percent: number;
} & ComponentProps<'svg'>;

export const Star = ({
  color = theme.colors.yellow[500],
  percent,
  ...props
}: ComponentProps<'svg'> & StarProps) => {
  const id = useId();
  return (
    <svg height={22} width={22} {...props} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={id}>
          <stop offset={`${percent}%`} stopColor={color} />
          <stop offset={`${percent}%`} stopColor="transparent" />
        </linearGradient>
      </defs>

      <path
        d="M6.99935 1.16666L8.80185 4.81832L12.8327 5.40749L9.91602 8.24832L10.6043 12.2617L6.99935 10.3658L3.39435 12.2617L4.08268 8.24832L1.16602 5.40749L5.19685 4.81832L6.99935 1.16666Z"
        fill={`url(#${id})`}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </svg>
  );
};

type StarRatingProps = {
  className?: string;
  containerClassName?: string;
  rating: number;
  starColor?: string;
  starsCount?: number;
  svgProps?: ComponentProps<'svg'>;
};

export const StarRating = ({
  className,
  containerClassName,
  rating,
  starColor,
  starsCount = 5,
  svgProps,
}: StarRatingProps) => {
  return (
    <div className={twMerge('flex', className)}>
      {new Array(starsCount).fill(null).map((_, i) => {
        let percent = 0;

        const starCount = i + 1;
        if (starCount <= rating) percent = 100;
        if (starCount > rating && starCount - rating < 1) {
          percent = Math.round((rating % 1) * 100);
        }
        return (
          <div className={twMerge('flex size-6 items-center justify-center', containerClassName)} key={i}>
            <Star {...svgProps} color={starColor} percent={percent} />
          </div>
        );
      })}
    </div>
  );
};
