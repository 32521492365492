import type { FormEventHandler, MouseEventHandler } from 'react';
import { useState } from 'react';

import { AxiosError } from 'axios';
import { twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';
import type { Review } from 'shared/api/reviews/types';

import { queryClient } from 'app/App';
import { useUser } from 'app/stores/user';
import { reviewsKeys } from 'shared/api/reviews/queryKeys';
import { useCreateReviewMutation } from 'shared/api/reviews/useCreateReviewMutation';
import { useDeleteReviewMutation } from 'shared/api/reviews/useDeleteReviewMutation';
import { useGetUserReviewByModelQuery } from 'shared/api/reviews/useGetUserReviewByModelQuery';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';
import { Spinner } from 'shared/ui/Spinner';
import { TextArea } from 'shared/ui/TextArea';
import { toaster } from 'shared/ui/Toast';

type Props = {
  isOpen: boolean;
  model: Model;
  onOpenChange: (isOpen: boolean) => void;
  review?: Review;
  sessionId?: string;
};

export const ReviewModal = ({ isOpen, model, onOpenChange, review, sessionId = '' }: Props) => {
  const { user } = useUser();
  const { data: reviewData, isPending: isReviewLoading } = useGetUserReviewByModelQuery(
    { modelId: model?._id || '', userId: user?._id || '' },
    { enabled: !review && !!model?._id },
  );

  const userReview = review || reviewData?.data;

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />

        {isReviewLoading ? (
          <div>
            <Spinner className="size-5" />
          </div>
        ) : (
          <ReviewModalContent
            model={model}
            onOpenChange={onOpenChange}
            review={userReview}
            sessionId={sessionId}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

const ReviewModalContent = ({
  model,
  onOpenChange,
  review,
  sessionId = '',
}: { onOpenChange: (isOpen: boolean) => void; review?: Review } & Pick<Props, 'model' | 'sessionId'>) => {
  const { user } = useUser();
  const [rating, setRating] = useState(review?.rating ?? 5);
  const [ratingHovered, setRatingHovered] = useState<number>();
  const [feedback, setFeedback] = useState(review?.feedback ?? '');

  const { isPending, mutateAsync: createOrUpdateReview } = useCreateReviewMutation();
  const { isPending: isDeleting, mutateAsync: deleteReview } = useDeleteReviewMutation();

  const handleDeleteReview: MouseEventHandler<HTMLButtonElement> = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      if (!review) {
        toaster.error('No review found to delete');
      }

      await deleteReview({ id: review?._id || '' });

      const key = reviewsKeys.list({ modelId: model._id, userId: user?._id });
      queryClient.removeQueries({ queryKey: key });

      toaster.success('Review is removed successfully');
      onOpenChange(false);
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault();

      const { data } = await createOrUpdateReview({
        feedback,
        modelId: model._id,
        rating,
        sessionId,
        userId: user?._id || '',
      });

      console.log('data', data);

      const key = reviewsKeys.list({ modelId: model._id, userId: user?._id });

      queryClient.setQueryData(key, {
        data: {
          ...data,
          user,
        },
      });

      toaster.success('Your review was submitted');
      onOpenChange(false);

      setRating(5);
      setRatingHovered(undefined);
      setFeedback('');
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  return (
    <form className="flex flex-col p-1" onSubmit={handleSubmit}>
      <Modal.Title className="mb-1">Submit a Review</Modal.Title>
      <div className="mb-4 text-base/6 text-clay-700">
        How was your overall experience with <span className="font-medium">{model.name}</span>?
      </div>

      <h2 className="mb-1 text-base text-clay-700">Please rate your experience:</h2>

      <div className="mb-6 flex gap-0">
        {new Array(5).fill(null).map((_, i) => {
          const isHovered = ratingHovered ? i < ratingHovered : i < rating;
          return (
            <div
              className="flex  cursor-pointer items-center justify-center"
              key={i}
              onClick={() => setRating(i + 1)}
              onMouseEnter={() => setRatingHovered(i + 1)}
              onMouseLeave={() => setRatingHovered(undefined)}
            >
              <Icon
                className={twMerge(
                  'size-8 stroke-yellow-500 text-transparent transition-all',
                  isHovered && 'text-yellow-500',
                )}
                name="starSolid"
              />
            </div>
          );
        })}
      </div>

      <TextArea
        className="mb-4"
        label="Your feedback"
        onChange={(e) => setFeedback(e.target.value)}
        placeholder="Write your review here..."
        value={feedback}
      />

      <div className="flex gap-3">
        <Button disabled={isDeleting} isLoading={isPending} type="submit">
          Submit
        </Button>
        <Button
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onOpenChange(false);
          }}
          variant="filled-light"
        >
          Close
        </Button>

        {!!review && (
          <Button
            className="ml-auto border-none bg-transparent text-red-800 transition-all hover:brightness-90"
            color="secondary"
            isLoading={isDeleting}
            onClick={handleDeleteReview}
            variant="filled-light"
          >
            Delete
          </Button>
        )}
      </div>
    </form>
  );
};
