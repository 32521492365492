import { useState } from 'react';

import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { getStringifiedJSONData } from 'shared/helpers/getStringifiedJSONData';
import { CodeBlock } from 'shared/ui/CodeBlock';
import { Icon } from 'shared/ui/Icon';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';
import { Tooltip } from 'shared/ui/Tooltip';

import { ExpandModal } from './ExpandModal';
type Props = {
  data?: string;
  imageSrc: string;
  isLoading?: boolean;
} & ClassName;

export const IpfsImage = ({ className, data, imageSrc, isLoading }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={twMerge(
        'relative flex size-full flex-col items-center justify-center overflow-hidden rounded-xl',
        className,
      )}
    >
      {isLoading ? (
        <StretchedSkeleton enable />
      ) : (
        <Tooltip
          className="size-full"
          content={
            data ? (
              <CodeBlock
                className="min-h-fit text-xs [&>div]:p-0"
                customStyle={{ background: 'transparent' }}
                style={a11yDark}
              >
                {getStringifiedJSONData(data)}
              </CodeBlock>
            ) : (
              ''
            )
          }
          delayDuration={100}
          side="top"
        >
          <div className="relative flex size-full items-center justify-center">
            <div
              className="absolute left-1/2 top-1/2 flex size-11 -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-xl bg-black/20 text-white backdrop-blur-md transition-colors hover:text-clay-20"
              onClick={() => setIsExpanded(true)}
            >
              <Icon className="size-5 text-white" name="expand" />
            </div>
            <img alt="image" className="h-full rounded-xl object-cover object-center" src={imageSrc} />
          </div>
        </Tooltip>
      )}

      <ExpandModal imageSrc={imageSrc} isOpen={isExpanded} onOpenChange={setIsExpanded} />
    </div>
  );
};
