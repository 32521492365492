import type { PropsWithChildren } from 'react';
import { useState } from 'react';
import Countdown from 'react-countdown';
import { useParams } from 'react-router-dom';

import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { Section } from 'features/QueryMenu/QueryMenu';
import type { ClassName } from 'shared/types';

import { useUser } from 'app/stores/user';
import { UpgradeToProModal } from 'features/UpgradeToProModal';
import { useLimitReached } from 'shared/hooks/useLimitReached';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Button } from 'shared/ui/Button';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  isMinimized?: boolean;
  isRequestingSession?: boolean;
  modelId: string;
  onStart?: () => void;
} & ClassName;

export const QueryLimitOverview = ({ className, isMinimized, modelId }: PropsWithChildren<Props>) => {
  const { section } = useParams<{ id: string; section?: Section }>();
  const { isLimitReached, isPending, limits } = useLimitReached(modelId);

  const lg = useMinWidthMediaQuery('lg');

  const { proVersion } = useUser();
  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);

  if (isPending) {
    return (
      <div className="absolute inset-0 z-10 flex flex-col items-center bg-white py-3">
        <StretchedSkeleton
          className="relative mx-auto max-h-6 w-full max-w-44 rounded-full"
          enable
          rx={12}
          ry={12}
        />
      </div>
    );
  }

  if (proVersion?.isPro) return;

  const isTotalLimitReached = limits && limits.totalLimitLeft <= 0;

  if (!limits) return;

  if (!isLimitReached) {
    return (
      <motion.div
        animate={{ opacity: 1 }}
        className={twMerge(
          'flex items-center justify-center gap-3 whitespace-nowrap rounded-full bg-clay-20 px-4 py-0.5 text-xs/none font-light text-clay-500',
          className,
        )}
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
      >
        {isMinimized || !lg ? (
          <Tooltip content="Number of free uses remaining" side="top">
            <div className="flex cursor-default gap-1">
              {limits && (
                <span className="font-normal text-clay-800">
                  {limits.modelLimit - limits.modelLimitUsed}/{limits.modelLimit}
                </span>
              )}{' '}
            </div>
          </Tooltip>
        ) : (
          <div className="flex min-w-fit gap-1">
            Number of free uses remaining:{' '}
            {limits && (
              <span className="font-normal text-clay-800">
                {limits.modelLimit - limits.modelLimitUsed}/{limits.modelLimit}
              </span>
            )}{' '}
          </div>
        )}
        <Button
          className="min-w-fit bg-transparent p-0"
          onClick={() => setIsUpgradeOpen(true)}
          size="extra-small"
          variant="filled-light"
        >
          Get Pro
        </Button>

        <UpgradeToProModal
          onOpenChange={setIsUpgradeOpen}
          open={isUpgradeOpen}
          redirectPath={`/models/${modelId}${section ? `/${section}` : ''}`}
        />
      </motion.div>
    );
  }

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className={twMerge(
        'mt-1 flex size-fit items-center justify-center gap-1 rounded-full bg-clay-20 px-4 py-2 text-xs/none font-light text-clay-500',
        className,
      )}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
    >
      <span className="text-xs/none text-clay-500">
        {isTotalLimitReached ? 'All models reset in' : 'This model resets in'}{' '}
      </span>
      <span className="font-semibold">
        <Countdown date={DateTime.now().plus({ days: 1 }).startOf('day').toISO()} daysInHours />
      </span>
    </motion.div>
  );
};
