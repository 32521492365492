import type { ReactNode } from 'react';

import { getIsTextParams } from 'features/LLMQuery/helpers/getLLMModelParams';
import { Accordion } from 'shared/ui/Accordion';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';

import type { LLMQueryParams } from './LLMParams';

type Props = {
  children: ReactNode;
  isApiModel: boolean;
  isExpanded?: boolean;
  onExpandedChange?: (val: boolean) => void;
  queryParams: LLMQueryParams;
};

export const LLMParamsAccordion = ({
  children,
  isApiModel,
  isExpanded,
  onExpandedChange,
  queryParams,
}: Props) => {
  if (getIsTextParams(queryParams, isApiModel)) {
    return (
      <Card
        animate={{ opacity: 1 }}
        className="!pb-1"
        exit={{ opacity: 0 }}
        initial={isExpanded ? undefined : { opacity: 0 }}
      >
        <Accordion isExpanded={isExpanded} onChange={onExpandedChange}>
          <Accordion.Trigger className="flex items-center gap-2">
            <Icon className="size-4 stroke-clay-400" name="penLine" /> Parameters
          </Accordion.Trigger>
          <Accordion.Content>{children}</Accordion.Content>
        </Accordion>
      </Card>
    );
  }

  return (
    <Card
      animate={{ opacity: 1 }}
      className="!pb-1"
      exit={{ opacity: 0 }}
      initial={isExpanded ? undefined : { opacity: 0 }}
    >
      <Accordion isExpanded={isExpanded} onChange={onExpandedChange}>
        <Accordion.Trigger>Parameters</Accordion.Trigger>
        <Accordion.Content>{children}</Accordion.Content>
      </Accordion>
    </Card>
  );
};
