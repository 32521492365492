import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

export const useTokenCheckMutation = (options: UseMutationOptions = {}) => {
  return useMutation({
    mutationFn: async () => {
      try {
        const { data } = await axiosInstance.post<{ valid: boolean }>('/users/token/validation');
        return data;
      } catch (e) {
        return { valid: false };
      }
    },
    ...options,
  });
};
