import type { GptQueryParams, TextGenerationQueryParams } from 'features/LLMQuery/ui/LLMParams';

const textGenDefaultParams: TextGenerationQueryParams = {
  maxNewTokens: 1,
  numBeams: 1,
  question: '',
  systemPrompt: '',
  temperature: 1,
  topK: 0,
  topP: 1,
};

const gptDefaultParams: GptQueryParams = {
  apiKey: '',
  frequencyPenalty: 0,
  maxTokens: 1,
  presencePenalty: 0,
  private: false,
  question: '',
  stopSequence: '',
  temperature: 1,
  topP: 1,
};

export const getDefaultLLMParams = <B extends boolean>(
  isApiModel: B,
): B extends true ? GptQueryParams : TextGenerationQueryParams => {
  return (isApiModel ? gptDefaultParams : textGenDefaultParams) as B extends true
    ? GptQueryParams
    : TextGenerationQueryParams;
};
