import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { ModelCollection } from './types';

import { modelCollectionsQueryKeys } from './queryKeys';

export const useModelCollectionsQuery = (options: UseQueryOptions<ModelCollection[]> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<ModelCollection[]>(`/model-collections/my-collections`, {
        signal,
      });

      return data;
    },
    queryKey: modelCollectionsQueryKeys.myCollections(),
    ...options,
  });
};

export const useModelCollectionByIdQuery = (id?: string, options: UseQueryOptions<ModelCollection> = {}) => {
  return useQuery({
    enabled: !!id,
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<ModelCollection>(`/model-collections/${id}`, {
        signal,
      });

      return data;
    },
    queryKey: modelCollectionsQueryKeys.byId(id),
    ...options,
  });
};
