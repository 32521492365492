import { useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import * as Papa from 'papaparse';

import { Label } from 'shared/ui/Label';
import { Select } from 'shared/ui/Select';
import { Slider } from 'shared/ui/Slider';
import { Switch } from 'shared/ui/Switch';

import type { TimeSeriesForecastingParams } from '../types';

type Props = {
  onChange: (params: Partial<TimeSeriesForecastingParams>) => void;
  queryParams: TimeSeriesForecastingParams;
};

const evaluationOptions = (['MAE', 'MAPE', 'MSE', 'RMSE'] as const).map((value) => ({
  label: value,
  value,
}));

const seasonalityOptions = (['Daily', 'None', 'Weekly', 'Yearly'] as const).map((value) => ({
  label: value,
  value,
}));

const trendOptions = (['Additive', 'Multiplicative'] as const).map((value) => ({
  label: value,
  value,
}));

export const TimeSeriesForecasting = ({ onChange, queryParams }: Props) => {
  const [fields, setFields] = useState<string[]>([]);
  const [isParsing, setIsParsing] = useState(false);

  useEffect(() => {
    if (queryParams.file && queryParams.file.name.includes('.csv')) {
      setIsParsing(true);
      Papa.parse<unknown, File>(queryParams.file, {
        complete: (result) => {
          setFields(result.meta.fields || []);

          setIsParsing(false);
        },
        header: true,
      });
    }
  }, [queryParams.file]);
  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className="grid min-h-fit grid-cols-2 gap-3 overflow-hidden pb-4"
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <div>
        <Select
          disabled={isParsing || !fields.length}
          label="Date Column"
          onValueChange={(value) =>
            onChange({ dateColumn: value as TimeSeriesForecastingParams['dateColumn'] })
          }
          value={queryParams.dateColumn}
        >
          <Select.Content>
            {fields.map((value) => (
              <Select.Item key={value} value={value}>
                {value}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Select
          disabled={isParsing || !fields.length}
          label="Prediction Column"
          onValueChange={(value) =>
            onChange({ predictionColumn: value as TimeSeriesForecastingParams['predictionColumn'] })
          }
          value={queryParams.predictionColumn}
        >
          <Select.Content>
            {fields.map((value) => (
              <Select.Item key={value} value={value}>
                {value}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Select
          label="Language"
          onValueChange={(value) =>
            onChange({ evaluationMetric: value as TimeSeriesForecastingParams['evaluationMetric'] })
          }
          value={queryParams.evaluationMetric}
        >
          <Select.Content>
            {evaluationOptions.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Select
          label="Seasonality"
          onValueChange={(value) =>
            onChange({ seasonality: value as TimeSeriesForecastingParams['seasonality'] })
          }
          value={queryParams.seasonality}
        >
          <Select.Content>
            {seasonalityOptions.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Select
          label="Trend"
          onValueChange={(value) => onChange({ trend: value as TimeSeriesForecastingParams['trend'] })}
          value={queryParams.trend}
        >
          <Select.Content>
            {trendOptions.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>

      <div>
        <Label>Forecast Horizon</Label>
        <Slider
          controls
          max={365}
          min={1}
          onValueChange={(values) => onChange({ forecastHorizon: values[0] })}
          step={0.01}
          value={[queryParams.forecastHorizon]}
        />
      </div>

      <div>
        <Label>Learning Rate</Label>
        <Slider
          controls
          max={0.1}
          min={0.001}
          onValueChange={(values) => onChange({ learningRate: values[0] })}
          step={0.001}
          value={[queryParams.learningRate]}
        />
      </div>

      <div>
        <Label>Change Points</Label>
        <Slider
          controls
          max={25}
          min={0}
          onValueChange={(values) => onChange({ changePoints: values[0] })}
          step={1}
          value={[queryParams.changePoints]}
        />
      </div>

      <div className="flex items-center gap-2">
        <Label className="mb-0 flex cursor-pointer items-center gap-2">
          <Switch
            checked={queryParams.holidays}
            onCheckedChange={(isChecked) => onChange({ holidays: isChecked })}
          />
          Holidays
        </Label>
      </div>
    </motion.div>
  );
};
