import type { AxiosError } from 'axios';

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { useEmailVerificationMutation } from 'shared/api/verification/useEmailVerificationMutation';
import { Button } from 'shared/ui/Button';
import { Spinner } from 'shared/ui/Spinner';

import CheckCircle from './ui/assets/check-circle.svg?react';

export const EmailVerification = () => {
  const navigate = useNavigate();
  const { token, userId } = useParams<{ token: string; userId: string }>();
  const { error, isPending, isSuccess, mutateAsync } = useEmailVerificationMutation();

  useEffect(() => {
    token && userId && mutateAsync({ token, userId });
  }, [mutateAsync, token, userId]);

  const errorMessage = ((error as AxiosError)?.response?.data as { message: string })?.message;

  const iconElement = isPending ? (
    <Spinner className="size-full" />
  ) : errorMessage ? null : (
    <CheckCircle className="size-full" />
  );
  const titleText = isPending
    ? 'Verifying Your Email...'
    : errorMessage
      ? 'Something Went Wrong'
      : 'Your Email Has Been Verified';
  const paragraphText = isPending
    ? "You'll be up and running on Nesa shortly"
    : errorMessage
      ? errorMessage
      : 'You can now sign in and interact using your new Nesa Account';

  return (
    <div className="relative m-auto flex size-full h-dvh flex-col items-center justify-center sm:h-screen">
      <div className={twMerge(false === isSuccess && 'size-24', isSuccess && 'size-20')}>{iconElement}</div>

      <h1 className="mt-10 text-2xl font-bold text-clay-1000">{titleText}</h1>

      <h2 className="mt-2 max-w-xs text-center text-base text-clay-500">{paragraphText}</h2>

      <Button
        className={twMerge('mt-8', isPending && 'opacity-0')}
        onClick={() => navigate('/')}
        size="medium"
      >
        Browse Nesa
      </Button>
    </div>
  );
};
