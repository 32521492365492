import { getStringifiedJSONData } from 'shared/helpers/getStringifiedJSONData';
import { CodeBlock } from 'shared/ui/CodeBlock';

type Props = {
  context: string | undefined;
  data: {
    answer: string;
    end: number;
    score: number;
    start: number;
  };
};

export const QAResponse = ({ context, data }: Props) => {
  return (
    <div className="flex flex-col gap-5">
      {context && (
        <div className="flex flex-col gap-2">
          <div className="rounded-md bg-clay-10 px-4 py-3 text-clay-500">
            {context.slice(0, data.start)}{' '}
            <span className="rounded-sm border border-primary-800 bg-white p-0.5 text-primary-800">
              {context.slice(data.start, data.end)}
            </span>
            {context.slice(data.end)}
          </div>
        </div>
      )}
      <div className="flex h-full flex-col gap-2">
        <CodeBlock className="h-full [&>div]:p-0">{getStringifiedJSONData(data)}</CodeBlock>
      </div>
    </div>
  );
};
