import { useNavigate } from 'react-router-dom';

import { useAccount, useBalance } from 'graz';

import emptyWalletSrc from 'app/assets/images/empty-wallet.svg';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};
export const InsufficientBalanceModal = ({ isOpen, onOpenChange }: Props) => {
  const navigate = useNavigate();
  const { data: account } = useAccount();
  const { data: uBalance, isLoading: isBalanceLoading } = useBalance({
    bech32Address: account?.bech32Address,
    chainId: nesaTestnet.chainId,
    denom: 'unes',
    enabled: !!account?.bech32Address,
  });

  const balance = uBalance?.amount ? fromUnesToNes(uBalance.amount).toNumber() : undefined;

  const hasLowBalance = balance ? balance < 1 : true;

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />

        <div className="flex flex-col items-center justify-center gap-4 p-4">
          <img alt="emptyWallet" className="max-w-36" src={emptyWalletSrc} />

          <h2 className="text-center text-2xl font-semibold">Insufficient Balance</h2>
          <p className="mb-3 text-center text-clay-380">
            {`You don't have enough balance to begin or resume inference.`}{' '}
            {!hasLowBalance && `Please restart your session.`}
            {hasLowBalance && `Please visit our faucet page to get more tokens.`}
          </p>

          {!isBalanceLoading && hasLowBalance && (
            <Button isLoading={isBalanceLoading} onClick={() => navigate('/privilege/faucet')} size="medium">
              Get Tokens
            </Button>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};
