import type {
  GptQueryParams,
  LLMQueryParams,
  TextGenerationQueryParams,
} from 'features/LLMQuery/ui/LLMParams';

import { getDefaultLLMParams } from './getDefaultLLMParams';

type Props = {
  isApiModel: boolean;
  isZkAvailable?: boolean;
  queryParams: GptQueryParams | TextGenerationQueryParams;
};

export const getIsApiParams = (params: LLMQueryParams, isApiModel: boolean): params is GptQueryParams => {
  return isApiModel;
};

export const getIsTextParams = (
  params: LLMQueryParams,
  isApiModel: boolean,
): params is TextGenerationQueryParams => {
  return !isApiModel;
};

export const getLLMModelParams = ({ isApiModel = false, isZkAvailable, queryParams }: Props) => {
  if (getIsApiParams(queryParams, isApiModel)) {
    const defaultParams = getDefaultLLMParams(true);

    return {
      api_key: queryParams.apiKey || null,
      frequency_penalty: queryParams.frequencyPenalty || defaultParams.frequencyPenalty,
      max_tokens: queryParams.maxTokens,
      presence_penalty: queryParams.presencePenalty || defaultParams.presencePenalty,
      stop_sequence: queryParams.stopSequence || null,
      temperature: queryParams.temperature || defaultParams.temperature,
      top_p: queryParams.topP || defaultParams.topP,
    };
  }

  const defaultParams = getDefaultLLMParams(false);

  return {
    max_new_tokens: queryParams.maxNewTokens || defaultParams.maxNewTokens,
    num_beams: queryParams.numBeams || defaultParams.numBeams,
    system_prompt: queryParams.systemPrompt || null,
    temperature: queryParams.temperature || defaultParams.temperature,
    top_k: queryParams.topK || defaultParams.topK,
    top_p: queryParams.topP || defaultParams.topP,
    ...(isZkAvailable ? { use_zk: queryParams.useZk ?? false } : {}),
  };
};
