import { Link, useLocation } from 'react-router-dom';

import { Button } from 'shared/ui/Button';
import { TermsLinks } from 'shared/ui/TermsLinks';

import { Header } from '../ui/Header';
import gmailSrc from './ui/gmail.png';
import mailCheckSrc from './ui/mail-check.svg';

export const VerifyEmail = () => {
  const { state } = useLocation();
  return (
    <>
      <Header
        description={
          <>
            We sent an email to{' '}
            <span className="font-semibold text-clay-900">{state?.email || 'Unknown'}</span>. Click the link
            inside to get started.
          </>
        }
        imgSrc={mailCheckSrc}
        title="Verify your email"
      />

      <div className="mb-4 flex w-full flex-col">
        <Button color="secondary" onClick={() => window.open('https://mail.google.com/mail/', 'blank')}>
          <img alt="gmail" height={22} src={gmailSrc} width={22} />
          Open Gmail
        </Button>
      </div>

      <div className="text-center text-sm text-clay-500">
        Make sure to check your <span className="text-clay-900">spam</span> folder
      </div>

      <Link
        className="mt-10 block cursor-pointer text-center text-sm font-semibold text-clay-900 duration-200 hover:text-primary-900"
        to="/"
      >
        Skip this step
      </Link>
      <TermsLinks
        className="absolute bottom-6 left-1/2 mx-auto -translate-x-1/2 items-center "
        classNameLink="text-clay-300"
      />
    </>
  );
};
