import { motion } from 'framer-motion';

import { Label } from 'shared/ui/Label';
import { Select } from 'shared/ui/Select';
import { Slider } from 'shared/ui/Slider';

import type { ImageGenerationParams } from '../types';

type Props = {
  onChange: (params: Partial<ImageGenerationParams>) => void;
  queryParams: ImageGenerationParams;
};

const imageSizeOptions = (['512x512', '512x768', '768x512', '1024x1024'] as const).map((value) => ({
  label: value,
  value,
}));

const samplerOptions = (
  ['DDIM', 'DPM', 'DPM++ 2M karras', 'DPM2', 'Eluer', 'Euler a', 'Huen', 'LMS'] as const
).map((value) => ({
  label: value,
  value,
}));

export const ImageGen = ({ onChange, queryParams }: Props) => {
  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className="grid min-h-fit grid-cols-2 gap-2.5 overflow-hidden pb-4"
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <div>
        <Select
          label="Aspect ration"
          onValueChange={(value) => onChange({ aspectRatio: value as ImageGenerationParams['aspectRatio'] })}
          value={queryParams.aspectRatio}
        >
          <Select.Content>
            {imageSizeOptions.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Select
          label="Sampler"
          onValueChange={(value) => onChange({ sampler: value as ImageGenerationParams['sampler'] })}
          value={queryParams.sampler}
        >
          <Select.Content>
            {samplerOptions.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Label>Number of Inference Steps</Label>
        <Slider
          controls
          max={50}
          min={1}
          onValueChange={(values) => onChange({ numInferenceSteps: values[0] })}
          step={1}
          value={[queryParams.numInferenceSteps]}
        />
      </div>
      <div>
        <Label>CFG Scale</Label>
        <Slider
          controls
          max={30}
          min={1}
          onValueChange={(values) => onChange({ cfgScale: values[0] })}
          step={0.5}
          value={[queryParams.cfgScale]}
        />
      </div>
      <div>
        <Label>Clip Skip</Label>
        <Slider
          controls
          max={3}
          min={1}
          onValueChange={(values) => onChange({ clipSkip: values[0] })}
          step={1}
          value={[queryParams.clipSkip]}
        />
      </div>
      {/* <div>
        <Label>Seed</Label>
        <Input
          onChange={(e) => {
            const numValue = Number(e.target.value);
            if (numValue > 0) {
              onChange({ seed: numValue });
            }
          }}
          type="number"
          value={queryParams.seed}
        />
      </div> */}
    </motion.div>
  );
};
