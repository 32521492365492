import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import type { UseQueryOptions } from 'shared/types';

import type { DatasetsTagsByTypeResponse, GetDatasetsTagsByTypeParams } from './types';

import { queryKeys } from './queryKeys';

export const useGetDatasetsTagsByType = (
  params: GetDatasetsTagsByTypeParams,
  options: UseQueryOptions<DatasetsTagsByTypeResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<DatasetsTagsByTypeResponse>(
        `https://umga.nesa.ai/api/datasets-tags-by-type`,
        {
          headers: {
            // 'Access-Control-Allow-Origin': `https://umga.nesa.ai`,
            'Content-Type': 'application/json;charset=utf-8',
            // 'X-NS-AUTH': 'AB7345GDDJMNU7T',
          },
          params,
          paramsSerializer: {
            indexes: null, // no brackets at all
          },
          signal,
        },
      );

      return data;
    },
    queryKey: queryKeys.datasetsByType(params),
    ...options,
  });

  // return useInfiniteQuery({
  //   getNextPageParam: (last: any[] = [], all: any[][]) => (last.length === 30 ? all.length : null),
  //   initialPageParam: 0,
  //   queryFn: async ({ pageParam = 0, signal }) => {
  //     const res = await axios.get(`https://datasets.nesa.ai/datasets-json`, { params, signal });

  //     return res.data;
  //   },
  //   queryKey: queryKeys.json(params),
  //   ...(options || {}),
  // });
};
