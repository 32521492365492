import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';
import { getAudioFileDuration } from 'shared/helpers/getAudioFileDuration';

import { ipfsKeys } from './queryKeys';

export type FileResponse =
  | {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: any;
      type: Omit<string, 'audio'>;
    }
  | AudioFileResponse;

export type AudioFileResponse = { data: Blob; duration: number; type: 'audio' };
//QmUWsSJzxRjYbyKzSFQXWAs64ftndnoME2QWJ2hbcFJu1H
export const useGetFileByCidQuery = (
  cid: string,
  { disableSignal, ...options }: { disableSignal?: boolean } & UseQueryOptions<FileResponse | undefined> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      try {
        const { data, headers, ...other } = await axiosInstance.get(`/images/${cid}`, {
          signal: disableSignal ? undefined : signal,
          timeout: 60 * 1000,
        });

        const isImage = headers['content-type'].includes('image'); // image
        const isAudio = headers['content-type'].includes('audio'); // image
        const isVideo = headers['content-type'].includes('video'); // video
        console.log({ data, other, type: headers['content-type'] });

        console.log('isVideo', isVideo);

        if (isImage) {
          const { data: blobData } = await axiosInstance.get<Blob>(`/images/${cid}`, {
            responseType: 'blob',
            signal: disableSignal ? undefined : signal,
            timeout: 60 * 1000,
          });

          return { data: URL.createObjectURL(blobData), type: 'image' };
        }

        if (isVideo) {
          const { data: blobData } = await axiosInstance.get<Blob>(`/images/${cid}`, {
            responseType: 'blob',
            signal: disableSignal ? undefined : signal,
            timeout: 60 * 1000,
          });

          return { data: URL.createObjectURL(blobData), type: 'video' };
        }

        if (isAudio) {
          const { data: blobData } = await axiosInstance.get<Blob>(`/images/${cid}`, {
            responseType: 'blob',
            signal: disableSignal ? undefined : signal,
            timeout: 60 * 1000,
          });

          const duration = await getAudioFileDuration(blobData);

          return { data: blobData, duration, type: 'audio' };
        }

        return { data, type: 'data' };
      } catch (e) {
        console.log(e);
      }
    },
    queryKey: ipfsKeys.file({ cid }),
    ...options,
  });
};
