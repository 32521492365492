import { memo } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';

import type { TableColumn, TableProps, TableRowExt } from '../types';

type Props<ColumnKey extends string, Row extends TableRowExt<ColumnKey>> = {
  column: TableColumn<ColumnKey, Row>;
} & Pick<TableProps<ColumnKey, Row>, 'onSortChange' | 'sort'>;

const Th = <ColumnKey extends string, Row extends TableRowExt<ColumnKey>>({
  column,
  onSortChange,
  sort,
}: Props<ColumnKey, Row>) => {
  const onSortButtonClick = () => {
    if (column.withSort) {
      onSortChange?.({
        key: column.key,
        type: sort?.type === 'asc' && sort.key === column.key ? 'desc' : 'asc',
      });
    }
  };

  return (
    <th
      className={twMerge(
        'group/th border-t border-clay-20 bg-white p-4 text-left first:rounded-tl-lg last:rounded-tr-lg first-of-type:border-l last-of-type:border-r',
        column.withSort && 'cursor-pointer',
        column.thClassName,
      )}
      onClick={onSortButtonClick}
    >
      <div className="flex items-center justify-between gap-2">
        <div className="flex-1 text-sm font-semibold text-clay-900">{column.title}</div>
        {column.withSort && (
          <Icon
            className={twMerge(
              'size-3.5 text-clay-500 opacity-0 transition-opacity group-hover/th:opacity-50',
              sort?.key === column.key && 'opacity-100 group-hover/th:opacity-100',
              sort?.key === column.key && sort.type === 'desc' && 'rotate-180',
            )}
            name="arrowDown"
          />
        )}
      </div>
    </th>
  );
};

const ThMemo = memo(Th) as typeof Th;

export { ThMemo as Th };
