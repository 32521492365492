/* eslint-disable perfectionist/sort-objects */
export const openAiPricesByType: Record<string, { input?: number; output?: number; type: string }> = {
  // 'image-generation': {
  //   output: 0.08,
  //   type: 'image-generation',
  // },
  'speech-to-text': {
    input: 0.18,
    type: 'speech-to-text',
  },
  // 'text-generation': {
  //   output: 10,
  //   type: 'text-generation',
  // },
  'text-to-speech': {
    input: 15,
    type: 'text-to-speech',
  },
};

export const openAiPricesByName: Record<string, { input?: number; output?: null | number; type: string }> = {
  'BioMistral-7B': {
    output: 3,
    type: 'text-generation',
  },
  'Llama-3.2-1B-Instruct': {
    output: 0.6,
    type: 'text-generation',
  },
  'Llama-3.2-3B-Instruct': {
    output: 0.6,
    type: 'text-generation',
  },
  'Llama-3-8B-Lexi-Uncensored': {
    output: 3,
    type: 'text-generation',
  },
  'claude-3-opus-20240229': {
    output: null,
    type: 'text-generation',
  },
  'gpt-3.5-turbo-0125': {
    output: null,
    type: 'text-generation',
  },
  'gpt-4-turbo-preview': {
    output: null,
    type: 'text-generation',
  },
  'gpt-4o': {
    output: null,
    type: 'text-generation',
  },

  // image
  'FLUX.1-schnell': {
    output: 0.04,
    type: 'image-generation',
  },
  'kivotos-xl-2.0': {
    output: 0.04,
    type: 'image-generation',
  },
  'holodayo-xl-2.1': {
    output: 0.04,
    type: 'image-generation',
  },
  'PonyRealism-Uncensored': {
    output: 0.018,
    type: 'image-generation',
  },
  'LazyMix-Uncensored': {
    output: 0.018,
    type: 'image-generation',
  },
  'Hyper-SD': {
    output: 0.018,
    type: 'image-generation',
  },
  'Fluently-XL-Final': {
    output: 0.018,
    type: 'image-generation',
  },
  'NSFW-gen-v2': {
    output: 0.018,
    type: 'image-generation',
  },
  'stable-diffusion-xl-base-1.0': {
    output: 0.018,
    type: 'image-generation',
  },
  'sdxl-flash': {
    output: 0.018,
    type: 'image-generation',
  },
  'sdxl-turbo': {
    output: 0.018,
    type: 'image-generation',
  },
  mobius: {
    output: 0.018,
    type: 'image-generation',
  },
  'dall-e-3': {
    output: null,
    type: 'image-generation',
  },
  'dall-e-2': {
    output: null,
    type: 'image-generation',
  },
};
