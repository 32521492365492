import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';

type ActionId = 'add-to-collection' | 'download' | 'report';

const actions: { icon: IconName; id: ActionId; label: string; mobileVisibleOnly?: boolean }[] = [
  { icon: 'bug', id: 'report', label: 'Report' },
  { icon: 'plus', id: 'add-to-collection', label: 'Add to Collection' },
  { icon: 'download', id: 'download', label: 'Download', mobileVisibleOnly: true },
];
type Props = {
  isOpen: boolean;
  onActionClick: (action: ActionId) => void;
  onOpenChange: (isOpen: boolean) => void;
};

export const MorePopover = ({ isOpen, onActionClick, onOpenChange }: Props) => {
  return (
    <Popover onOpenChange={onOpenChange} open={isOpen}>
      <Popover.Trigger asChild>
        <div
          className={twMerge(
            'group flex h-9 cursor-pointer items-center gap-0 rounded-lg bg-white px-2 text-sm',
            isOpen && 'shadow-sm',
          )}
        >
          <Icon className="size-4 text-clay-800 transition-colors group-hover:text-primary-800" name="more" />{' '}
        </div>
      </Popover.Trigger>

      <Popover.Content
        align="end"
        className="rounded-lg p-1"
        collisionPadding={4}
        side="bottom"
        sideOffset={4}
      >
        {actions.map(({ icon, id, label, mobileVisibleOnly }) => {
          return (
            <div
              className={twMerge(
                'flex cursor-pointer items-center gap-2 rounded-md px-4 py-2 text-sm font-light text-clay-800 transition-colors hover:bg-clay-10',
                mobileVisibleOnly && 'lg:hidden',
              )}
              key={id}
              onClick={() => onActionClick(id)}
            >
              <Icon className={twMerge('size-4 text-clay-800', icon === 'plus' && 'size-3')} name={icon} />{' '}
              {label}
            </div>
          );
        })}
      </Popover.Content>
    </Popover>
  );
};
