import { type FormEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AxiosError } from 'axios';
import isEmail from 'validator/es/lib/isEmail';

import { useUser } from 'app/stores/user';
import { useLoginMutation } from 'shared/api/user/useLoginMutation';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
import { toaster } from 'shared/ui/Toast';

interface Props {
  isOpen: boolean;
  noOverlay?: boolean;
  onLogin?: () => void;
  onOpenChange: (isOpen: boolean) => void;
  onSignupClick?: () => void;
}

interface FormValues {
  email: string;
  password: string;
}

export const LoginModal = ({ isOpen, noOverlay, onLogin, onOpenChange, onSignupClick }: Props) => {
  const navigate = useNavigate();

  const {
    formState: { errors, isValid },
    getValues,
    register,
    reset,
  } = useForm<FormValues>({
    defaultValues: { email: '' },
    mode: 'onChange',
  });

  const { isPending, mutateAsync: login } = useLoginMutation();
  const { updateUser } = useUser();

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const values = getValues();

      const { user, whitelist } = await login({ ...values, remember: false });

      if (whitelist) {
        navigate('/whitelist');
        return;
      }

      updateUser(user);

      reset();

      onOpenChange(false);

      onLogin?.();
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content
        className="z-[100] max-w-full  sm:!max-w-96 md:!max-w-[364px] 2xl:!max-w-96"
        innerClassName="bg-[#1E1E1E]"
        noOverlay={noOverlay}
        wrapperClassName="z-[100]"
      >
        <Modal.Title className="mb-4 font-light text-white">Login</Modal.Title>

        <form className="mb-4 flex flex-col gap-2" onSubmit={handleLogin}>
          <Input
            errorSpacing
            {...register('email', { validate: (val) => (!isEmail(val) ? 'Invalid email' : true) })}
            classNameInput="placeholder:text-white/20 text-white font-light"
            classNameInputWrapper="bg-[#1E1E1E] !border-white/10"
            error={errors.email?.message}
            placeholder="Email Address"
          />
          <div className="relative flex flex-col gap-0">
            <Input
              classNameInput="placeholder:text-white/20 text-white font-light"
              classNameInputWrapper="bg-[#1E1E1E] !border-white/10"
              errorSpacing
              {...register('password')}
              error={errors.password?.message}
              placeholder="Password"
              type="password"
            />
            <div
              className="absolute bottom-0 right-0 flex cursor-pointer justify-end text-xs text-clay-500 transition-colors hover:text-primary-900"
              onClick={() => {
                const { email } = getValues();
                onOpenChange(false);
                navigate('/recovery/password', { state: { email } });
              }}
            >
              Forgot password?
            </div>
          </div>

          <Button disabled={!isValid} isLoading={isPending} type="submit">
            Login
          </Button>
        </form>

        {onSignupClick && (
          <div className="mx-auto flex flex-wrap justify-center gap-1 text-center text-xs text-clay-500">
            Do not have an account?
            <a
              className="cursor-pointer text-primary-800 transition-colors hover:text-primary-900"
              onClick={onSignupClick}
            >
              Sign Up
            </a>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};
