import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = {
  color?: 'gray' | 'primary' | 'white';
  selected: boolean;
};

export const ButtonTab = ({
  children,
  className,
  color = 'primary',
  selected,
  ...props
}: Omit<ComponentProps<'button'>, keyof Props> & Props) => {
  return (
    <button
      {...props}
      aria-selected={selected}
      className={twMerge(
        'inline-flex h-10 min-w-20 cursor-pointer items-center rounded-lg px-3 py-1 text-sm font-semibold transition-all duration-200',

        color === 'primary' &&
          'bg-transparent text-clay-700 aria-selected:bg-primary-1000 aria-selected:text-white hover:text-primary-1000 aria-selected:hover:text-white',

        color === 'gray' &&
          'bg-transparent text-clay-900 aria-selected:bg-clay-20 hover:bg-clay-10 aria-selected:hover:bg-clay-30',

        color === 'white' &&
          'bg-transparent text-clay-900 aria-selected:bg-white hover:bg-clay-20 aria-selected:hover:bg-clay-10',
        className,
      )}
    >
      {children}
    </button>
  );
};
