import type {
  AudioClassificationParams,
  AutoSpeechRecognitionParams,
  DocumentQAParams,
  ImageGenerationParams,
  OCRParams,
  QueryParams,
  SentenceSimilarityParams,
  SpeechToTextQueryParams,
  TextToSpeechQueryParams,
  TextToVideoParams,
  TimeSeriesForecastingParams,
  UnconditionalImageGenParams,
} from '../types';

export const getIsTextToSpeech = (
  params: QueryParams,
  modelType: string,
): params is TextToSpeechQueryParams => {
  return modelType === 'text-to-speech';
};

export const getIsSpeechToText = (
  params: QueryParams,
  modelType: string,
): params is SpeechToTextQueryParams => {
  return modelType === 'speech-to-text';
};

export const getIsAudioClassification = (
  params: QueryParams,
  modelType: string,
): params is AudioClassificationParams => {
  return modelType === 'audio-classification';
};

export const getIsDocumentQA = (params: QueryParams, modelType: string): params is DocumentQAParams => {
  return modelType === 'document-question-answering';
};

export const getIsOCR = (params: QueryParams, modelType: string): params is OCRParams => {
  return modelType === 'ocr';
};

export const getIsUnconditionalImageGen = (
  params: QueryParams,
  modelType: string,
): params is UnconditionalImageGenParams => {
  return modelType === 'unconditional-image-generation';
};

export const getIsAutoSpeechRecognition = (
  params: QueryParams,
  modelType: string,
): params is AutoSpeechRecognitionParams => {
  return modelType === 'automatic-speech-recognition';
};

export const getIsSentenceSimilarity = (
  params: QueryParams,
  modelType: string,
): params is SentenceSimilarityParams => {
  return modelType === 'sentence-similarity';
};

export const getIsImageGeneration = (
  params: QueryParams,
  modelType: string,
): params is ImageGenerationParams => {
  return modelType === 'image-generation';
};

export const getIsTextToVideo = (params: QueryParams, modelType: string): params is TextToVideoParams => {
  return modelType === 'text-to-video';
};

export const getIsTimeSeriesForecasting = (
  params: QueryParams,
  modelType: string,
): params is TimeSeriesForecastingParams => {
  return modelType === 'time-series-forecasting';
};
