import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { CreateModelCollection, EditModelCollection, ModelCollection } from './types';

export const useCreateModelCollectionsQuery = (
  options: UseMutationOptions<ModelCollection, unknown, CreateModelCollection> = {},
) => {
  return useMutation({
    mutationFn: async (params: CreateModelCollection) => {
      const { data } = await axiosInstance.post<ModelCollection>(`/model-collections/create`, params);

      return data;
    },
    ...options,
  });
};

export const useEditModelCollectionsQuery = (
  options: UseMutationOptions<ModelCollection, unknown, EditModelCollection> = {},
) => {
  return useMutation({
    mutationFn: async (params: EditModelCollection) => {
      const { data } = await axiosInstance.put<ModelCollection>(`/model-collections/edit`, params);

      return data;
    },
    ...options,
  });
};

export const useDeleteModelCollectionsQuery = (
  options: UseMutationOptions<ModelCollection, unknown, string> = {},
) => {
  return useMutation({
    mutationFn: async (id: string) => {
      const { data } = await axiosInstance.delete<ModelCollection>(`/model-collections/delete/${id}`);

      return data;
    },
    ...options,
  });
};
