export const getPrice = (price?: number, fallback = '-.--') => {
  if (!price || price <= 0) return fallback;

  const threeDecimals = price.toFixed(3);

  if (Number(threeDecimals) > 0) return threeDecimals;

  const firstSignificantPlace = price
    .toString()
    .split('.')[1]
    .split('')
    .findIndex((item) => item !== '0');

  return price.toFixed(firstSignificantPlace + 1);
};
