import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Flicking from '@egjs/react-flicking';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { Search } from 'app/ui/GalleryHeader/ui/Search';
import { filterItems, getCategoryLabel } from 'features/CategoryModelsDropdown';
import { MOCK_NESAS_PICK } from 'pages/GalleryHome/config';
import { useGetFeaturedModelsQuery } from 'shared/api/models/useGetFeaturedModelsQuery';
import { useGetModelsByNamesQuery } from 'shared/api/models/useGetModelsByNamesQuery';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Button } from 'shared/ui/Button';
import { GalleryCard } from 'shared/ui/GalleryCard';
import { Icon } from 'shared/ui/Icon';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

import type { FilterState } from '../GalleryFilters/GalleryFilters';

import bgSrc from './assets/bg.svg';

type Props = {
  onFilterChange: (filters: FilterState) => void;
};

export const Featured = ({ onFilterChange }: Props) => {
  const ref = useRef<Flicking>(null);
  const navigate = useNavigate();

  const sm = useMinWidthMediaQuery('sm');
  const md = useMinWidthMediaQuery('md');
  const lg = useMinWidthMediaQuery('lg');

  // const [activeIndex, setActiveIndex] = useState(0);

  const { data: models = [], isPending } = useGetModelsByNamesQuery({ names: MOCK_NESAS_PICK.slice(0, 9) });
  const { data, isPending: isFeaturedPending } = useGetFeaturedModelsQuery();

  const featuredList = data?.list || [];

  const getCardsCount = () => {
    if (lg) return 8;
    if (md) return 6;
    if (sm) return 4;

    return 2;
  };

  const viewCardsCount = getCardsCount();

  const viewCount = Math.ceil(models.length / viewCardsCount);

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="size-full overflow-x-hidden overflow-y-scroll"
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
    >
      <div className="m-3 mx-auto flex max-w-7xl flex-col rounded-xl border border-clay-20 bg-white">
        <div
          className="flex flex-col items-center justify-center border-b border-clay-20 bg-cover bg-center bg-no-repeat pb-12 pt-10"
          style={{ backgroundImage: `url(${bgSrc})` }}
        >
          <div className="mb-2 rounded-full bg-tusk-100 px-4 py-1 text-sm">Find any type of Model</div>
          <h1 className="mb-3 text-3xl font-bold md:text-5xl">Explore Nesa</h1>
          <div className="mb-4 flex items-center text-lg font-light text-clay-500">
            Browse from 120,000+ Models
          </div>

          <Search
            className={'!w-full px-6 md:!w-1/3'}
            classNameInputWrapper="!h-12 !shadow-md"
            classNameTrigger="!w-full"
            inheritPopoverWidth
            isHotKeySupported={false}
            placeholder="Find Models"
          />
        </div>

        <div className="mb-6 flex flex-col gap-4 p-6">
          <h2 className="text-lg font-normal">Featured Categories</h2>

          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
            {isFeaturedPending ? (
              <>
                {Array.from({ length: 4 }).map((_, idx) => (
                  <div className="relative h-48 w-full sm:h-56 lg:h-72" key={idx}>
                    <StretchedSkeleton enable rx={12} ry={12} />
                  </div>
                ))}
              </>
            ) : (
              featuredList.map((category) => {
                return (
                  <div
                    className="flex h-48 cursor-pointer flex-col overflow-hidden rounded-xl bg-clay-40 p-4 transition-all hover:brightness-95 sm:h-56 lg:h-72 lg:p-6"
                    key={category.id}
                    onClick={() => {
                      onFilterChange({ featuredEnabled: false, type: category.categories });
                    }}
                    style={category.color ? { backgroundColor: category.color } : undefined}
                  >
                    <div className="mb-6 flex h-6 flex-wrap gap-2 lg:mb-10 lg:h-8">
                      {category.tags.map((tag) => {
                        return (
                          <div
                            className="size-fit rounded-full bg-clay-10 px-2 py-1 text-xs font-light text-clay-600"
                            key={tag}
                          >
                            {tag}
                          </div>
                        );
                      })}
                    </div>

                    <h3 className="text-xl font-semibold md:text-2xl">{category.title}</h3>

                    <div className="mt-auto flex items-end justify-between">
                      <div className="text-base md:text-lg">{category.count} Models</div>
                      <Button className="pointer-events-none flex size-10 items-center justify-center rounded-full bg-clay-900 p-0 hover:bg-black">
                        <Icon className="text-white" name="arrowUpRight" />
                      </Button>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>

        <div className="mb-6 flex flex-col gap-4 p-6">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-normal">Favorite Models</h2>

            {/* <div>
            <div className="flex gap-2">
              <Icon
                className={twMerge(
                  'size-4 cursor-pointer rounded-md bg-clay-10 p-1.5 text-clay-500 transition-colors hover:bg-clay-20',
                  (activeIndex <= 0 || isPending) && 'pointer-events-none opacity-50',
                )}
                name="arrowLeftLong"
                onClick={() => ref.current?.prev()}
              />
              <Icon
                className={twMerge(
                  'size-4 rotate-180 cursor-pointer rounded-md bg-clay-10 p-1.5 text-clay-500 transition-colors hover:bg-clay-20',
                  (activeIndex >= viewCount - 1 || isPending) && 'pointer-events-none opacity-50',
                )}
                name="arrowLeftLong"
                onClick={() => ref.current?.next()}
              />
            </div>
          </div> */}
          </div>

          {isPending ? (
            <div className="grid grid-cols-1 grid-rows-2 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {Array.from({ length: viewCardsCount })
                .fill('')
                .map((_, idx) => (
                  <GalleryCard isLoading key={idx} />
                ))}
            </div>
          ) : (
            <Flicking
              align="prev"
              cameraClass="flex w-full"
              cameraTag="div"
              circular={false}
              defaultIndex={0}
              horizontal
              noPanelStyleOverride
              // onWillChange={(e: WillChangeEvent) => {
              //   // setActiveIndex(e.index);
              // }}
              ref={ref}
              renderOnSameKey={false}
              viewportTag="div"
            >
              {Array.from({ length: viewCount })
                .fill('')
                .map((_, idx) => {
                  return (
                    <div
                      className="mr-3 grid min-w-[calc(100%-8px)] grid-cols-1 grid-rows-2 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
                      key={idx}
                    >
                      {models.slice(idx * viewCardsCount, (idx + 1) * viewCardsCount).map((model) => {
                        return (
                          <GalleryCard
                            className="border border-clay-20"
                            key={model._id}
                            model={model}
                            onClick={() => navigate(`/models/${model._id}`)}
                          />
                        );
                      })}
                    </div>
                  );
                })}
            </Flicking>
          )}
        </div>

        <div className="mb-6 flex flex-col gap-4 p-6">
          <h2 className="text-lg font-normal">Models on Nesa</h2>

          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
            {filterItems.map((item) => {
              if (item.value === 'all') return;

              return (
                <div
                  className={twMerge(
                    'group flex h-10 w-full cursor-pointer flex-row items-center justify-start gap-1 rounded-md p-2.5 text-xs font-medium text-clay-700 transition-colors aria-selected:bg-clay-20 hover:bg-clay-20 xs:h-14 xs:gap-2 xs:p-3 xs:text-sm',
                  )}
                  key={item.value}
                  onClick={() => {
                    onFilterChange({ featuredEnabled: false, type: [item.value] });
                  }}
                >
                  <div
                    className={twMerge(
                      'flex size-6 items-center justify-center rounded-lg bg-clay-10 p-0.5 group-hover:bg-white xs:size-9 xs:p-2',
                    )}
                  >
                    <Icon
                      className={twMerge(
                        'size-4 p-0 text-clay-350 group-hover:text-primary-800 xs:size-5',
                        item.value === 'all' && 'size-3 xs:size-4',
                      )}
                      name={item.icon}
                    />
                  </div>
                  {getCategoryLabel(item.value)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
