import { WalletProvider } from 'features/WalletProvider';
import { Modal } from 'shared/ui/Modal';

interface Props {
  onConnected?: () => void;
  onOpenChange: (open: boolean) => void;
  open: boolean;
}

export const WalletProviderModal = ({ onConnected, onOpenChange, open }: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={open}>
      <Modal.Content className="w-full max-w-[600px]">
        <Modal.CloseButton />

        <WalletProvider
          onConnected={() => {
            onConnected?.();
            onOpenChange(false);
          }}
        />
      </Modal.Content>
    </Modal>
  );
};
