import type { ComponentProps, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';

export const BackButton = ({
  children,
  className,
  iconClassName,
  ...props
}: PropsWithChildren<{ iconClassName?: string } & ComponentProps<'button'>>) => {
  return (
    <button
      className={twMerge(
        'group flex cursor-pointer items-center gap-3 bg-transparent text-2xl font-semibold text-clay-900 outline-none transition-colors hover:text-clay-900  disabled:cursor-not-allowed disabled:text-clay-300',
        className,
      )}
      {...props}
    >
      <div
        className={twMerge(
          'flex size-6 items-center justify-center rounded-[4px] bg-clay-20 transition-colors group-hover:bg-clay-40',
          iconClassName,
        )}
      >
        <Icon className="size-4 text-clay-500" name="arrowLeftLong" />
      </div>
      {children}
    </button>
  );
};
