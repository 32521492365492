import type { PropsWithChildren, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = {
  footer: ReactNode;
  header: ReactNode;
  sidebar: ReactNode;
} & ClassName;

export const GalleryLayoutWrapper = ({
  children,
  className,
  footer: Footer,
  header: Header,
  sidebar: Sidebar,
}: PropsWithChildren<Props>) => {
  return (
    <div className={twMerge('flex h-dvh max-h-dvh ', className)}>
      <div className="h-full shrink-0">{Sidebar}</div>
      <div className="relative flex h-dvh grow flex-col gap-2 overflow-y-auto pb-16 sm:pb-0">
        {Header}
        {children}
      </div>
      <div className="fixed -bottom-px w-full">{Footer}</div>
    </div>
  );
};
