import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import type { UseQueryOptions } from 'shared/types';

import type { GetMatchingDatasetsResponse, GetMatchingDatasetsTagsParams } from './types';

import { queryKeys } from './queryKeys';

export const useGetMatchingDatasetsTags = (
  params: GetMatchingDatasetsTagsParams,
  options: UseQueryOptions<GetMatchingDatasetsResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<GetMatchingDatasetsResponse>(
        `https://umga.nesa.ai/api/matching-datasets-tags`,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          params,
          paramsSerializer: {
            indexes: null, // no brackets at all
          },
          signal,
        },
      );

      return data;
    },
    queryKey: queryKeys.matchingTags(params),
    ...options,
  });

  // return useInfiniteQuery({
  //   getNextPageParam: (last: any[] = [], all: any[][]) => (last.length === 30 ? all.length : null),
  //   initialPageParam: 0,
  //   queryFn: async ({ pageParam = 0, signal }) => {
  //     const res = await axios.get(`https://datasets.nesa.ai/datasets-json`, { params, signal });

  //     return res.data;
  //   },
  //   queryKey: queryKeys.json(params),
  //   ...(options || {}),
  // });
};
