import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { Model, ModelListByOrgRequestParams, ModelListResponse } from './types';

import { modelsKeys } from './queryKeys';

export const useGetModelListByOrgQuery = (
  { limit = 4, org, ...params }: ModelListByOrgRequestParams,
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: Model[], all: Model[][]) => (last.length === limit ? all.length : null),
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<ModelListResponse>(`models/orgs/${org}`, {
        params: { ...params, limit: limit, skip: pageParam * limit },
        signal,
      });

      return res.data.data;
    },
    queryKey: modelsKeys.modelsByOrg({ ...params, limit, org }),
    ...(options || {}),
  });
};
