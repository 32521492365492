import { isMobile } from 'react-device-detect';

import { WalletType, useSuggestChainAndConnect } from 'graz';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';
import cosmostationSrc from 'shared/ui/assets/cosmostation.svg';
import keplrSmLogo from 'shared/ui/assets/keplrSmLogo.svg';
import leapSrc from 'shared/ui/assets/leap.svg';
import metamaskSrc from 'shared/ui/assets/metamask.svg';

const wallets = [
  { hiddenMobile: false, label: 'Keplr', logo: keplrSmLogo, walletType: WalletType.KEPLR },
  { hiddenMobile: true, label: 'MetaMask', logo: metamaskSrc, walletType: WalletType.METAMASK_SNAP_LEAP },
  { hiddenMobile: false, label: 'Leap', logo: leapSrc, walletType: WalletType.LEAP },
  { hiddenMobile: false, label: 'Cosmostation', logo: cosmostationSrc, walletType: WalletType.COSMOSTATION },
];

export const checkInstalledWallet = (walletType: WalletType) => {
  if (walletType === WalletType.KEPLR) {
    return !!window.keplr;
  }
  if (walletType === WalletType.LEAP) {
    return !!window.leap;
  }
  if (walletType === WalletType.COSMOSTATION) {
    return !!window.cosmostation;
  }
  if (walletType === WalletType.METAMASK_SNAP_LEAP) {
    return !!window?.ethereum?.isMetaMask;
  }

  return true;
};

type Props = {
  onConnected?: () => void;
} & ClassName;

export const WalletProvider = ({ className, onConnected }: Props) => {
  const { isLoading, suggestAndConnectAsync } = useSuggestChainAndConnect();

  return (
    <div className={twMerge('flex w-full flex-col', className)}>
      <div className="mb-4 flex flex-col items-center justify-center pt-2">
        <Icon className="mb-4 size-20 text-clay-500" name="dashedWallet" />

        <h1 className="mb-2 text-center text-2xl font-semibold">Select your wallet provider</h1>
        <div className="text-center text-base font-light text-clay-500">
          Choose how you want to connect. There are several wallet providers.
        </div>
      </div>
      <div className="relative grid w-full grid-cols-2 gap-2">
        {isLoading && (
          <div className="absolute inset-0 z-20 col-span-2 flex flex-col items-center justify-center gap-3 bg-white/50 text-clay-500">
            <Spinner className="size-6" /> <span>Connecting...</span>
          </div>
        )}

        {wallets.map(({ hiddenMobile, label, logo, walletType }) => {
          if (hiddenMobile && isMobile) return;

          return (
            <div
              className={twMerge(
                'flex cursor-pointer items-center justify-center gap-4 overflow-hidden rounded-lg bg-clay-10 px-4 py-5 transition-colors hover:bg-clay-20',
                isLoading && 'pointer-events-none select-none opacity-50',
              )}
              key={walletType}
              onClick={async () => {
                const isInstalled = checkInstalledWallet(walletType);

                if (!isInstalled) {
                  toaster.error(`You don't have ${label} wallet installed or enabled`);
                  return;
                }

                await suggestAndConnectAsync({
                  chainInfo: nesaTestnet,
                  walletType,
                });

                onConnected?.();
              }}
            >
              <img className="size-7" src={logo} /> {label}
            </div>
          );
        })}
      </div>
      <div
        className="col-span-2 ml-auto mt-4 flex cursor-pointer flex-col items-center justify-center text-clay-800 transition-all hover:text-black"
        onClick={() =>
          window.open('https://docs.nesa.ai/nesa/using-nesa/getting-started/wallet-setup', '_blank')
        }
      >
        How to connect a wallet?
      </div>
    </div>
  );
};
