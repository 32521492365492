import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { StripeCheckSubscriptionResponse } from './types';

import { stripeQueryKeys } from './queryKeys';

export const useCheckSubscriptionQuery = <T = StripeCheckSubscriptionResponse>(
  options: UseQueryOptions<StripeCheckSubscriptionResponse, unknown, T> = {},
) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await axiosInstance.get<StripeCheckSubscriptionResponse>('/stripe/check-subscription');

      return data;
    },
    queryKey: stripeQueryKeys.checkSubscription(),
  });
};
