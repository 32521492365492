import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

export type CreateSubscriptionResponse = {
  id: string;
  url: string;
};

type CreateSubscriptionParams = {
  priceId: string;
  redirectPath?: string;
};

export const useCreateSubscriptionMutation = (
  options: UseMutationOptions<CreateSubscriptionResponse, unknown, CreateSubscriptionParams> = {},
) => {
  return useMutation({
    mutationFn: async (params) => {
      const { data } = await axiosInstance.post<CreateSubscriptionResponse>(
        '/stripe/create-subscription',
        params,
      );

      return data;
    },
    ...options,
  });
};
