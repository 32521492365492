import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Popover } from 'shared/ui/Popover';

type Props = {
  max: number;
  min: number;
  onChange: (value: string) => void;
  onReset: () => void;
  value: string;
};

const options = [
  { label: '25%', value: 0.25 },
  { label: '50%', value: 0.5 },
  { label: '75%', value: 0.75 },
];

const getIsValid = (value: number, min: number, max: number) => {
  return !(Number.isNaN(value) || value > max || value < min);
};

export const DetailsPopover = ({ max, min, onChange, onReset, value }: Props) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const numValue = Number(value);

  return (
    <Popover onOpenChange={setIsDetailsOpen} open={isDetailsOpen}>
      <Popover.Trigger
        className="outline-none"
        onClick={() => {
          if (isDetailsOpen) {
            onReset();
          }
          setIsDetailsOpen((prev) => !prev);
        }}
      >
        <div
          className={twMerge(
            'flex size-6 max-h-6 min-w-6 cursor-pointer items-center justify-center rounded-md bg-clay-10 p-0.5 outline-none transition-colors hover:bg-clay-20',
            isDetailsOpen && 'bg-clay-800 p-1 hover:bg-clay-900',
          )}
        >
          <Icon
            className={twMerge('size-full text-white transition-colors', !isDetailsOpen && 'text-clay-350')}
            name={isDetailsOpen ? 'check' : 'grip'}
          />
        </div>
      </Popover.Trigger>
      <Popover.Content
        alignOffset={8}
        className="flex max-w-40 flex-col p-2"
        hideWhenDetached={false}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (!getIsValid(numValue, min, max)) {
              onReset();
            } else {
              onChange(value);
            }

            setIsDetailsOpen(false);
          }
        }}
        sideOffset={8}
      >
        <Input
          className="mb-1"
          error={
            Number.isNaN(numValue) ? 'Invalid value' : numValue > max || numValue < min ? 'Out of range' : ''
          }
          errorSpacing
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (!getIsValid(numValue, min, max)) {
                onReset();
              } else {
                onChange(value);
              }

              setIsDetailsOpen(false);
            }
          }}
          value={value}
        />

        <div className="grid grid-cols-3 gap-1">
          {options.map(({ label, value }) => {
            return (
              <div
                className={twMerge(
                  'flex w-full cursor-pointer items-center justify-center rounded-md bg-clay-20 px-4 py-1 text-xs transition-colors hover:bg-primary-30',
                  value * max === numValue && 'bg-primary-30 text-primary-800',
                )}
                key={value}
                onClick={() => onChange(`${(max - min) * value + min}`)}
              >
                {label}
              </div>
            );
          })}
        </div>
      </Popover.Content>
    </Popover>
  );
};
