import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';

import { useGetReviewSummaryQuery } from 'shared/api/reviews/useGetReviewSummaryQuery';
import { Icon } from 'shared/ui/Icon';
import { Image } from 'shared/ui/Image';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';
import { Tooltip } from 'shared/ui/Tooltip';

import { WARM_MODELS } from '../config';
import fireSrc from './assets/fire.svg';

type Props = {
  isModelLoading?: boolean;
  model?: Model;
  onReviewsClick?: () => void;
};

export const ModelCard = ({ isModelLoading, model, onReviewsClick }: Props) => {
  const { data: reviewSummary, isPending: isReviewSummaryPending } = useGetReviewSummaryQuery(
    { modelId: model?._id || '' },
    { enabled: !!model?._id },
  );

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isWarmModel = model?.name && WARM_MODELS.includes(model.name);

  return (
    <div className="grid grid-cols-[3.5rem_1fr] gap-4">
      <Image className="size-14" src={model?.image || ''} />

      <div className="grid grid-cols-1 gap-0.5">
        <h3 className="relative min-h-5 truncate text-lg font-bold text-clay-900">
          <StretchedSkeleton enable={isModelLoading} rx={4} ry={4} />
          {!isModelLoading && model?.modelName}
        </h3>
        <div className="relative mb-2 min-h-5 text-sm font-medium text-clay-500">
          <StretchedSkeleton enable={isModelLoading} rx={4} ry={4} />
          {!isModelLoading && model?.org}
        </div>

        <div className="flex flex-wrap items-center gap-3 lg:gap-4">
          <Tooltip
            className="max-w-60"
            content={
              isWarmModel !== undefined ? (
                <span className="font-light">
                  <b className="font-semibold">This model is {isWarmModel ? 'warm' : 'cold'}.</b>{' '}
                  {`You'll get a fast response if the model is warm and
                  already running, and slower response if the model is cold and starting up.`}
                </span>
              ) : (
                ''
              )
            }
            onOpenChange={setIsTooltipOpen}
            open={isTooltipOpen}
          >
            <div
              className={twMerge(
                'relative flex min-h-6 min-w-14 select-none items-center gap-1.5 rounded-full border border-clay-400 px-2 py-0.5 text-xs/none font-normal capitalize text-clay-400',
                isWarmModel && 'gap-0.5 border-red-200 bg-red-100 pl-1 pr-2 text-[#F85713]',
              )}
              onClick={() => setIsTooltipOpen(true)}
            >
              {isWarmModel ? (
                <>
                  <img src={fireSrc} /> Warm
                </>
              ) : (
                <>
                  <div className="flex size-[6px] min-h-1.5 min-w-fit rounded-full bg-clay-350" /> Cold
                </>
              )}
            </div>
          </Tooltip>
          <div className="flex items-center gap-1">
            <Icon className="size-4 text-gold-600" name="starSolid" />
            <div
              className="group relative flex min-h-4 min-w-16 cursor-pointer items-end gap-1 text-sm/none font-medium text-gold-600 transition-colors"
              onClick={onReviewsClick}
            >
              <StretchedSkeleton enable={isReviewSummaryPending} rx={4} ry={4} />

              {!isReviewSummaryPending && (
                <>
                  {reviewSummary?.avg?.toFixed(2) || '--'}
                  <span className="font-light leading-none text-clay-20 transition-colors group-hover:text-gold-600">
                    /
                  </span>{' '}
                  <span className="relative min-w-5 font-normal leading-none text-clay-700 transition-colors group-hover:text-gold-600">
                    {reviewSummary?.totalCount}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center gap-1 text-sm text-clay-700">
            <Icon className="size-4 stroke-clay-350 text-transparent" name="bolt" />
            <span>{`< ${model?.latency || 3}sec avg`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
