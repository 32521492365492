import { useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import logoNameWhiteSrc from 'app/assets/images/logo-name-white.svg';
import logoWhiteSrc from 'app/assets/images/logo-white.svg';
import { useElementSize } from 'shared/hooks/useElementSize';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { AnimateRoute } from 'shared/ui/AnimateRoute';

import mobileBg from '../assets/bg-mobile.png';
import mobileVideo from '../assets/bg-mobile-video.mp4';
import video from '../assets/bg-video.mp4';
import { AuthCard } from './ui/AuthCard';

const GAP_CARDS = 16;

export const Auth = () => {
  const lg = useMinWidthMediaQuery('lg');

  const ref = useRef<HTMLDivElement>(null);
  const { height, width } = useElementSize(ref, lg);

  const cardWidth = (width - GAP_CARDS * 2) / 3;
  const cardHeight = (height - GAP_CARDS) / 2;
  const acceptCardWidth = cardHeight > cardWidth ? cardWidth : cardHeight;

  return (
    <AnimateRoute className="flex h-dvh flex-col lg:flex-row-reverse">
      <div className="relative -mb-4 flex-1 overflow-hidden lg:h-full lg:flex-[7]">
        <video
          aria-expanded="false"
          autoPlay
          className={twMerge(
            'absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2 object-center',
            lg ? 'min-h-[max(1600px,100%)]' : 'min-w-[max(794px,100%)]',
          )}
          id="static"
          loop
          muted
          playsInline
          poster={mobileBg}
          src={lg ? video : mobileVideo}
        ></video>

        {!lg && (
          <img
            alt=""
            className="absolute left-1/2 top-1/2 w-[153px] -translate-x-1/2 -translate-y-1/2"
            src={logoNameWhiteSrc}
          />
        )}
        {lg && (
          <div className="grid h-full grid-cols-1 grid-rows-1 overflow-hidden px-11 pb-11 pt-16 2xl:pt-20">
            <div className="relative grid size-full grid-rows-[auto_1fr] gap-16 2xl:gap-20">
              <div className="flex w-full flex-col items-end gap-7 pr-8">
                <img alt="" className="w-[153px]" src={logoNameWhiteSrc} />
                <p className="text-right text-lg font-medium text-white">
                  Nesa is a new type of AI platform that gives you
                  <br />
                  access to over 100,000 AI models at as low as
                  <br />
                  20% the cost of ChatGPT, DALLE and other
                  <br />
                  centralized AI.
                </p>
              </div>
              <div className="flex w-full justify-end overflow-hidden" ref={ref}>
                <div className="grid h-full grid-rows-2 gap-[16px]">
                  <div className="flex items-end justify-center gap-[16px]">
                    <AuthCard title="Unique Models" value="120k" width={acceptCardWidth} />
                    <AuthCard className="bg-[#E0FF8733]" width={acceptCardWidth}>
                      <img
                        alt=""
                        className="absolute left-1/2 top-1/2 w-1/3 min-w-[76px] -translate-x-1/2 -translate-y-1/2"
                        src={logoWhiteSrc}
                      />
                    </AuthCard>

                    <AuthCard hidden width={acceptCardWidth} />
                  </div>
                  <div className="flex items-start justify-center gap-[16px]">
                    <AuthCard hidden width={acceptCardWidth} />
                    <AuthCard reverse title="Modalities" width={acceptCardWidth}>
                      <div className="absolute right-6 top-6 text-[8vw] font-semibold leading-none text-white">
                        29
                      </div>
                    </AuthCard>
                    <AuthCard
                      title="Response Speed"
                      value={
                        <>
                          <span className="text-[1.15em] font-thin">{'<'}</span>1.3s
                        </>
                      }
                      width={acceptCardWidth}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="relative z-10 flex min-h-[55%] flex-col justify-center rounded-t-2xl bg-white px-7 py-8 lg:flex-[4] lg:rounded-none">
        <div className="mx-auto w-full max-w-xl lg:max-w-[26rem] 2xl:max-w-md">
          <Outlet />
        </div>
      </div>
    </AnimateRoute>
  );
};
