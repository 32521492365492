import { memo, useEffect } from 'react';

import { saveAs } from 'file-saver';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';

import type { QueryParams } from 'features/ModelQueryParams/types';
import type { AudioFileResponse, FileResponse } from 'shared/api/ipfs/useGetFileByCidQuery';
import type { Model } from 'shared/api/models/types';

import { useUser } from 'app/stores/user';
import { AudioPanel } from 'features/AudioPanel';
import { QAResponse } from 'features/ModelQuery/ui/QAResponse';
import { TokenClassificationResponse } from 'features/ModelQuery/ui/TokenClassificationResponse';
import { TranslationResponse } from 'features/ModelQuery/ui/TranslationResponse';
import {
  getIsAudioClassification,
  getIsAutoSpeechRecognition,
  getIsDocumentQA,
  getIsImageGeneration,
  getIsSentenceSimilarity,
  getIsSpeechToText,
  getIsTextToSpeech,
  getIsTextToVideo,
  getIsTimeSeriesForecasting,
  getIsUnconditionalImageGen,
} from 'features/ModelQueryParams/helpers/modelParamsChecks';
import { useErrorSubmitMutation } from 'shared/api/errors/useErrorSubmitMutation';
import { useGetFileByCidQuery } from 'shared/api/ipfs/useGetFileByCidQuery';
import { getIsEmptyResponse } from 'shared/helpers/getIsEmptyResponse';
import { getStringifiedJSONData } from 'shared/helpers/getStringifiedJSONData';
import { Button } from 'shared/ui/Button';
import { CodeBlock } from 'shared/ui/CodeBlock';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { VideoPlayer } from 'shared/ui/VideoPlayer';

import { DataCarousel } from './DataCarousel';
import { ImageCarousel } from './ImageCarousel';
import { IpfsImage } from './IpfsImage';

type Props = {
  ipfsLinks?: string[];
  model?: Model;
  requestData?: QueryParams;
  textList: string[];
};

const getIsAudio = (fileData: FileResponse): fileData is AudioFileResponse => {
  return fileData.type === 'audio';
};

// TODO: check what's going on with props and why are they changing often when user is waiting for response
export const ResponseContent = memo(({ ipfsLinks = [], model, requestData, textList }: Props) => {
  const {
    data: fileData,
    error,
    isError,
    isLoading: isLoadingFile,
    refetch,
  } = useGetFileByCidQuery(ipfsLinks[0], {
    enabled: ipfsLinks?.length === 1 && !!ipfsLinks[0],
  });

  console.log('file', fileData?.data, fileData?.type);

  const { user } = useUser();
  const { mutateAsync: logError } = useErrorSubmitMutation();

  useEffect(() => {
    if (!isError) return;

    logError({
      error: 'IPFS fetch error',
      modelId: model?._id || '',
      params: {
        error: `${error}`,
        ipfsLink: ipfsLinks.join(','),
        modelName: model?.name,
      },
      userId: user?._id || '',
    });
  }, [error, isError, logError, model?._id, model?.name, user?._id, ipfsLinks]);

  if (isLoadingFile) {
    return (
      <div className="pb-3">
        <Spinner className="size-4" />
      </div>
    );
  }

  if (!!ipfsLinks[0] && isError) {
    return (
      <div className="my-auto flex flex-col items-center justify-center gap-3">
        <p className="text-center text-clay-500">Error while fetching a response</p>
        <Button onClick={() => refetch()}>Try again</Button>
      </div>
    );
  }

  if (ipfsLinks && ipfsLinks.length > 1) {
    return model?.type === 'image-segmentation' ? (
      <ImageCarousel cidList={ipfsLinks} />
    ) : (
      <DataCarousel cidList={ipfsLinks} />
    );
  }

  if (fileData?.type === 'image') {
    return (
      <div className="flex flex-col overflow-hidden pb-2">
        <IpfsImage className="max-h-56" imageSrc={fileData.data} isLoading={isLoadingFile} />
      </div>
    );
  }

  if (fileData?.type === 'video') {
    return (
      <div className="flex  flex-col gap-3 overflow-hidden pb-2">
        <VideoPlayer url={fileData.data} />

        <div
          className="group ml-auto flex max-w-fit cursor-pointer items-center gap-2 rounded-lg bg-clay-20 px-3 py-2.5 text-sm text-clay-800 backdrop-blur-md transition-colors hover:bg-clay-30"
          onClick={() => saveAs(fileData.data, 'video')}
        >
          <Icon name="download" />
          Download
        </div>
      </div>
    );
  }

  if (fileData && getIsAudio(fileData)) {
    return (
      <div className="flex flex-col items-center justify-between gap-4 pb-3 lg:flex-row">
        <AudioPanel
          className="bg-white"
          disableReset
          onChange={() => {}}
          voice={{ audioBlob: fileData.data, duration: fileData.duration, id: uuid() }}
        />

        <div
          className="group flex cursor-pointer items-center gap-2 rounded-lg bg-clay-20 px-3 py-2.5 text-sm text-clay-800 backdrop-blur-md transition-colors hover:bg-clay-30"
          onClick={() => saveAs(fileData.data, 'audio.wav')}
        >
          <Icon name="download" />
          Download
        </div>
      </div>
    );
  }

  if (fileData?.type === 'data' && requestData && model) {
    if (
      getIsTextToSpeech(requestData, model.type) ||
      getIsTextToVideo(requestData, model.type) ||
      getIsTimeSeriesForecasting(requestData, model.type)
    ) {
      return;
    }

    if (getIsSpeechToText(requestData, model.type)) {
      return <span className="pb-4 text-sm font-light xl:text-base">{fileData?.data?.text}</span>;
    }

    if (getIsAutoSpeechRecognition(requestData, model.type)) {
      return (
        <div className="flex h-full flex-col">
          <CodeBlock className="h-full">{getStringifiedJSONData(fileData?.data)}</CodeBlock>
        </div>
      );
    }

    if (getIsAudioClassification(requestData, model.type)) {
      return <span className="break-all">Audio classification - {JSON.stringify(fileData?.data)}</span>;
    }

    if (getIsDocumentQA(requestData, model.type) || getIsSentenceSimilarity(requestData, model.type)) {
      return (
        <div className="flex h-full flex-col">
          <CodeBlock className="h-full">{getStringifiedJSONData(fileData.data)}</CodeBlock>
        </div>
      );
    }

    if (
      getIsUnconditionalImageGen(requestData, model.type) ||
      getIsImageGeneration(requestData, model.type)
    ) {
      return (
        <div className="flex flex-col overflow-hidden pb-2">
          <img
            alt="image"
            className="mr-auto min-w-[-webkit-fill-available] object-contain"
            src={fileData?.data}
          />
        </div>
      );
    }

    if (model?.type === 'question-answering') {
      console.log('fileData', fileData);
      return <QAResponse context={requestData?.context} data={fileData.data} />;
    }

    if (model?.type === 'token-classification') {
      return <TokenClassificationResponse data={fileData.data} input={requestData?.question} />;
    }

    if (model?.type === 'translation') {
      return <TranslationResponse data={fileData.data} />;
    }

    if (typeof fileData?.data === 'object') {
      const isEmpty = fileData && getIsEmptyResponse(fileData.data);

      return (
        <div className="flex h-full flex-col">
          {isEmpty ? (
            <div className="my-auto flex items-center justify-center text-xs text-clay-500">
              No predictions available for the given input.
            </div>
          ) : (
            <CodeBlock className="h-full">{getStringifiedJSONData(fileData?.data)}</CodeBlock>
          )}
        </div>
      );
    }
    console.log('other');
    return <span className="break-all">{JSON.stringify(fileData?.data)}</span>;
  }

  if (textList.length > 0) {
    return (
      <div className="flex grow flex-col">
        <div className="inline-block text-base text-clay-1000">
          {textList.map((item, i) => {
            return (
              <motion.span animate={{ opacity: 1 }} initial={{ opacity: 0 }} key={i}>
                {item}
              </motion.span>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div className="flex grow items-center justify-center gap-2 pb-6 pt-4 text-center text-sm font-light text-clay-500">
      <Icon className="size-4 text-clay-400" name="logs" />
      No Data Available
    </div>
  );
});
