import { useState } from 'react';
import { Link } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { QueryHistoryListItem } from 'shared/api/queryHistory/types';

import { QueryHistoryItemModal } from 'features/QueryHistory';
import { useGetQueryHistoryByUserInfiniteQuery } from 'shared/api/queryHistory/useGetQueryHistoryByUserInfiniteQuery';
import { Accordion } from 'shared/ui/Accordion';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  classNameViewAll?: string;
  contentClassName?: string;
  isOpen?: boolean;
  modelId: string;
  onOpenChange?: (val: boolean) => void;
  userId: string;
};

export const QueryHistoryContent = ({
  classNameViewAll,
  modelId,
  userId,
}: Pick<Props, 'classNameViewAll' | 'modelId' | 'userId'>) => {
  const [selectedQueryHistory, setSelectedQueryHistory] = useState<QueryHistoryListItem>();

  const { data: queryHistory, isLoading } = useGetQueryHistoryByUserInfiniteQuery(
    { limit: 10, modelId: modelId, userId: userId },
    { enabled: !!userId && !!modelId },
  );
  const history = queryHistory?.pages.flat() || [];

  return (
    <>
      {isLoading ? (
        <div className="flex size-full items-center justify-center">
          <Spinner className="mx-auto size-6" />
        </div>
      ) : history.length > 0 ? (
        <div className="grid grid-cols-1 gap-2 overflow-scroll pb-8 scrollbar-none">
          {history.map((item) => {
            const { date, question } = item;
            return (
              <AnimatePresence key={item._id}>
                <motion.div
                  animate={{ opacity: 1 }}
                  className={twMerge(
                    'relative flex h-7 min-h-fit flex-col items-start justify-center gap-1 overflow-hidden rounded-lg border border-clay-20 bg-white p-2 text-clay-500 transition-colors',
                    'cursor-pointer hover:border-primary-900 hover:bg-primary-40 ',
                  )}
                  initial={{ opacity: 0 }}
                  key={item._id}
                  onClick={() => {
                    setSelectedQueryHistory(item);
                  }}
                >
                  <div className="w-full overflow-hidden truncate px-1 text-sm text-clay-800">{question}</div>

                  <div className="flex items-center justify-start gap-1 font-fira text-xs text-clay-400">
                    <Icon className="size-4" name="clock" />
                    {date ? DateTime.fromISO(date).toFormat('dd/MM HH:mma') : '-'}
                  </div>

                  {item.private && (
                    <Link
                      className="absolute bottom-1 right-1 flex cursor-pointer select-none items-center justify-center gap-0.5 rounded-md bg-clay-800 py-1 pl-1 pr-0.5 text-xs font-light text-white transition-colors hover:bg-clay-900"
                      to={`/private-tx/${item._id}`}
                    >
                      <Icon className="size-3 text-tusk-200" name="lock" />
                      Private
                      <Icon className="size-4 text-white" name="arrowUpRight" />
                    </Link>
                  )}
                </motion.div>
              </AnimatePresence>
            );
          })}
        </div>
      ) : (
        <div className="flex grow items-center justify-center gap-2 pb-6 pt-4 text-center text-sm font-light text-clay-500">
          <Icon className="size-4 text-clay-400" name="logs" />
          No History Available
        </div>
      )}

      {history.length > 0 && (
        <div
          className={twMerge(
            'absolute bottom-0 ml-auto flex w-full items-end justify-end bg-white py-2',
            classNameViewAll,
          )}
        >
          <Link
            className="flex cursor-pointer items-center gap-1.5 text-xs font-medium text-primary-800 transition-colors hover:text-primary-900"
            to="/query-history"
          >
            View all history{' '}
            <Icon className="size-3 rounded-sm bg-primary-30 p-0.5 text-primary-800" name="arrowUpRight" />
          </Link>
        </div>
      )}

      {selectedQueryHistory && (
        <QueryHistoryItemModal
          isOpen={true}
          onOpenChange={() => setSelectedQueryHistory(undefined)}
          queryHistoryItem={selectedQueryHistory}
        />
      )}
    </>
  );
};

export const QueryHistory = ({
  classNameViewAll,
  contentClassName,
  isOpen,
  modelId,
  onOpenChange,
  userId,
}: Props) => {
  return (
    <Card animate={{ opacity: 1 }} className="!pb-1" exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
      <Accordion isExpanded={isOpen} onChange={onOpenChange}>
        <Accordion.Trigger className="flex items-center gap-2">
          <Icon className="size-5 text-clay-400" name="history" /> Query History
        </Accordion.Trigger>
        <Accordion.Content
          className={twMerge(
            'relative grid h-36 grid-cols-1 grid-rows-[0.5rem_1fr] gap-2 overflow-hidden scrollbar-none',
            contentClassName,
          )}
        >
          <QueryHistoryContent classNameViewAll={classNameViewAll} modelId={modelId} userId={userId} />
        </Accordion.Content>
      </Accordion>
    </Card>
  );
};
