import type Stripe from 'stripe';

import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';

import { loadStripe } from '@stripe/stripe-js';

import { useCreateSubscriptionMutation, useGetProPricesQuery } from 'shared/api/stripe';
import { toaster } from 'shared/ui/Toast';

export type ProductStripeType =
  | 'nesa.enterprise.month'
  | 'nesa.enterprise.year'
  | 'nesa.pro.month'
  | 'nesa.pro.year';

export type PricesProps = {
  data: Partial<Record<ProductStripeType, Stripe.Price>> | null;
  isError: boolean;
  isLoading: boolean;
  isPending: boolean;
};

export const PricesContext = createContext<PricesProps>({
  data: null,
  isError: false,
  isLoading: false,
  isPending: false,
});

export const ProductPricesProvider = ({ children }: PropsWithChildren) => {
  const { data: prices, isError, isLoading, isPending } = useGetProPricesQuery();

  const value = useMemo(() => {
    const priceProByMonth = prices?.data.find(
      (el) => (el.product as Stripe.Product)?.name === 'Nesa Pro' && el.recurring?.interval === 'month',
    );
    const priceProByYear = prices?.data.find(
      (el) => (el.product as Stripe.Product)?.name === 'Nesa Pro' && el.recurring?.interval === 'year',
    );

    const enterprisePriceByMonth = prices?.data.find(
      (el) =>
        (el.product as Stripe.Product)?.name === 'Nesa Enterprise' && el.recurring?.interval === 'month',
    );
    const enterprisePriceByYear = prices?.data.find(
      (el) => (el.product as Stripe.Product)?.name === 'Nesa Enterprise' && el.recurring?.interval === 'year',
    );

    const data: PricesProps['data'] = {};

    if (priceProByMonth) {
      data['nesa.pro.month'] = priceProByMonth;
    }
    if (priceProByYear) {
      data['nesa.pro.year'] = priceProByYear;
    }

    if (enterprisePriceByMonth) {
      data['nesa.enterprise.month'] = enterprisePriceByMonth;
    }
    if (enterprisePriceByYear) {
      data['nesa.enterprise.year'] = enterprisePriceByYear;
    }

    return {
      data: prices ? data : null,
      isError,
      isLoading,
      isPending,
    };
  }, [prices, isError, isLoading, isPending]);

  return <PricesContext.Provider value={value}>{children}</PricesContext.Provider>;
};

export const useProductPrices = () => useContext(PricesContext);

export const useProductStripePayment = (redirectPath?: string) => {
  const { data: prices } = useProductPrices();

  const { mutateAsync: createSubscription } = useCreateSubscriptionMutation();
  const payment = useCallback(
    async (productType: ProductStripeType) => {
      const price = prices?.[productType];

      if (!price) return;

      try {
        const res = await createSubscription({ priceId: price.id, redirectPath });

        const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHED_KEY);

        const resultCheckout = await stripe?.redirectToCheckout({
          sessionId: res.id,
        });
        if (resultCheckout?.error) {
          toaster.error('Something went wrong');
          throw new Error('Something went wrong');
        }
      } catch (error) {
        toaster.error('Something went wrong');
        throw error;
      }
    },
    [createSubscription, prices, redirectPath],
  );

  return payment;
};
