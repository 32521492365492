import type { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { getIsTextParams } from 'features/LLMQuery/helpers/getLLMModelParams';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { Slider } from 'shared/ui/Slider';

export type LLMQueryParams = GptQueryParams | TextGenerationQueryParams;

export type TextGenerationQueryParams = {
  maxNewTokens: number;
  numBeams: number;
  private?: boolean;
  question: string;
  systemPrompt: string;
  temperature: number;
  topK: number;
  topP: number;
  useZk?: boolean;
};

export type GptQueryParams = {
  apiKey?: string;
  frequencyPenalty: number;
  maxTokens?: number;
  presencePenalty: number;
  private?: boolean;
  question: string;
  stopSequence?: string;
  temperature: number;
  topP: number;
};

type Props = {
  classNameSliderWrapper?: string;
  divider?: ReactNode;
  isApiModel: boolean;
  onChange: (params: Partial<LLMQueryParams>) => void;
  queryParams: LLMQueryParams;
} & ClassName;

export const LLMParams = ({
  className,
  classNameSliderWrapper,
  isApiModel,
  onChange,
  queryParams,
}: Props) => {
  if (getIsTextParams(queryParams, isApiModel)) {
    return (
      <div className={twMerge('grid grid-cols-2 gap-3 pb-3', className)}>
        <div className="col-span-2">
          <Input
            label="System Prompt"
            onChange={(e) => onChange({ systemPrompt: e.target.value })}
            placeholder="Your System Prompt"
            value={queryParams.systemPrompt}
          />
        </div>
        <div className={classNameSliderWrapper}>
          <Label>Temperature</Label>
          <Slider
            controls
            max={2}
            min={0}
            onValueChange={(values) => onChange({ temperature: values[0] })}
            step={0.01}
            value={[queryParams.temperature]}
          />
        </div>
        <div className={classNameSliderWrapper}>
          <Label>Top P</Label>
          <Slider
            controls
            max={1}
            min={0}
            onValueChange={(values) => onChange({ topP: values[0] })}
            step={0.01}
            value={[queryParams.topP]}
          />
        </div>
        <div className={classNameSliderWrapper}>
          <Label>Top K</Label>
          <Slider
            controls
            max={100}
            min={0}
            onValueChange={(values) => onChange({ topK: values[0] })}
            step={1}
            value={[queryParams.topK || 0]}
          />
        </div>
        <div className={classNameSliderWrapper}>
          <Label>Num Beams</Label>
          <Slider
            controls
            max={20}
            min={1}
            onValueChange={(values) => onChange({ numBeams: values[0] })}
            step={1}
            value={[queryParams.numBeams || 1]}
          />
        </div>
        <div className={classNameSliderWrapper}>
          <Label>Max New Tokens</Label>
          <Slider
            controls
            max={4096}
            min={1}
            onValueChange={(values) => onChange({ maxNewTokens: values[0] })}
            step={1}
            value={[queryParams.maxNewTokens || 1]}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={twMerge('grid grid-cols-2 gap-3 pb-3', className)}>
      <div className="col-span-2">
        <Input
          label="Stop sequence"
          onChange={(e) => onChange({ stopSequence: e.target.value })}
          placeholder="Enter sequence and press tab"
          value={queryParams.stopSequence}
        />
      </div>
      <div className="col-span-2">
        <Input
          label="API Key"
          onChange={(e) => onChange({ apiKey: e.target.value })}
          placeholder="Your api key"
          value={queryParams.apiKey}
        />
      </div>
      <div className={classNameSliderWrapper}>
        <Label>Temperature</Label>
        <Slider
          controls
          max={2}
          min={0}
          onValueChange={(values) => onChange({ temperature: values[0] })}
          step={0.01}
          value={[queryParams.temperature]}
        />
      </div>
      <div className={classNameSliderWrapper}>
        <Label>Maximum Tokens</Label>
        <Slider
          controls
          max={256}
          min={0}
          onValueChange={(values) => onChange({ maxTokens: values[0] || 0 })}
          step={0.01}
          value={[queryParams.maxTokens || 0]}
        />
      </div>

      <div className={classNameSliderWrapper}>
        <Label>Top P</Label>
        <Slider
          controls
          max={1}
          min={0}
          onValueChange={(values) => onChange({ topP: values[0] })}
          step={0.01}
          value={[queryParams.topP]}
        />
      </div>
      {queryParams.frequencyPenalty !== undefined && (
        <div className={classNameSliderWrapper}>
          <Label>Frequency penalty</Label>
          <Slider
            controls
            max={2}
            min={0}
            onValueChange={(values) => onChange({ frequencyPenalty: values[0] })}
            step={0.01}
            value={[queryParams.frequencyPenalty]}
          />
        </div>
      )}
      {queryParams.presencePenalty !== undefined && (
        <div className={classNameSliderWrapper}>
          <Label>Presence penalty</Label>
          <Slider
            controls
            max={2}
            min={0}
            onValueChange={(values) => onChange({ presencePenalty: values[0] })}
            step={0.01}
            value={[queryParams.presencePenalty]}
          />
        </div>
      )}
    </div>
  );
};
