import { memo, useEffect, useRef, useState } from 'react';

import { motion } from 'framer-motion';
import { Duration } from 'luxon';
import { twMerge } from 'tailwind-merge';
import { v4 as uuid } from 'uuid';
import Wavesurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';

import type { ClassName } from 'shared/types';

import { theme } from 'app/theme';
import { useEvent } from 'shared/hooks/useEvent';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';

export type VoiceMessage = {
  audioBlob?: Blob;
  duration?: number;
  id: string;
};

type Props = {
  disableReset?: boolean;
  isRecording?: boolean;
  onChange: (newVoice: VoiceMessage) => void;
  onRecordingChange?: (isRecording: boolean) => void;
  onReset?: () => void;
  voice: VoiceMessage | undefined;
} & ClassName;

const formatDuration = (seconds: number): string => {
  const duration = Duration.fromObject({ seconds });
  return duration.toFormat('mm:ss');
};

export const AudioPanel = memo(
  ({ className, disableReset, isRecording, onChange, onRecordingChange, onReset, voice }: Props) => {
    const onChangeEvent = useEvent(onChange);
    const [voiceId, setVoiceId] = useState<null | string>();
    const [isLoadingRecord, setIsLoadingRecord] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progressTime, setProgressTime] = useState(0);
    const [playingTime, setPlayingTime] = useState(0);

    const hasRecording = Boolean(voice?.audioBlob);

    const stoppedRecording = useRef(false);
    const recordRef = useRef<RecordPlugin | null>(null);
    const voiceRef = useRef<HTMLDivElement>(null);
    const wavesurferRef = useRef<Wavesurfer | null>(null);

    const handleClickVoiceRecord = async () => {
      try {
        stoppedRecording.current = false;
        setIsLoadingRecord(true);
        await recordRef.current?.startRecording();
        onRecordingChange?.(true);
        setIsLoadingRecord(false);
      } catch (e) {
        setIsLoadingRecord(false);
        window.console.error('startRecordingMp3', e);
        toaster.error('Make sure you enabled permissions to use your microphone');
      }
    };

    const handleDiscardRecording = async () => {
      stoppedRecording.current = true;
      setProgressTime(0);
      setPlayingTime(0);
      setIsPlaying(false);
      onReset?.();
      if (!voice) {
        setVoiceId(uuid());
      }
    };

    useEffect(() => {
      if (!voiceRef.current || !voiceId) return;

      if (voice?.duration) {
        setProgressTime(voice.duration);
      }

      const wavesurfer = Wavesurfer.create({
        backend: 'WebAudio',
        barGap: 2.5,
        barRadius: 4,
        barWidth: 1.5,
        container: voiceRef.current,
        cursorColor: theme.colors.primary[800],
        cursorWidth: 0,
        height: 'auto',
        progressColor: theme.colors.primary[800],
        url: voice?.audioBlob && URL.createObjectURL(voice?.audioBlob),
        waveColor: theme.colors.clay[100],
        width: '100%',
      });
      wavesurferRef.current = wavesurfer;

      const recordPlugin = wavesurfer.registerPlugin(
        RecordPlugin.create({
          renderRecordedAudio: true,
          scrollingWaveform: false,
        }),
      );
      recordRef.current = recordPlugin;

      const durationProgressRef = { current: 0 };
      recordRef.current.on('record-progress', (time) => {
        const t = Number((time / 1000).toFixed(2));
        durationProgressRef.current = t;
        setProgressTime(t);
      });

      recordRef.current.on('record-end', (blob) => {
        if (stoppedRecording.current) return;
        onChangeEvent({
          audioBlob: blob.slice(0, blob.size, 'audio/wav'),
          duration: wavesurfer.getDuration(),
          id: voiceId,
        });
      });

      wavesurfer.on('play', () => {
        setIsPlaying(true);
      });
      wavesurfer.on('pause', () => setIsPlaying(false));

      wavesurfer.on('timeupdate', (time) => {
        setPlayingTime(time);
      });

      return () => {
        recordPlugin.unAll();
        recordPlugin.destroy();
        wavesurfer.unAll();
        wavesurfer.destroy();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChangeEvent, voiceId]);

    useEffect(() => {
      if (!voice) {
        setVoiceId(uuid());
      } else {
        setVoiceId((prev) => (prev !== voice.id ? voice.id : prev));
      }
    }, [voice]);

    return (
      <div className={twMerge('flex h-16 w-full items-center gap-2 rounded-lg bg-clay-10 p-4', className)}>
        {hasRecording && (
          <div
            className="flex size-8 min-w-8 cursor-pointer items-center justify-center rounded-full border border-clay-20 bg-white transition-colors"
            onClick={() => (isPlaying ? wavesurferRef.current?.pause() : wavesurferRef.current?.play())}
          >
            <Icon className="size-4 text-primary-800" name={isPlaying ? 'pause' : 'play'} />
          </div>
        )}

        {!isRecording && !voice ? (
          <div className="flex min-w-fit items-center gap-3">
            <Tooltip content="Record audio" side="top">
              <div
                className="cursor-pointer rounded-full border border-clay-20 bg-white p-1.5 text-clay-500 shadow-lg transition-colors hover:text-primary-800"
                onClick={isLoadingRecord ? undefined : handleClickVoiceRecord}
              >
                {isLoadingRecord ? <Spinner className="size-4" /> : <Icon className="size-4" name="mic" />}
              </div>
            </Tooltip>

            <div className="min-w-fit select-none text-base font-medium">Start recording</div>
          </div>
        ) : (
          !hasRecording && (
            <div
              className="flex size-8 min-w-8 cursor-pointer items-center justify-center rounded-full border border-clay-20 bg-white text-red-800 transition-colors hover:text-red-900"
              onClick={() => {
                recordRef.current?.stopRecording();
                onRecordingChange?.(false);
              }}
            >
              <Icon className="size-4" name="stop" />
            </div>
          )
        )}

        {isRecording && (
          <motion.div
            animate={{ opacity: [0, 1, 0], transition: { duration: 1, repeat: Infinity } }}
            className="size-2 min-w-2 rounded-full bg-red-800"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          />
        )}

        <div className="w-full">
          <div className="h-7 w-full" ref={voiceRef} />
        </div>

        <div className="flex w-28 items-center justify-start gap-2">
          {hasRecording && (
            <div className="text-sm/none font-medium text-clay-500">
              {formatDuration(isPlaying ? playingTime : progressTime)}
            </div>
          )}

          {hasRecording && !disableReset && (
            <Tooltip content="Remove audio" side="top">
              <div className="flex size-7 cursor-pointer items-center justify-center text-clay-500 transition-colors hover:text-red-800">
                <Icon className="size-5" name="close" onClick={handleDiscardRecording} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    );
  },
);
