import { Link } from 'react-router-dom';

import type { QueryHistoryListItem } from 'shared/api/queryHistory/types';

import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';

import { QueryHistoryCard } from './QueryHistoryCard';

type Props = {
  isOpen: boolean;
  onOpenChange: (val: boolean) => void;
  queryHistoryItem: QueryHistoryListItem;
};

export const QueryHistoryItemModal = ({ isOpen, onOpenChange, queryHistoryItem }: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="md:max-w-4xl" innerClassName="p-6">
        <Modal.CloseButton className="right-4 top-4" />

        <div className="mb-5 flex items-center gap-4">
          <div className="text-2xl font-semibold text-clay-900">Query</div>
          {queryHistoryItem.private && (
            <div className="flex select-none items-center justify-center gap-1 rounded-lg bg-clay-800 px-2 py-1.5 text-xs font-medium text-white transition-colors hover:bg-clay-900">
              <Icon className="size-3.5 text-tusk-200" name="lock" />
              Private
            </div>
          )}
        </div>
        <QueryHistoryCard
          className="rounded-none border-none !p-0 shadow-none"
          data={queryHistoryItem}
          showLinkToModel={false}
          withPrivateButton={false}
        />
        {queryHistoryItem.private && (
          <>
            <div className=" my-5 border-b border-clay-20" />
            <div className="flex justify-end">
              <Link
                className="flex cursor-pointer items-center gap-2.5"
                to={`/private-tx/${queryHistoryItem._id}`}
              >
                <div className="truncate text-right text-sm text-clay-700">
                  {queryHistoryItem.transactionHash}
                </div>
                <div className="flex size-5 items-center justify-center rounded bg-primary-30">
                  <Icon className="size-4 text-primary-800" name="arrowUpRight" safeArea="0" />
                </div>
              </Link>
            </div>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};
