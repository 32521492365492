import { useModelLimitsQuery } from 'shared/api/models/useModelLimitsQuery';

export const useLimitReached = (modelId: string | undefined = '') => {
  const { data: limits, isPending, refetch } = useModelLimitsQuery({ modelId }, { enabled: !!modelId });

  const isModelLimitReached = limits && limits.modelLimitLeft <= 0;
  const isTotalLimitReached = limits && limits.modelLimitLeft <= 0;

  return {
    isLimitReached: isModelLimitReached || isTotalLimitReached,
    isPending,
    limits,
    refetch,
  };
};
