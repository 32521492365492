import type { ReactNode } from 'react';
import { useState } from 'react';

import * as RadixSlider from '@radix-ui/react-slider';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Tooltip } from '../Tooltip';
import { DetailsPopover } from './ui/DetailsPopover';

type Props = {
  controls?: boolean;
  logs?: boolean;
  renderTooltipContent?: (value?: number[]) => ReactNode;
} & ClassName &
  RadixSlider.SliderProps;

const getProgress = (value: number, min: number, max: number) => {
  const valueToCheck = value > max ? max : value < min ? min : value;

  return valueToCheck / (max - min);
};

export const Slider = ({
  className,
  controls,
  max = 100,
  min = 0,
  onValueChange,
  renderTooltipContent,
  ...props
}: Props) => {
  const [start] = props.value || [];
  const [startToSet, setStartToSet] = useState(`${start}`);
  const [showTooltip, setShowTooltip] = useState(false);

  const progress = getProgress(start, min, max);

  return (
    <div className="flex items-center gap-2">
      <RadixSlider.Root
        className={twMerge('relative flex h-5 w-full touch-none select-none items-center', className)}
        defaultValue={[50]}
        max={max}
        min={min}
        onValueChange={(value) => {
          onValueChange?.(value);
          setStartToSet(`${value[0]}`);
        }}
        step={1}
        {...props}
      >
        <RadixSlider.Track className="sliderTrack relative h-1 grow rounded-r-full bg-clay-20">
          <RadixSlider.Range className="sliderRange absolute h-full rounded-r-none bg-primary-800" />
        </RadixSlider.Track>

        <Tooltip
          className="text-xs/none font-light"
          content={renderTooltipContent ? renderTooltipContent(props.value) : `${props.value}`}
          open={showTooltip}
          side="top"
        >
          <RadixSlider.Thumb
            aria-label="Volume"
            className="block size-4 cursor-pointer rounded-[10px] border-2 border-white bg-primary-1000 shadow-[0_2px_10px] transition-colors hover:bg-primary-900 focus:shadow-[0_0_0_5px] focus:outline-none"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            style={{
              transform: `translate(${progress > 0.5 ? 2 : 0}px, ${4 * (1 - progress)}px)`,
            }}
          />
        </Tooltip>
      </RadixSlider.Root>

      {controls && (
        <DetailsPopover
          max={max}
          min={min}
          onChange={(value) => {
            const numValue = Number(value);
            setStartToSet(value);

            if (!Number.isNaN(numValue) && numValue <= max && numValue >= min) {
              onValueChange?.([numValue]);
            }
          }}
          onReset={() => setStartToSet(`${props.value?.[0] || min}`)}
          value={startToSet}
        />
      )}
    </div>
  );
};
