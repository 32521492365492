import { useState } from 'react';

import { useEvent } from 'shared/hooks/useEvent';

import { chatClient } from '../chatClientStore';

export const useChatStatus = () => {
  const [txDataCode, setTxDataCode] = useState<null | number>(null);

  const requestChatStatus = useEvent(async () => {
    if (!chatClient) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const readableStream: any = await chatClient?.requestChatStatus();

    readableStream.on('data', (data: { code: number; message: string }) => {
      console.log('readableStream');
      setTxDataCode(data.code);
      //  Processing transmission data
      // const { code, message } = data;
      // cb(data);
      //  code and message reference as follows
      //  300 : Connecting to Nesa chain
      //  301 : Connected to Nesa chain
      //  302 : Choosing an inference validator
      //  303 : Connecting to the validator
      //  304 : Waiting for query
      //  305 : Conducting inference
      //  306 : Receiving responses
      //  307 : Task completed, wait for another query
    });
    readableStream.on('end', () => {
      readableStream.destroy();
      // End of transmission
    });
  });

  return { requestChatStatus, txDataCode, updateTxDataCode: setTxDataCode };
};
