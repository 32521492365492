import { useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import { twJoin } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';

type Props = {
  duration?: number;
};

export const Loading = ({ duration }: Props) => {
  const [progress, setProgress] = useState(duration ? 0 : 100);

  useEffect(() => {
    if (!duration) {
      return;
    }

    const d = duration;
    let zero: number;

    requestAnimationFrame(firstFrame);
    function firstFrame(timestamp: number) {
      zero = timestamp;
      animate(timestamp);
    }
    function animate(timestamp: number) {
      const value = (timestamp - zero) / d;
      if (value < 1) {
        setProgress(value * 100);
        requestAnimationFrame((t) => animate(t));
      } else {
        setProgress(100);
      }
    }
  }, [duration]);

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="absolute inset-0 z-[150] flex h-dvh flex-col items-center justify-center gap-6 bg-white"
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, repeat: 0 }}
    >
      <Icon className="absolute left-5 top-5 size-10 text-black" name="logo" />
      <div className="absolute inset-x-0 bottom-0">
        <h1 className="mb-6 pl-6 text-3xl font-semibold text-clay-900 sm:mb-11 sm:pl-11 sm:text-4xl">
          Loading Playground...
        </h1>
        <div className="relative h-32 w-full bg-clay-10 pr-5 text-end sm:h-[13.5rem] ">
          <div
            className={twJoin('absolute inset-y-0 left-0 bg-tusk-100')}
            style={{ width: `${progress}%` }}
          />
          <span className="relative text-7xl font-bold leading-[8rem] text-clay-900 sm:text-[8.875rem] sm:font-semibold sm:leading-[13.5rem]">
            {`${Math.ceil(progress)}`.split('').map((n, i) => (
              <span className="inline-block w-[3.125rem] text-center sm:w-[6.125rem]" key={i}>
                {n}
              </span>
            ))}
            %
          </span>
        </div>
      </div>
    </motion.div>
  );
};
