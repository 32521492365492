import Lottie from 'lottie-react';

import { ERROR_CODES } from 'pages/QueryPage/config';

import endingAnimation from './assets/dots-ending.json';
import errorAnimation from './assets/dots-error.json';
import loadingAnimation from './assets/dots-loading.json';
import progressAnimation from './assets/dots-progress.json';

type Props = {
  code: null | number;
};

const getAnimation = (code?: null | number) => {
  if (!code) return {};

  const hasError = ERROR_CODES.includes(code);
  const isLoading = [300, 302, 303, 304].includes(code);
  const isStopppedLoading = [301].includes(code);
  const isProgress = [305, 306].includes(code);
  const isFinished = [307].includes(code);

  if (hasError) {
    return { animation: errorAnimation, loop: false };
  }
  if ([302, 303].includes(code)) {
    return { animation: loadingAnimation, loop: 1 };
  }
  if (isLoading) {
    return { animation: loadingAnimation, loop: false };
  }
  if (isStopppedLoading) {
    return { animation: loadingAnimation, loop: false };
  }
  if (isProgress) {
    return { animation: progressAnimation, loop: false };
  }
  if (isFinished) {
    return { animation: endingAnimation, loop: false };
  }

  if (!code) {
    return { animation: loadingAnimation, loop: false };
  }

  return {};
};
export const Inference = ({ code }: Props) => {
  const { animation, loop } = getAnimation(code);

  return (
    <div className="flex flex-col gap-1 overflow-hidden">
      <Lottie animationData={animation ?? loadingAnimation} className="max-w-80" loop={loop ?? 0} />
    </div>
  );
};
