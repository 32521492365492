import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Turnstile } from '@marsidev/react-turnstile';
import { AxiosError } from 'axios';
import isEmail from 'validator/es/lib/isEmail';

import { useUser } from 'app/stores/user';
import { Logo } from 'app/ui/Logo';
import { useSignUpMutation } from 'shared/api/user/useSignUpMutation';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { TermsLinks } from 'shared/ui/TermsLinks';
import { toaster } from 'shared/ui/Toast';

type FormValues = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

export const SignUp = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [captchaToken, setCaptchaToken] = useState('');

  const {
    formState: { errors, isValid },
    getValues,
    register,
  } = useForm<FormValues>({
    defaultValues: { email: state.email || '', password: '', passwordConfirmation: '' },
    mode: 'onChange',
  });

  const { isPending, mutateAsync } = useSignUpMutation();

  const { updateUser } = useUser();

  const handleSignUp = async () => {
    try {
      if (!captchaToken) {
        toaster.error('You have to submit captcha first');
        return;
      }

      const { email, password, passwordConfirmation } = getValues();
      if (password !== passwordConfirmation) return;

      const data = await mutateAsync({ captchaToken, email, password });

      if (data.whitelist) {
        navigate('/whitelist');
        return;
      }

      await updateUser(data.user);

      navigate('/signup/verify', { state: { email } });
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  return (
    <div className="m-auto -mt-3 flex h-dvh w-full max-w-96 flex-col items-center justify-center px-5">
      <Logo className="mb-3" />
      <h2 className="mb-4 text-lg">Create your account</h2>

      <form
        className="mb-4 flex w-full flex-col gap-1"
        onSubmit={(e) => {
          e.preventDefault();

          handleSignUp();
        }}
      >
        <Input
          errorSpacing
          {...register('email', { validate: (val) => (!isEmail(val) ? 'Invalid email' : true) })}
          error={errors.email?.message}
          placeholder="Email Address"
        />
        <Input
          errorSpacing
          {...register('password', {
            validate: (val) => {
              return val.length < 6 ? 'Password should have at least 6 characters' : true;
            },
          })}
          error={errors.password?.message}
          placeholder="Password"
          type="password"
        />
        <Input
          errorSpacing
          type="password"
          {...register('passwordConfirmation', {
            minLength: 6,
            validate: (value, formValues) =>
              formValues.password !== value ? 'Passwords should match' : true,
          })}
          error={errors.passwordConfirmation?.message}
          placeholder="Password Confirmation"
        />

        <Button disabled={!isValid || !captchaToken} isLoading={isPending} type="submit">
          Next
        </Button>

        {/* width: fit-content;
    height: auto;
    display: flex;
    min-width: fit-content;
    max-width: 100%;
    align-self: center; */}
        <Turnstile
          onSuccess={setCaptchaToken}
          options={{ appearance: 'interaction-only' }}
          siteKey={import.meta.env.VITE_CLOUDFLARE_SITE_KEY}
          style={{ alignSelf: 'center', maxWidth: '100%', minWidth: 'fit-content' }}
        />

        <p className="mt-2 text-center text-xs/none text-clay-500">
          By signing up you agree to the{' '}
          <a className="underline" href="https://www.legacy.nesa.ai/terms" rel="noreferrer" target="_blank">
            terms
          </a>
        </p>
      </form>

      <TermsLinks className="absolute bottom-6 mt-auto" />
    </div>
  );
};
