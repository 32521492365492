import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = {
  id: string;
  // image?: string;
  isUser?: boolean;
  text: string;
  title?: string;
} & ClassName;

export const Message = ({ className, id, isUser, text, title }: Props) => {
  return (
    <div className={twMerge('relative flex gap-3', isUser && 'flex-row-reverse', className)} id={id}>
      <div className="absolute top-0 ml-6 text-xs font-light text-clay-500">{title}</div>

      <p
        className={twMerge(
          'max-w-[60%] whitespace-pre-line rounded-2xl rounded-bl-none bg-primary-30 px-4 py-2 text-sm/5 font-light text-clay-800 lg:text-base/5 2xl:text-sm/5',
          isUser && 'rounded-bl-2xl rounded-br-none bg-clay-10',
        )}
      >
        {text}
      </p>
    </div>
  );
};
