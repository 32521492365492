import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { GetModelReviewsParams, PaginationResponse, Review } from './types';

import { reviewsKeys } from './queryKeys';

export const useGetReviewsByModelQuery = (
  { limit = 4, modelId, userId, ...params }: GetModelReviewsParams,
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: Review[] = [], all: Review[][]) => {
      return last.length === limit ? all.length : null;
    },
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<PaginationResponse<Review>>(`reviews/models/${modelId}/list`, {
        params: {
          ...params,
          limit,
          modelId,
          skip: pageParam * limit,
          userId,
        },
        signal,
      });

      return res.data.list;
    },
    queryKey: reviewsKeys.list({ limit, modelId, userId, ...params }),
    ...(options || {}),
  });
};
