import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { Model } from './types';

import { modelsKeys } from './queryKeys';

type Response = {
  model: Model;
};

export const useGetRandomPickModelQuery = (options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Response>(`/models/favorite/random`, { signal });

      return data;
    },
    queryKey: modelsKeys.random(),
    staleTime: 0,
    ...options,
  });
};
