import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { Review } from 'shared/api/reviews/types';

import { theme } from 'app/theme';
import { timeAgo } from 'shared/helpers/timeAgo';
import { Icon } from 'shared/ui/Icon';
import { StarRating } from 'shared/ui/StarRating';

type Props = {
  isOwner?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  review: Review;
};

export const ReviewItem = ({ isOwner, onDelete, onEdit, review }: Props) => {
  const hasFirstName = !!review.user?.first_name;
  const hasLastName = !!review.user?.last_name;

  return (
    <div
      className={twMerge(
        'flex w-full gap-4 border-b border-clay-20 px-3 py-4',
        isOwner && 'rounded-xl bg-primary-40/50',
      )}
    >
      <div className="flex size-8 min-w-8 items-center justify-center rounded-lg bg-clay-10 font-klapt">
        <span className="-mt-px font-bold uppercase leading-none text-clay-700">
          {review.user?.last_name?.trim()[0] ?? review.user?.first_name?.trim()[0] ?? 'U'}
        </span>
      </div>

      <div className="flex w-full flex-col">
        <div className="mb-2 flex items-center justify-between gap-3">
          <div className="flex flex-wrap items-start gap-2">
            <div className="grid grid-cols-1 gap-0.5">
              <div className="truncate text-base/none font-normal text-clay-900">
                {hasFirstName || hasLastName ? (
                  <>
                    {review.user?.first_name} {review.user?.last_name}
                  </>
                ) : (
                  'Unknown'
                )}
              </div>
              <div className="text-xs/none font-light italic text-clay-600">
                {review.timestamp && timeAgo(DateTime.fromMillis(review.timestamp))}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end gap-1">
            <div className="flex items-center gap-2">
              <div className="text-base/none font-bold text-black">{review?.rating?.toFixed(1)}</div>
              <StarRating
                className="-mt-1"
                containerClassName="size-5"
                rating={review.rating}
                starColor={theme.colors.primary[800]}
                svgProps={{ height: 12, width: 12 }}
              />
            </div>

            {isOwner && (
              <div className="flex items-start gap-3">
                {onEdit && (
                  <div
                    className="flex cursor-pointer items-center gap-1 text-xs text-primary-800 transition-colors hover:text-primary-1000"
                    onClick={onEdit}
                  >
                    <Icon className="-mt-px size-4" name="edit" /> Edit
                  </div>
                )}
                {onDelete && (
                  <div
                    className="flex cursor-pointer items-center gap-1 text-xs text-primary-800 transition-colors hover:text-primary-1000"
                    onClick={onDelete}
                  >
                    <Icon className="-mt-px size-4" name="trash" /> Delete
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {review.feedback ? (
          <div className="text-base font-light text-clay-500">{review.feedback}</div>
        ) : (
          <div className="font-light text-clay-500">No text</div>
        )}
      </div>
    </div>
  );
};
