import { motion } from 'framer-motion';

import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { Select } from 'shared/ui/Select';
import { Slider } from 'shared/ui/Slider';

import type { UnconditionalImageGenParams } from '../types';

type Props = {
  onChange: (params: Partial<UnconditionalImageGenParams>) => void;
  queryParams: UnconditionalImageGenParams;
};

const imageSizeOptions = (['256x256', '512x512', '1024x1024'] as const).map((value) => ({
  label: value,
  value,
}));

export const UnconditionalImageGen = ({ onChange, queryParams }: Props) => {
  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className="grid min-h-fit grid-cols-2 gap-2.5 overflow-hidden pb-4"
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <div>
        <Select
          label="Image Size"
          onValueChange={(value) =>
            onChange({ imageSize: value as UnconditionalImageGenParams['imageSize'] })
          }
          value={queryParams.imageSize}
        >
          <Select.Content>
            {imageSizeOptions.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Label>Number of Inference Steps</Label>
        <Slider
          controls
          max={1000}
          min={10}
          onValueChange={(values) => onChange({ numInferenceSteps: values[0] })}
          step={1}
          value={[queryParams.numInferenceSteps]}
        />
      </div>
      <div>
        <Label>Guidance Scale</Label>
        <Slider
          controls
          max={10}
          min={1}
          onValueChange={(values) => onChange({ guidanceScale: values[0] })}
          step={0.01}
          value={[queryParams.guidanceScale]}
        />
      </div>
      <div>
        <Label>Seed</Label>
        <Input
          onChange={(e) => {
            const numValue = Number(e.target.value);
            if (numValue > 0) {
              onChange({ seed: numValue });
            }
          }}
          type="number"
          value={queryParams.seed}
        />
      </div>
    </motion.div>
  );
};
