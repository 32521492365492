import { useState } from 'react';

import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';

import { useDownloadBucketFileMutation } from 'shared/api/models/useDownloadBucketFileMutation';
import { useGetModelByIdQuery } from 'shared/api/models/useGetModelByIdQuery';
import { useGetModelFilesQuery } from 'shared/api/models/useGetModelFilesQuery';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
import { Spinner } from 'shared/ui/Spinner';
import { Table } from 'shared/ui/Table';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  isOpen: boolean;
  modelId: string;
  onOpenChange: (isOpen: boolean) => void;
};

const formatBytes = (bytes: number, decimals = 2) => {
  if (!bytes) return '0B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
};

export const DownloadModal = ({ isOpen, modelId, onOpenChange }: Props) => {
  const { data: model } = useGetModelByIdQuery(modelId);
  const { mutateAsync: downloadFile } = useDownloadBucketFileMutation();

  const [loadingFiles, setLoadingFiles] = useState<Record<string, boolean>>({});
  const [filter, setFilter] = useState('');

  const prefix = `${model?.bucket_path?.includes('models--') ? model?.bucket_path : `${model?.bucket_path}/models--${model?.name.replace('/', '--')}`}`;
  const { data: filesData, isLoading } = useGetModelFilesQuery({ prefix }, { enabled: !!model?.bucket_path });

  const filteredData = filter
    ? filesData?.files.filter(({ name }) => name.includes(filter))
    : filesData?.files;

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-screen-lg p-0" innerClassName="!p-0">
        <Modal.CloseButton className="z-50 rounded-lg bg-clay-20 md:hidden" />
        <div className="flex flex-col gap-1">
          <div className="flex flex-col justify-between border-b border-clay-20 bg-clay-10 pb-4 md:flex-row md:items-center md:pb-0">
            <div className="flex flex-1 items-center gap-3  p-4 text-lg font-medium">
              <Icon className="text-clay-800" name="grid" />
              File Browser
            </div>

            <div className="flex size-full !min-w-48 flex-col border-l border-clay-20 px-4 md:w-auto">
              <Input
                className="h-full min-w-64"
                classNameInput="text-base"
                classNameInputWrapper="border-none bg-transparent text-lg !h-full"
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Search"
                startSlot={<Icon className="size-4" name="search" />}
                value={filter}
              />
            </div>
          </div>

          <div className="flex flex-col overflow-x-scroll">
            {isLoading ? (
              <div className="flex items-center justify-center py-32">
                <Spinner className="size-5" />
              </div>
            ) : filteredData ? (
              <Table
                className="border-none"
                columns={[
                  {
                    key: 'name',
                    renderTd: ({ name }) => {
                      const pathToShow = name.replace(prefix, '');
                      const splittedPath = pathToShow.split('/');
                      const filename = splittedPath[splittedPath.length - 1];

                      return (
                        <div className="flex items-center">
                          {/* {name.replace(prefix, '')} */}
                          <span className="font-light text-clay-400">
                            {splittedPath.slice(0, splittedPath.length - 1).join('/')}/
                          </span>

                          <span>{filename}</span>
                        </div>
                      );
                    },
                    thClassName: 'border-none',
                    title: 'Test',
                  },
                  {
                    key: 'size',
                    renderTd: ({ size }) => {
                      return <div className="w-24">{size ? formatBytes(Number(size)) : '0B'}</div>;
                    },
                    thClassName: 'border-none',
                    title: 'File Size',
                  },
                  {
                    key: 'timestamp',
                    renderTd: ({ timestamp }) => {
                      return (
                        <div className="w-32">
                          {DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_SHORT)}
                        </div>
                      );
                    },
                    thClassName: 'border-none',
                    title: 'Updated At',
                  },
                  {
                    key: 'action',
                    renderTd: (item) => {
                      const pathToShow = item.name.replace(prefix, '');
                      const splittedPath = pathToShow.split('/');
                      const filename = splittedPath[splittedPath.length - 1];

                      return (
                        <Tooltip content="Download File">
                          <div
                            className="flex w-full min-w-11 items-center justify-center"
                            onClick={async () => {
                              setLoadingFiles((prev) => {
                                return {
                                  ...prev,
                                  [filename]: true,
                                };
                              });

                              const res = await downloadFile({ filename: item.name });

                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              const data = Buffer.from(res.data as any);

                              const blob = new Blob([data]);

                              saveAs(blob, filename);

                              setLoadingFiles((prev) => {
                                return {
                                  ...prev,
                                  [filename]: false,
                                };
                              });
                            }}
                          >
                            {loadingFiles[filename] ? (
                              <Spinner className="size-4" />
                            ) : (
                              <Icon
                                className="size-4 cursor-pointer text-clay-500 transition-colors hover:text-clay-800"
                                name="download"
                              />
                            )}
                          </div>
                        </Tooltip>
                      );
                    },
                    tdClassName: 'p-0',
                    thClassName: 'border-none',
                  },
                ]}
                data={filteredData || []}
              ></Table>
            ) : (
              <div className="flex items-center justify-center py-32 text-sm text-clay-400">
                No Data Available
              </div>
            )}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
