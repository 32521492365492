import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

type Params = {
  modelName: string;
};

type Response = {
  imageUrl: string;
};

export const useGenerateImageMutation = (options: UseMutationOptions<Response, Error, Params> = {}) => {
  return useMutation({
    mutationFn: async (params: Params) => {
      const { data } = await axiosInstance.post<Response>(
        `/images/generate?modelName=${params.modelName}`,
        {},
        { timeout: 70000 },
      );

      return data;
    },
    ...options,
  });
};
