import { motion } from 'framer-motion';

import { Label } from 'shared/ui/Label';
import { Select } from 'shared/ui/Select';
import { Slider } from 'shared/ui/Slider';

import type { Emotion, Language, TextToSpeechQueryParams } from '../types';

import { textSpeechEmotionOptions } from '../consts';
import { languages } from '../languages';

type Props = {
  onChange: (params: Partial<TextToSpeechQueryParams>) => void;
  queryParams: TextToSpeechQueryParams;
};

export const TextToSpeech = ({ onChange, queryParams }: Props) => {
  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      className="grid min-h-fit grid-cols-2 gap-2.5 overflow-hidden pb-4"
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      layout
    >
      <div>
        <Select
          label="Language"
          onValueChange={(value) => onChange({ language: value as Language })}
          value={queryParams.language}
        >
          <Select.Content>
            {languages.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Select
          label="Emotion"
          onValueChange={(value) => onChange({ emotion: value as Emotion })}
          value={queryParams.emotion}
        >
          <Select.Content>
            {textSpeechEmotionOptions.map(({ label, value }) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Select
          label="Sampling Rate"
          onValueChange={(value) =>
            onChange({ samplingRate: Number(value) as TextToSpeechQueryParams['samplingRate'] })
          }
          value={`${queryParams.samplingRate}`}
        >
          <Select.Content>
            {[16000, 22050, 24000].map((value) => (
              <Select.Item key={value} value={`${value}`}>
                {value.toLocaleString()}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <div>
        <Select
          label="Bitrate"
          onValueChange={(value) =>
            onChange({ bitrate: Number(value) as TextToSpeechQueryParams['bitrate'] })
          }
          value={`${queryParams.bitrate}`}
        >
          <Select.Content>
            {[32, 64, 128, 192, 256, 320].map((value) => (
              <Select.Item key={value} value={`${value}`}>
                {value.toLocaleString()}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>

      <div>
        <Label>Pitch</Label>
        <Slider
          controls
          max={3}
          min={-3}
          onValueChange={(values) => onChange({ pitch: values[0] })}
          step={0.01}
          value={[queryParams.pitch]}
        />
      </div>

      <div>
        <Label>Speed</Label>
        <Slider
          controls
          max={2}
          min={0.5}
          onValueChange={(values) => onChange({ speed: values[0] })}
          step={0.01}
          value={[queryParams.speed]}
        />
      </div>
      <div>
        <Label>Volume</Label>
        <Slider
          controls
          max={2}
          min={0}
          onValueChange={(values) => onChange({ volume: values[0] })}
          step={0.01}
          value={[queryParams.volume]}
        />
      </div>
      <div>
        <Label>Temperature</Label>
        <Slider
          controls
          max={1}
          min={0.1}
          onValueChange={(values) => onChange({ temperature: values[0] })}
          step={0.01}
          value={[queryParams.temperature || 1]}
        />
      </div>
    </motion.div>
  );
};
