import { AnimatePresence, motion } from 'framer-motion';
import Lottie from 'lottie-react';
import { twMerge } from 'tailwind-merge';

import { ERROR_CODES } from 'pages/QueryPage/config';

import ErrorIcon from './assets/error.svg?react';
import step0 from './assets/step-0.json';
import step1 from './assets/step-1.json';
import step2 from './assets/step-2.json';
import step3 from './assets/step-3.json';
import step4 from './assets/step-4.json';
import step5 from './assets/step-5.json';
import step6 from './assets/step-6.json';
import stepError from './assets/step-error.json';

export type Step = {
  animation?: Record<string, unknown>;
  icon?: React.FunctionComponent<
    {
      title?: string | undefined;
    } & React.SVGProps<SVGSVGElement>
  >;
  id: string;
  repeat?: number;
  text: string;
};

export const STEPS: Partial<Record<string, Step>> = {
  200: { id: 'success', text: 'Completed' },
  205: { animation: stepError, id: 'error', repeat: 0, text: 'Error' },
  300: { animation: step0, id: 'connecting-chain', repeat: 0, text: 'Connecting to Nesa chain' },
  301: { animation: step1, id: 'connected-chain', repeat: 0, text: 'Connected to Nesa chain' },
  302: {
    animation: step2,
    id: 'inference-validator',
    repeat: 0,
    text: 'Choosing an inference validator',
  },
  303: {
    animation: step3,
    id: 'connecting-validator',
    repeat: 0,
    text: 'Connecting to the validator',
  },
  304: { animation: step4, id: 'waiting-for-query', repeat: 0, text: 'Waiting for query' },
  305: { animation: step5, id: 'conducting', repeat: 0, text: 'Conducting inference' },
  306: { animation: step5, id: 'receiving-response', repeat: 0, text: 'Receiving responses' },
  307: { animation: step6, id: 'sending', repeat: 0, text: 'Task completed, wait for another query' },
  311: {
    animation: stepError,
    id: 'lockAmount',
    repeat: 0,
    text: 'LockAmount cannot be less than x, LockAmount check',
  },
  312: { animation: stepError, id: 'register-failed', repeat: 0, text: 'Sign register session failed.' },
  313: { animation: stepError, id: 'session-error', repeat: 0, text: 'Register session error' },
  314: { animation: stepError, id: 'params-format-error', repeat: 0, text: 'Chain params format error' },
  315: { animation: stepError, id: 'params-error', repeat: 0, text: 'Chain params error' },
  316: { animation: stepError, id: 'sdk-error', repeat: 0, text: 'SDK client init error' },
  317: { animation: stepError, id: 'wallet-connect-error', repeat: 0, text: 'Wallet connect error' },
  318: { animation: stepError, id: 'wallet-connect-error2', repeat: 0, text: 'Wallet connect error' },
};

type Props = {
  code: null | number;
  errorMessage: null | string;
  latency?: number | string;
};

const getStepNumber = (code: number) => {
  if (code >= 300 && code <= 307) return code - 300 + 1;
};

export const Steps = ({ code, errorMessage, latency }: Props) => {
  const hasError = code && ERROR_CODES.includes(code);
  const activeStep = typeof code === 'number' && STEPS[code];
  const stepNumber = code && getStepNumber(code);

  return (
    <AnimatePresence>
      {activeStep ? (
        <motion.div
          animate={{ opacity: 1 }}
          className="relative flex h-full flex-row items-center gap-2 p-4 md:flex-col md:items-baseline"
          initial={{ opacity: 0 }}
        >
          {!hasError && (
            <div className="absolute right-2 top-2 flex items-center justify-end gap-1 text-xs/none font-light text-clay-400 2xl:text-sm/none">
              <span className="font-semibold text-clay-900">{latency || 5} sec</span>
              <span>Estimated time</span>
            </div>
          )}

          <div className="relative mb-0 size-7 md:mb-2">
            <AnimatePresence>
              {activeStep.animation ? (
                <motion.div
                  className={twMerge(
                    'absolute',
                    hasError && 'left-1/2 top-2/3 -translate-x-1/2 -translate-y-1/2',
                  )}
                  key={activeStep.id}
                >
                  {hasError ? (
                    <Lottie animationData={stepError} className="size-14" loop={0} />
                  ) : (
                    <Lottie
                      animationData={activeStep.animation}
                      className="size-9"
                      loop={activeStep.repeat}
                    />
                  )}
                </motion.div>
              ) : (
                activeStep.icon && (
                  <motion.div
                    animate={hasError ? undefined : { opacity: 0.5, scale: 0.9 }}
                    className="absolute"
                    key={activeStep.id}
                    transition={
                      hasError
                        ? undefined
                        : { duration: 0.7, repeat: activeStep.repeat ?? 2, repeatType: 'mirror' }
                    }
                  >
                    {hasError ? <ErrorIcon className="size-7" /> : <activeStep.icon className="size-7" />}
                  </motion.div>
                )
              )}
            </AnimatePresence>
          </div>
          <div className="relative mt-auto flex w-full flex-1 flex-col justify-end gap-1.5">
            {(stepNumber || hasError) && (
              <motion.div
                className={twMerge(
                  'w-fit rounded-md bg-tusk-100 px-1.5 py-0 text-[10px] uppercase tracking-wide',
                  hasError && 'bg-red-900 text-white',
                )}
              >
                {hasError ? 'ERROR' : `step ${stepNumber} / 8`}
              </motion.div>
            )}

            <AnimatePresence>
              <div className="relative min-h-4 min-w-fit text-base font-semibold md:min-h-7 2xl:min-h-4">
                <motion.div
                  animate={{ opacity: 1 }}
                  className="absolute top-0 text-sm/none font-semibold md:text-sm/none"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  key={activeStep.id}
                >
                  {errorMessage || activeStep.text}
                </motion.div>
              </div>
            </AnimatePresence>
          </div>
        </motion.div>
      ) : (
        <motion.div
          animate={{ opacity: 1 }}
          className="m-auto flex h-full items-center justify-center gap-1 rounded-lg p-4 text-xs font-semibold text-clay-300"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
        >
          No pending inference
        </motion.div>
      )}
    </AnimatePresence>
  );
};
