import type { ReactPlayerProps } from 'react-player';

import { useRef } from 'react';
import ReactPlayer from 'react-player';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = ClassName & ReactPlayerProps;

export const VideoPlayer = ({ className, ...props }: Props) => {
  const ref = useRef<ReactPlayer>(null);

  return (
    <div className={twMerge('overflow-hidden rounded-lg', className)}>
      <ReactPlayer className="" controls height="100%" ref={ref} width="100%" {...props} />
    </div>
  );
};
