import { saveAs } from 'file-saver';

import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';

type Props = {
  imageSrc: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const ExpandModal = ({ imageSrc, isOpen, onOpenChange }: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-fit !p-0" innerClassName="p-0">
        <div
          className="group absolute right-4 top-4 flex size-11 cursor-pointer items-center justify-center rounded-xl bg-black/20 text-white backdrop-blur-md transition-colors hover:text-clay-20"
          onClick={() => onOpenChange(false)}
        >
          <Icon className="size-5 text-white transition-colors group-hover:text-clay-10" name="close" />
        </div>

        <img
          className="aspect-auto size-full h-full max-h-[80vh] max-w-[80vw] object-contain"
          src={imageSrc}
        />

        <div
          className="group absolute bottom-4 left-4 flex cursor-pointer items-center gap-2 rounded-lg bg-black/20 px-3 py-2.5 text-white backdrop-blur-md transition-colors hover:text-clay-20"
          onClick={() => saveAs(imageSrc, 'image')}
        >
          <Icon name="download" />
          Download
        </div>
      </Modal.Content>
    </Modal>
  );
};
